import {EventEmitter, Injectable} from '@angular/core';
import {IScheduleWeekSettings} from '../modules/schedule/interfaces';
import {IApiChain} from '../models/IApiChain';
import {IApiUser} from '../models/IApiUser';
import {environment} from '../../environments/environment';
import {version} from '../../environments/version';
import {buildnumber} from '../../environments/buildnumber';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  LASTLOADEDSUBMITTEDFORMS = 'lastLoadedSubmittedForms';

  public branchChanged: EventEmitter<void> = new EventEmitter<void>();
  public countersChanged: EventEmitter<void> = new EventEmitter<void>();
  public updateUser: EventEmitter<void> = new EventEmitter<void>();
  public updateTimeZone: EventEmitter<void> = new EventEmitter<void>();
  public updateAvatar: EventEmitter<string> = new EventEmitter<string>();
  public updateNotificationsCounter: EventEmitter<void> = new EventEmitter<void>();
  public updateNotificationsList: EventEmitter<void> = new EventEmitter<void>();
  public networkStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public notificationsCount = 0;

  public connected = true;

  public platform = '';
  public version = version + '.' + buildnumber;

  public previousUrl = '';

  public currentUser = null;

  constructor() {
  }

  getApiToken() {
    return localStorage.getItem('apiToken');
  }

  setApiToken(apiToken: string) {
    localStorage.setItem('apiToken', apiToken);
  }

  getTimeZone() {
    return localStorage.getItem('timeZone');
  }

  setTimeZone(timeZone: string) {
    localStorage.setItem('timeZone', timeZone);
  }

  getTimeZoneId() {
    return localStorage.getItem('timeZoneId');
  }

  setTimeZoneId(timeZoneId: string) {
    localStorage.setItem('timeZoneId', timeZoneId);
  }

  getScheduleSettings(): IScheduleWeekSettings {
    const s = localStorage.getItem('scheduleSettings');
    return JSON.parse(s);
  }

  setScheduleSettings(settings: IScheduleWeekSettings) {
    localStorage.setItem('scheduleSettings', JSON.stringify(settings));
  }

  getScheduleAvailabilityTab(): number {
    const v = localStorage.getItem('scheduleAvailabilityTab');
    return parseInt(v, 10);
  }

  setScheduleAvailabilityTab(v: any) {
    localStorage.setItem('scheduleAvailabilityTab', v);
  }

  getUserMenu() {
    const s = localStorage.getItem('userMenu');
    return JSON.parse(s);
  }

  setUserMenu(v) {
    localStorage.setItem('userMenu', JSON.stringify(v));
  }

  delUserMenu() {
    localStorage.removeItem('userMenu');
  }

  getUserData(): IApiUser {
    const s = localStorage.getItem('userData');
    return JSON.parse(s);
  }

  setUserData(v) {
    localStorage.setItem('userData', JSON.stringify(v));
    this.setCurrentBranch();
  }

  setCurrentBranch() {
    this.currentUser = localStorage.getItem('userData');

    this.currentUser = typeof this.currentUser !== 'undefined' && this.currentUser ?
      JSON.parse(this.currentUser) : undefined;


    if ( typeof this.currentUser !== 'undefined'
      && this.currentUser
      && typeof this.currentUser.branches !== 'undefined'
      && this.currentUser.branches
      && this.currentUser.branches.length > 0
    ) {
      this.currentUser.branches.forEach((branch) => {
        if (Number(branch.branch_id) === Number(this.currentUser.current_branch_id)) {
          localStorage.setItem('currentBranch', JSON.stringify(branch));
        }
      });
    }
  }

  delUserData() {
    localStorage.removeItem('userData');
  }

  getChain(): IApiChain {
    const s = localStorage.getItem('chain');
    return JSON.parse(s);
  }

  setChain(v: IApiChain) {
    localStorage.setItem('chain', JSON.stringify(v));
    localStorage.setItem('chainLogo', v.logo);
  }

  setCss(cssUrl: string) {
    if (!environment.domainMode) {
      const fullUrl = environment.staticUrl + cssUrl + '?' + (new Date()).getTime();
      localStorage.setItem('chainCSS', fullUrl);
      this.applyCss();
    }
  }

  applyCss() {
    if (!environment.domainMode) {
      const fullUrl = localStorage.getItem('chainCSS');
      if (typeof fullUrl === 'string' && fullUrl.length > 0) {
        const linkTag = document.createElement('link');
        linkTag.rel = 'stylesheet';
        linkTag.href = fullUrl;
        document.head.append(linkTag);
      }
    }
  }

  setLastLoadedSubmittedForms(d: any) {
    localStorage.setItem(this.LASTLOADEDSUBMITTEDFORMS, JSON.stringify(d));
  }

  getLastLoadedSubmittedForms() {
    return localStorage.getItem(this.LASTLOADEDSUBMITTEDFORMS);
  }

  delLastLoadedSubmittedForms() {
    return localStorage.removeItem(this.LASTLOADEDSUBMITTEDFORMS);
  }

  clear() {
    localStorage.removeItem('apiToken');
    localStorage.removeItem('timeZone');
    localStorage.removeItem('timeZoneId');
    localStorage.removeItem('scheduleSettings');
    localStorage.removeItem('scheduleAvailabilityTab');
    localStorage.removeItem('userMenu');
    localStorage.removeItem('userData');
    localStorage.removeItem('chain');
    localStorage.removeItem('branches');
    localStorage.removeItem('currentBranch');
    localStorage.removeItem(this.LASTLOADEDSUBMITTEDFORMS);
  }

}
