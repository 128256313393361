import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IApiChain} from '../../../models/IApiChain';
import {IApiUser, IBranchShort} from '../../../models/IApiUser';
import {IApiUserMenuItem} from '../../../models/IApiUserMenu';
import {environment} from '../../../../environments/environment';
import {LayoutService} from '../layout.service';
import {PlatformLocation} from '@angular/common';
import {ApiService} from '../../../services/api.service';
import {Router} from '@angular/router';
import {LoaderService} from '../../../services/loader.service';
import {StateService} from '../../../services/state.service';
import {TranslocoService} from '@ngneat/transloco';
import {Subscription} from 'rxjs';
import {SnackbarService} from '../../../components/snackbar/snackbar.service';
import {version} from '../../../../environments/version';
import {buildnumber} from '../../../../environments/buildnumber';
import {PagesApiService} from '../../../pages/pages-api.service';
import {Browser} from '@capacitor/browser';

@Component({
  selector: 'foh-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  @Input() menu: IApiUserMenuItem[] = [];
  @Input() chain: IApiChain;
  @Input() branch: IBranchShort;
  @Input() user: IApiUser;
  @Input() avatarUrl = '';
  showBackendMenu = false;
  isApp = false;
  show = false;
  backendUrl = '';
  subs: Subscription[] = [];
  version = '';
  isTestDomain = false;
  testEnv = '';
  isProd = environment.production;

  constructor(
    private location: PlatformLocation,
    private apiService: ApiService,
    private loader: LoaderService,
    private snackbar: SnackbarService,
    private router: Router,
    private api: ApiService,
    public state: StateService,
    private t: TranslocoService,
    public ls: LayoutService,
    public pagesApi: PagesApiService
  ) {
    this.version = version;
    if (!environment.production) {
      this.version += '.' + buildnumber;
    }
    this.isApp = environment.isApp;
    this.subs.push(this.ls.menustate.subscribe(show => {
      if (show) {
        history.pushState(null, 'foh-menu-opened');
      }
      this.show = show;
    }));
    this.showBackendMenu = true; // environment.domainMode || environment.isApp;
    if (this.showBackendMenu) {
      this.subs.push(
        this.api.getChainCached()
          .subscribe(
            (chain: IApiChain) => {
              this.backendUrl = environment.apiUrl.replace('api.', chain.subdomain + '.');
            }
          )
      );
    }
  }

  getLoginAdminUrl(url){
    this.subs.push(
      this.pagesApi.getUrl(url)
        .subscribe(
          (data1: any) => {
            console.log('data1', data1)
            this.openBackend(data1.data);
          },
          (e) => {
          }
        )
    );
  }

  ngOnInit() {
    this.location.onPopState(() => this.show = false);
    const testEnv = localStorage.getItem('test-env');
    if (testEnv) {
      this.testEnv = testEnv;
      this.isTestDomain = true;
    }
  }

  logout() {
    this.loader.showLoader();
    if (environment.isApp) {
      this.subs.push(this.api.removeFbToken()
        .subscribe(
          () => {
            this.doLogout();
          }
        ));
    } else {
      this.doLogout();
    }
    return false;
  }

  doLogout() {
    this.subs.push(this.apiService.logout()
      .subscribe(() => {
        this.router.navigateByUrl('/user/login')
          .then(() => {
            this.loader.hideLoader();
          });
      }));
  }

  changeBranch(e) {
    const value = (e.target as HTMLSelectElement).value;
    let branch = null;
    if (value !== '') {
      const branchId = Number((e.target as HTMLSelectElement).value);
      this.user.branches.forEach((brnch: IBranchShort) => {
        if (Number(brnch.branch_id) === branchId) {
          branch = brnch;
        }
      });
    } else {
      branch = {branch_id: 0, chain_id: this.user.chain_id};
    }

    localStorage.setItem('currentBranch', JSON.stringify(branch));

    this.loader.showLoader();
    this.subs.push(this.api.switchBranch(branch).subscribe(() => {
      this.loader.hideLoader();
      this.user.current_branch_id = branch.branch_id;
      this.snackbar.clear();
      this.snackbar.add({
        msg: this.t.translate('Branch has been switched'),
        action: {text: this.t.translate('Ok')},
      });
      this.api.getUserCached(true).then(() => {
        this.state.delUserMenu();
        this.state.branchChanged.emit();
        this.ls.menustate.emit(false);
      });
    }));
    return false;
  }

  goHome() {
    this.ls.menustate.emit(false);
    this.router.navigateByUrl('/dashboard').then(r => r);
  }

  openBackend(url: string) {
    const u = new URL(url);
    if (this.user) {
      u.searchParams.set('branchId', this.user.current_branch_id.toString());
    }
    Browser.open({url: u.toString()})
      .then(() => console.log('Browser opened with url: ' + this.backendUrl + url));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
