import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {LayoutService} from '../layout.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {LanguageService} from '../../../services/language.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'foh-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
      <section [ngClass]="{'full-width':this.fullWidth}">
          <div *ngIf="(showSearch && showSearchTextbox && showSearchFilters)" style="width: 41px">
              <button>
                  <i class="fa-light fa-filter-list"></i>
              </button>
          </div>
          <div [ngStyle]="{width:leftWidth}" *ngIf="!(showSearch && showSearchTextbox)">
              <ng-container *ngIf="!dontShowMenuAtAll">
                  <button *ngIf="showBurger && !showBack && !showClose && !backLink && !closeLink" (click)="openMenu()">
                      <i class="fa-light fa-bars"></i>
                  </button>
              </ng-container>
              <button *ngIf="showBack || backLink" (click)="clickBack()">
                  <i *ngIf="!isRtl" class="fa-light fa-arrow-left"></i>
                  <i *ngIf="isRtl" class="fa-light fa-arrow-right"></i>
              </button>
              <button *ngIf="showClose || closeLink" (click)="clickClose()">
                  <i class="fa-light fa-xmark"></i>
              </button>
              <ng-container *ngIf="!dontShowMenuAtAll">
                  <button *ngIf="!showBurger" (click)="openMenu()">
                      <i class="fa-light fa-ellipsis-vertical"></i>
                  </button>
              </ng-container>
          </div>
          <span class="title-container" *ngIf="!showSearchTextbox">{{ title }}</span>
          <div class="searchbox" [ngStyle]="{display: (showSearchTextbox && showSearch) ? 'flex':'none'}">
              <input type="text"
                     [maxLength]="128"
                     [placeholder]="searchPlaceholder"
                     tabindex="0" #searchInputRef
                     autocomplete="off"
                     id="foh-headerv3-search-textbox"
                     (keyup)="searchKeyUp($event)">
              <button *ngIf="searchText.length > 0 && loading"><i class="fa-light fa-sync fa-spin"></i></button>
              <!--              <button xngIf="searchText.length > 0 && !loading" >-->
              <!--                  <i class="fa-light fa-circle-xmark" aria-hidden="true"></i>-->
              <!--              </button>-->
          </div>
          <div [ngStyle]="{width:rightWidth}" *ngIf="!(showSearch && showSearchTextbox)">
              <button *ngIf="showSearch" (click)="showSearchInput($event)">
                  <i *ngIf="!showSearchTextbox" class="fa-light fa-magnifying-glass"></i>
              </button>
              <button id="foh-header-notifications-button"
                      routerLink="/notification">
                  <i class="fa-light fa-bell"></i>
                  <span *ngIf="notificationsCount > 0"><bdo
                          dir="ltr">{{ notificationsCount < 100 ? notificationsCount : '99+' }}</bdo></span>
              </button>
          </div>
          <div *ngIf="(showSearch && showSearchTextbox)" style="width: 41px">
              <button *ngIf="showSearch" (click)="closeSearch()">
                  <i class="fa-light fa-circle-xmark"></i>
              </button>
          </div>
      </section>
  `,
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  isRtl = document.body.parentElement.getAttribute('dir') === 'rtl';
  title = '';

  searchText = '';
  showSearchTextbox = false;
  showSearchFilters = false;
  showBurger = true;
  showSearch = false;
  searchPlaceholder = '';
  showBack = false;
  backLink = '';
  showClose = false;
  closeLink = '';
  notificationsCount = 0;
  deployUrl = environment.deployUrl;
  loading = false;

  dontShowMenuAtAll = false;
  fullWidth = false;

  rightWidth = '41px';
  leftWidth = '41px';

  @ViewChild('searchInputRef', {static: true}) searchInputRef: ElementRef;

  subs: Subscription[] = [];

  constructor(
    public ls: LayoutService,
    private lang: LanguageService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
    private location: Location
  ) {
    this.subs.push(this.ls.searchLoading.subscribe((loading: boolean) => {
      this.loading = loading;
      this.cd.detectChanges();
    }));

    this.subs.push(this.lang.languageChanged.subscribe(() => {
      this.isRtl = document.body.parentElement.getAttribute('dir') === 'rtl';
      this.cd.detectChanges();
    }));

    this.subs.push(this.ls.pagetitle.subscribe((title: string) => {
      this.title = title;
      document.title = 'Better Chains - ' + title;
      this.cd.detectChanges();
    }));

    this.subs.push(this.ls.showSearch.subscribe(show => {
      this.showSearch = show;
      if (show === false) {
        this.showSearchTextbox = false;
        this.searchInputRef.nativeElement.value = '';
        this.searchInputRef.nativeElement.blur();
      }
      this.rightWidth = show ? '82px' : '41px';
      this.cd.detectChanges();
    }));

    this.subs.push(this.ls.searchPlaceholder.subscribe(placeholder => {
      this.searchPlaceholder = placeholder;
    }));

    this.subs.push(this.ls.showSearchFilters.subscribe(show => {
      this.showSearchFilters = show;
    }));

    this.subs.push(this.ls.closeSearch.subscribe(() => {
      this.closeSearch();
    }));

    this.subs.push(this.ls.showBurger.subscribe(show => {
      this.showBurger = show;
      if (show) {
        this.showBack = false;
        this.showClose = false;
        this.backLink = '';
        this.closeLink = '';
      }
      this.resizeLeftWidth();
      this.cd.detectChanges();
    }));

    this.subs.push(this.ls.showBack.subscribe(show => {
      this.showBack = show;
      if (show) {
        this.showBurger = false;
        this.showClose = false;
        this.closeLink = '';
      }
      this.resizeLeftWidth();
      this.cd.detectChanges();
    }));

    this.subs.push(this.ls.showClose.subscribe(show => {
      this.showClose = show;
      if (show) {
        this.showBack = false;
        this.showBurger = false;
        this.backLink = '';
      }
      this.resizeLeftWidth();
      this.cd.detectChanges();
    }));

    this.subs.push(this.ls.backLink.subscribe((link: string) => {
      this.backLink = link;
      if (link.trim() !== '') {
        this.showBurger = false;
        this.showClose = false;
        this.closeLink = '';
      }
      this.resizeLeftWidth();
      this.cd.detectChanges();
    }));

    this.subs.push(this.ls.closeLink.subscribe((link: string) => {
      this.closeLink = link;
      if (link.trim() !== '') {
        this.showBurger = false;
        this.showBack = false;
        this.backLink = '';
      }
      this.resizeLeftWidth();
      this.cd.detectChanges();
    }));

    this.subs.push(this.ls.notificationsCount.subscribe((count: number) => {
      this.notificationsCount = count;
      this.cd.detectChanges();
    }));

    this.subs.push(this.ls.notificationsCount.subscribe((count: number) => {
      this.notificationsCount = count;
      this.cd.detectChanges();
    }));

    this.subs.push(this.ls.dontShowMenuAtAll.subscribe((dontShowMenuAtAll: boolean) => {
      this.dontShowMenuAtAll = dontShowMenuAtAll;
      this.cd.detectChanges();
    }));

    this.fullWidth = this.ls.headerFullWidth;

    this.subs.push(this.ls.fullWidth.subscribe((fullWidth: boolean) => {
      this.fullWidth = fullWidth;
      this.cd.detectChanges();
    }));
  }

  resizeLeftWidth() {
    this.leftWidth = this.showBurger ? '41px' : '82px';
  }

  openMenu() {
    this.ls.menustate.emit(true);
    this.cd.detectChanges();
  }

  clickBack() {
    if (this.backLink.trim() === '') {
      this.cd.detectChanges();
      this.location.back();
    } else {
      this.cd.detectChanges();
      this.router.navigateByUrl(this.backLink).then(r => r);
    }
  }

  clickClose() {
    if (this.closeLink.trim() === '') {
      this.cd.detectChanges();
      const s = this.location.getState();
      if (!s) {
        this.location.back();
      }
      this.location.back();
    } else {
      this.cd.detectChanges();
      this.router.navigateByUrl(this.closeLink).then(r => r);
    }
  }

  searchKeyUp($event: KeyboardEvent) {
    const ie = ($event.target as HTMLInputElement);
    if ($event.keyCode === 27) {
      this.clearSearchText();
    }
    this.searchText = ie.value;
    this.ls.searchTextChange.emit({text: this.searchText, key: $event.keyCode});
    // this.cd.markForCheck();
  }

  showSearchInput(e: MouseEvent) {
    e.preventDefault();
    this.showSearchTextbox = true;
    setTimeout(() => this.ngZone.run(() => this.searchInputRef.nativeElement.focus()), 0);
  }

  clearSearchText() {
    this.searchText = '';
    this.searchInputRef.nativeElement.value = '';
    this.ls.searchTextChange.emit({text: '', key: 0});
    this.searchInputRef.nativeElement.focus();
    this.cd.detectChanges();
  }

  closeSearch() {
    this.searchInputRef.nativeElement.value = '';
    this.searchText = '';
    this.showSearchTextbox = false;
    this.ls.searchTextChange.emit({text: '', key: 0});
    this.ls.searchClosed.emit();
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

}
