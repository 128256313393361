import {ChangeDetectorRef, Component, Input, NgZone} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {ApiService} from '../../../../services/api.service';
import {LoaderService} from '../../../../services/loader.service';
import {StateService} from '../../../../services/state.service';
import {TranslocoService} from '@ngneat/transloco';
import {ComponentService} from '../../../../components/component.service';
import {IApiDeleteAvatarResponse, IApiUpdateAvatarResponse} from './IApiAvatar';
import {SnackbarService} from '../../../../components/snackbar/snackbar.service';
import {CapacitorFilePickerService} from '../../../../../../projects/forms-render/src/lib/svc/capacitor-file-picker.service';
import {Subscription} from 'rxjs';
import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
  selector: 'foh-user-profile-avatar',
  templateUrl: './avatar.component.html',
  styles: [`
    .foh-avatar-buttons {
      display: flex;
      margin: 0 15px;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  `]
})
export class AvatarComponent {
  isApp = environment.isApp;
  @Input() avatarUrl = '';
  croppedImageBase64 = '';
  changingAvatar = false;
  imageChangedEvent: any = '';
  imageBase64 = '';
  isRtl = false;
  subs: Subscription[] = [];

  ALLOWED_IMAGE_TYPE = ['image/jpeg', 'image/png'];

  constructor(
    private apiService: ApiService,
    private loader: LoaderService,
    private state: StateService,
    private ngZone: NgZone,
    private t: TranslocoService,
    private cd: ChangeDetectorRef,
    private snackbar: SnackbarService,
    private componentService: ComponentService,
    private capacitorFilePicker: CapacitorFilePickerService
  ) {
    this.isRtl = document.body.parentElement.getAttribute('dir') === 'rtl';
  }

  deleteAvatarConfirm() {
    const text = 'Are you sure you want to remove the picture?';
    this.subs.push(this.componentService.confirm({
      title: 'Remove picture?',
      message: text,
      okText: 'Remove',
      closeText: 'Cancel',
    }).subscribe({
      next: (r) => {
        switch (Number(r)) {
          case this.componentService.ALERT_OK:
            this.deleteAvatar();
            break;
          case this.componentService.ALERT_CLOSE:
            break;
          default:
            break;
        }
      }
    }));
    return false;
  }

  deleteAvatar() {
    this.loader.showLoader();
    this.apiService.deleteAvatar()
      .subscribe((res: IApiDeleteAvatarResponse) => {
        if (res.data.newAvatarUrl.startsWith('http')) {
          this.avatarUrl = res.data.newAvatarUrl;
        } else {
          this.avatarUrl = environment.apiUrl + res.data.newAvatarUrl;
        }
        this.state.updateAvatar.emit(this.avatarUrl);
        this.loader.hideLoader();
        this.snackbar.clear();
        this.snackbar.add({
          msg: this.t.translate('Profile picture has been deleted!'),
          action: {text: this.t.translate('Ok')},
        });
      });
  }

  updateAvatar() {
    if (this.croppedImageBase64) {
      const fd = new FormData();
      const f = this.capacitorFilePicker.dataURLtoFile(this.croppedImageBase64, 'avatar.png');
      fd.append('avatar', f, 'avatar.png');
      this.loader.showLoader();
      this.apiService.updateAvatar(fd)
        .subscribe((res: IApiUpdateAvatarResponse) => {
          this.loader.hideLoader();
          if (res.data.newAvatarUrl.startsWith('http')) {
            this.avatarUrl = res.data.newAvatarUrl;
          } else {
            this.avatarUrl = environment.apiUrl + res.data.newAvatarUrl + '?' + (new Date()).getUTCSeconds();
          }
          this.state.delUserData();
          this.state.updateAvatar.emit(this.avatarUrl);
          this.changingAvatar = false;
          this.snackbar.clear();
          this.snackbar.add({
            msg: this.t.translate('Your profile picture has been updated!'),
            action: {text: this.t.translate('Ok')},
          });
        });
    }
    return false;
  }

  fileChangeEvent(event: Event): void {
    if (typeof event.target !== 'undefined') {
      const uploadedFile = (event.target as HTMLInputElement)?.files[0];
      const fileType = uploadedFile?.type;
      const found = this.ALLOWED_IMAGE_TYPE.includes(fileType);
      if (found === true) {
        this.changingAvatar = true;
        this.imageChangedEvent = event;
      } else {
        this.subs.push(this.componentService.confirm({
          title: 'File has not been uploaded',
          message: 'Please, check the file type. File type should be *.png, *.jpg, *.jpeg',
          okText: '',
          closeText: 'Close'
        }).subscribe({
          next: () => {
          }
        }));
      }
    }
  }

  cancelEditAvatar() {
    this.changingAvatar = false;
    this.cd.detectChanges();
    return false;
  }

  appFilePicker() {
    this.capacitorFilePicker.RunAsync(false, 320, 320)
      .then(imageDataArray => {
        if (imageDataArray.length > 0) {
          this.ngZone.run(() => {
            this.changingAvatar = true;
            this.imageBase64 = 'data:image/jpeg;base64,' + imageDataArray[0].data;
          });
        }
      });
  }

  imageCropped(ice: ImageCroppedEvent) {
    this.croppedImageBase64 = ice.base64;
  }
}
