<h1>{{'Enter Verification Code' | transloco}}</h1>
<p class="text-center">
  <ng-container *ngIf="isIos">{{'Enter the code sent to'|transloco}}</ng-container>
  <ng-container *ngIf="!isIos">
    {{'Please enter verification code we have sent to'|transloco}}
  </ng-container>
  <br>
  <span *ngIf="userLoginService.loginCodeRequestedTo=='email'">
    ({{'Email'|transloco}} : <bdo dir="ltr">{{emailHint}}</bdo>)
  </span>
  <span *ngIf="userLoginService.loginCodeRequestedTo=='sms'">
    ({{'Phone ending with'|transloco}} : <bdo dir="ltr">{{phoneHint}}</bdo>)
  </span>
</p>
<section>
  <input type="text" [(ngModel)]="code"
         (keyup)="formKeyUp($event)"
         [placeholder]="'Code'|transloco" class="form-control">
</section>
<p *ngIf="hasError" class="text-danger">{{errorMessage}}</p>
<section>
  <button class="btn btn-lg btn-danger" (click)="verifyCode()" [disabled]="code == ''">{{'Sign In'|transloco}}</button>
</section>
<p class="text-center">
  {{"Didn't get the code?"|transloco}}
  <a routerLink="/user/login/code-prompt">{{'Send again'|transloco}}</a>
</p>
