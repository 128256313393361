import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'foh-content',
  template: '<ng-content></ng-content>',
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 15px;

      &[no-padding] {
        padding: 0
      }
    }

    @media screen and (max-width: 479px) {
      :host {
        width: 100%;
      }
    }

    @media screen and (min-width: 480px) and (max-width: 639px) {
      :host {
        width: 100%;
        max-width: 640px;
      }
    }

    @media screen and (min-width: 640px) and (max-width: 959px) {
      :host {
        width: 100%;
        max-width: 959px;
      }
    }

    @media screen and (min-width: 960px) {
      :host {
        width: 100%;
        max-width: 960px;
      }
    }

  `]
})
export class FohContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
