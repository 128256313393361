import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[superhtml]'
})
export class SuperHtmlDirective {

  @Input()
  set superhtml(v: string) {
    this.setHtml(this.el.nativeElement, v);
  }

  constructor(private el: ElementRef) {
  }

  setHtml(elm: HTMLDivElement, html) {
    elm.innerHTML = html;
    Array.from(elm.querySelectorAll('script')).forEach(oldScript => {
      const newScript = document.createElement('script');
      Array.from(oldScript.attributes)
        .forEach(attr => newScript.setAttribute(attr.name, attr.value));
      newScript.appendChild(document.createTextNode(oldScript.innerHTML));
      oldScript.parentNode.replaceChild(newScript, oldScript);
    });
  }
}
