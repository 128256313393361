import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StateService} from '../../../services/state.service';
import {LoaderService} from '../../../services/loader.service';
import {LayoutService} from '../../../layout/main/layout.service';
import {ApiService} from '../../../services/api.service';
import {IApiChain} from '../../../models/IApiChain';
import {IApiUser} from '../../../models/IApiUser';
import {LanguageService} from '../../../services/language.service';
import {Subscription} from 'rxjs';
import {ComponentService} from '../../../components/component.service';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent implements OnInit, OnDestroy {

  firstName = '';
  showSignatureDialog = false;
  subs: Subscription[] = [];

  constructor(
    private router: Router,
    private loader: LoaderService,
    private layout: LayoutService,
    private language: LanguageService,
    private api: ApiService,
    private state: StateService,
    private componentService: ComponentService
  ) {
    document.title = 'Better Chains - Introduction';
    const ud = this.state.getUserData();
    if(!ud) {
      return;
    }
    this.firstName = ud.first_name;
    this.subs.push(this.api.getChainCached(true)
      .subscribe((chain: IApiChain) => {
        this.api.getUserCached(true)
          .then((user: IApiUser) => {
            user.branches.forEach((branch) => {
              if (Number(branch.branch_id) === Number(user.current_branch_id)) {
                this.language.setupLanguageFromSettings(chain, branch, user);
              }
            });
          });
      }));
  }

  ngOnInit() {
    this.layout.headerstate.emit(false);
  }

  clickChangeSignature() {
    this.showSignatureDialog = true;
    this.componentService.showSignDialogPopup();
  }

  closeSignatureDialog() {
    this.showSignatureDialog = false;
  }

  signatureSubmitted() {
    this.state.delUserData();
    this.showSignatureDialog = false;
    this.state.updateUser.emit();
    this.subs.push(this.api.getNextToSign().subscribe((res: any) => {
      this.subs.push(this.api.getPendingFormsCount().subscribe((resPending: any) => {
        if (Number(res.data.unsignedCount) > 0 || Number(resPending.data) > 0) {
          this.router.navigateByUrl('/signDocs').then(() => this.loader.hideLoader());
        } else {
          this.router.navigateByUrl('/dashboard').then(() => this.loader.hideLoader());
        }
      }));
    }));
  }

  signatureSubmit() {
    this.loader.showLoader();
  }

  logout() {
    this.subs.push(this.api.logout()
      .subscribe(() => {
        this.router.navigateByUrl('/user/login');
      }));
    return false;
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
