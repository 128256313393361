import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  public formDataCache: any[] = [];
  private formData: any[] = [];
  public goToForm: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  push(data) {
    this.formData.push(data);
  }

  getIDs(): number[] {
    let res = [];
    // alert("formData in formService\n"+JSON.stringify(this.formData)+"\ndata in dataCache:\n"+JSON.stringify(this.formDataCache))
    if (Array.isArray(this.formData)) {
      if (this.formData.length > 0) {
        this.formData.map(fd => {
          if (Array.isArray(fd)) {
            fd.map(f => {
              res.push(Number(f.fid));
            });
          } else if ('fid' in fd) {
            res.push(fd.fid);
          }
        });
      }
    }
    return res;
  }

  pull() {
    return this.formData.pop();
  }

  pullById(id: number) {
    let res = null;
    this.formData.forEach(datablock => {
      if (Array.isArray(datablock)) {
        datablock.forEach(fd => {
          if (fd.fid === id) {
            res = fd;
            this.formData = [];
          }
        });
      } else {
        if (datablock.fid == id) {
          res = datablock;
          this.formData = [];
        }
      }
    });
    return res;
  }

  hasNFCDataById(id: number): boolean {
    let res = false;
    if (Array.isArray(this.formData)) {
      this.formData.forEach(fd => {
        if (Array.isArray(fd)) {
          fd.forEach(f => {
            if ('fid' in f && f.fid == id && f.type == 'NFC') {
              res = true;
            }
          });
        } else if ('fid' in fd && fd.fid == id && fd.type == 'NFC') {
          res = true;
        }
      });
    }
    return res;
  }

  hasData(): boolean {
    return this.formData.length > 0;
  }

  empty() {
    this.formData = [];
  }
}
