import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainModule} from './layout/main/main.module';
import {ApiService} from './services/api.service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ComponentsModule} from './components/components.module';
import {PagesModule} from './pages/pages.module';
import {ModalModule} from 'ngx-bootstrap/modal';
import {UserLoginModule} from './modules/user/login/user-login.module';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {StateService} from './services/state.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SnackbarModule} from './components/snackbar/snackbar.module';
import {TranslocoRootModule} from './transloco-root.module';
import {LanguageService} from './services/language.service';
import {JoditAngularModule} from 'jodit-angular';
import {registerLocaleData} from '@angular/common';

// NG-BS locales
import {defineLocale} from 'ngx-bootstrap/chronos';
import {esLocale, frLocale, heLocale, roLocale, ruLocale} from 'ngx-bootstrap/locale';
import {UserModule} from './modules/user/user.module';
import {ErrorLoggerService} from './services/error-logger.service';
import {HttpInterceptorService} from './services/http-interceptor.service';
import {Select2Module} from 'ng-select2-component';
import {NgMapsCoreModule} from '@ng-maps/core';
import {GOOGLE_MAPS_API_CONFIG} from '@ng-maps/google';


import localeRuCa from '@angular/common/locales/ru';
import localeHeCa from '@angular/common/locales/he';
import localeRoCa from '@angular/common/locales/ro';
import localeFrCa from '@angular/common/locales/fr';
import localeEsCa from '@angular/common/locales/es';
import {SortablejsModule} from 'nxt-sortablejs';
import {BcGqlModule} from '@betterchains/bc-gql';
import {environment} from '../environments/environment';

// import localeHeCaExtra from '@angular/common/locales/global/he';
// import localeRuCaExtra from '@angular/common/locales/global/ru';
// import localeFrCaExtra from '@angular/common/locales/global/fr';
// import localeEsCaExtra from '@angular/common/locales/global/es';
// import localeRoCaExtra from '@angular/common/locales/global/ro';

defineLocale('he', heLocale);
defineLocale('ru', ruLocale);
defineLocale('ro', roLocale);
defineLocale('fr', frLocale);
defineLocale('es', esLocale);

registerLocaleData(localeHeCa);
registerLocaleData(localeRuCa);
registerLocaleData(localeRoCa);
registerLocaleData(localeFrCa);
registerLocaleData(localeEsCa);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ComponentsModule,
    PagesModule,
    UserLoginModule,
    UserModule,
    MainModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SnackbarModule.forRoot(),
    PopoverModule.forRoot(),
    TimepickerModule.forRoot(),
    TranslocoRootModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    NgMapsCoreModule,
    Select2Module,
    BrowserModule,
    JoditAngularModule,
    SortablejsModule.forRoot({animation: 150}),
    BcGqlModule.forRoot({
      isLocalhost: true,
      apiUrl: environment.apiUrl,
      staticUrl: environment.staticUrl,
    }),
  ],
  providers: [
    HttpClient,
    ApiService,
    StateService,
    LanguageService,
    {provide: ErrorHandler, useClass: ErrorLoggerService},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useValue: {
        apiKey: 'AIzaSyAObbmOETMCJrUPjeBozndTEKHmIOfPaus'
      }
    }
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}

