<h1>{{'We need to verify your identity' | transloco}}</h1>
<div class="alert alert-danger" *ngIf="hasError">
  <p class="text-danger">{{errorMessage}}</p>
</div>
<div class="user-check-icon" *ngIf="!hasError">
  <i class="fa-light fa-user-check"></i>
</div>
<p *ngIf="!hasError">{{'How would you like to receive your verification code?'|transloco}}</p>
<section *ngIf="!hasError">
  <input type="radio" [value]="'email'" id="sendTypeEmail" [(ngModel)]="sendType">
  <i class="fa-light fa-envelope"></i>
  <label for="sendTypeEmail">{{'Email it'|transloco}} <bdo dir="ltr">({{emailHint}})</bdo></label>
</section>
<section *ngIf="!hasError">
  <input type="radio" [value]="'sms'" id="sendTypeSms" [(ngModel)]="sendType">
  <i class="fa-light fa-message"></i>
  <label for="sendTypeSms">
    <bdi>{{'Text it'|transloco}}&nbsp;</bdi>
    <bdo dir="ltr">({{phoneHint}})</bdo></label>
</section>
<section>
  <button class="btn btn-lg btn-danger" (click)="requestCode()">{{'Send'|transloco}}</button>
</section>
