import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {PagesApiService} from './pages-api.service';
import {StateService} from '../services/state.service';
import {Browser} from '@capacitor/browser';

@Injectable({
  providedIn: 'root'
})
export class PageNotFoundHandlerService {
  subs: Subscription[] = [];
  user = null;
  STORAGE_ACTIONS_ACL = [
    {url: 'storage.', acl: 'storage.file'},
    {url: 'storage.imageUpload', acl: 'storage.imageUpload'}
  ];
  redirectUrl = null;
  ACTION_SEPARATOR = '#';
  QUESTION_SIGN = '?';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: PagesApiService,
    private state: StateService
  ) { }

  initStatus(){
    this.getUserInfo();
    this.subs.push(this.route.params.subscribe(async () => {
      this.prepareAclActionToCheck(this.router.url);
    }));
  }

  prepareAclActionToCheck(action){
      let actionStr = action.substring(1);
      actionStr = actionStr.replace(new RegExp('/', 'g'), '.');
      let actionToCheck = actionStr;

      for (const act of this.STORAGE_ACTIONS_ACL) {
        if (actionStr.includes(act.url)) {
          actionToCheck = act.acl;
        }
      }

      if (actionToCheck.includes(this.ACTION_SEPARATOR)) {
         actionToCheck = actionToCheck.split(this.ACTION_SEPARATOR)[0];
         actionToCheck = actionToCheck.replace(/\.\d+./g, '');
      }

      if (actionToCheck.includes(this.QUESTION_SIGN)) {
        actionToCheck = actionToCheck.split(this.QUESTION_SIGN)[0];
      }

      this.checkAccess(actionToCheck);
  }


  checkAccess(action) {
    this.subs.push(
      this.api.checkAccess(this.user.id, action)
        .subscribe(
          (data: any) => {
            if (data?.data === true) {
              this.getUrl();

            }
          },
          () => {
          }
        )
    );
  }

  getUrl() {
    this.subs.push(
      this.api.getUrl(this.router.url)
        .subscribe(
          (data1: any) => {
            if (data1.data) {
              this.redirectUrl = data1.data;
            }
          },
          () => {
          }
        )
    );
  }

  redirectAction(){
    if (this.redirectUrl) {
      environment.isApp ? Browser.open({url: this.redirectUrl}) : window.open(this.redirectUrl, '_blank');
    }
  }

  getUserInfo() {
    this.user = this.state.getUserData();
  }

  unsubscribeSubs(){
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
