import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {MainModule} from '../layout/main/main.module';
import {RouterModule} from '@angular/router';
import {ComponentsModule} from '../components/components.module';
import {TranslocoModule} from '@ngneat/transloco';
import {LayoutComponent} from './layout/layout.component';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    LayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MainModule,
    ComponentsModule,
    TranslocoModule
  ],
  exports: [PageNotFoundComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule { }

