import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {LayoutService} from '../../layout/main/layout.service';
import {PageNotFoundHandlerService} from '../page-not-found-handler.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {

  constructor(
    private location: Location,
    private layout: LayoutService,
    public pageNotFoundHandler: PageNotFoundHandlerService,
  ) {
    this.pageNotFoundHandler.initStatus();
  }

  back() {
    this.location.back();
  }

  redirect(){
    this.pageNotFoundHandler.redirectAction();
  }

  ngOnInit() {
    this.layout.headerstate.emit(false);
  }

  ngOnDestroy(): void {
    this.pageNotFoundHandler.unsubscribeSubs();
  }

}
