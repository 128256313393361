export function injectExternalScriptFile(id: string, url: string, module: boolean, loaded: () => void) {
  let loader: HTMLScriptElement = document.getElementById(id) as HTMLScriptElement;
  if (loader === null) {
    loader = document.createElement('script') as HTMLScriptElement;
    loader.src = url;
    loader.crossOrigin = 'anonymous';
    loader.referrerPolicy = 'no-referrer';
    if (module) {
      loader.type = 'module';
    }
    loader.onload = () => {
      loaded();
    };
    document.head.append(loader);
  }
}

export function injectExternalStyleWithLinkTag(id: string, url: string, loaded: () => void) {
  let css: HTMLLinkElement = document.getElementById(id) as HTMLLinkElement;
  if (css === null) {
    css = document.createElement('link') as HTMLLinkElement;
    css.href = url;
    css.crossOrigin = 'anonymous';
    css.referrerPolicy = 'no-referrer';
    css.rel = 'stylesheet';
    css.onload = () => loaded();
    document.head.append(css);
  }
}

export function injectResources(
  css_array: { id: string, url: string }[],
  js_array: { id: string, url: string, module: boolean }[]): Promise<void> {
  let cssloaded = 0;
  let jsloaded = 0;
  return new Promise<void>(resolve => {
    css_array.forEach(css => {
      injectExternalStyleWithLinkTag(css.id, css.url, () => {
        cssloaded++;
        if (cssloaded == css_array.length && jsloaded == js_array.length) {
          resolve();
        }
      });
    });
    js_array.forEach(js => {
      injectExternalScriptFile(js.id, js.url, js.module, () => {
        jsloaded++;
        if (cssloaded == css_array.length && jsloaded == js_array.length) {
          resolve();
        }
      });
    });
  });
}
