import {ErrorHandler, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {version} from "../../environments/version";
import {buildnumber} from "../../environments/buildnumber";
import {StateService} from "./state.service";

@Injectable({
  providedIn: 'root'
})
export class ErrorLoggerService extends ErrorHandler {

  constructor(
    private http: HttpClient,
    private state: StateService
  ) {
    super();
  }

  handleError(error: Error) {
    super.handleError(error);

    if (!this.state.connected) {
      return;
    }

    const name = error.constructor.name;
    const message = error.message;
    const trace = (error.stack) ? error.stack.toString() : '';
    const date = (new Date());

    const log: IErrorLoggerData = {
      dateString: date.toString(),
      timestamp: date.getTime(),
      name,
      message,
      trace,
      isApp: environment.isApp,
      production: environment.production,
      version: version + '.' + buildnumber,
      userAgent: navigator.userAgent
    };

    this.send(log);
  }

  public send(log) {
    this.http.post(environment.apiUrl + '/api/log/ngFoh', log)
      .subscribe(
        () => {
          // console.log('Log sent to server:' + JSON.stringify(log));
        },
        e => {
          console.log('Was unable to send log to server: ' + JSON.stringify(e))
        }
      );
  }
}

export interface IErrorLoggerData {
  dateString: string;
  timestamp: number;
  name: string;
  message: string;
  trace: string;
  isApp: boolean;
  production: boolean;
  version: string;
  userAgent: string;
}
