import {Component} from '@angular/core';
import {UserLoginService} from '../user-login.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'foh-user-login-select-chain',
  styleUrls: ['select-chain.component.scss'],
  templateUrl: 'select-chain.component.html'
})
export class SelectChainComponent {

  subs: Subscription[] = [];

  sError = '';

  constructor(
    public userLoginService: UserLoginService,
    private router: Router,
  ) {
    if (this.userLoginService.chains.length == 0) {
      this.router.navigateByUrl('/user/login').then();
      return;
    }
  }



}
