/* tslint:disable:no-string-literal */
import {ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ApiService} from '../../../services/api.service';
import {Router} from '@angular/router';
import {LoaderService} from '../../../services/loader.service';
import {StateService} from '../../../services/state.service';
import {environment} from '../../../../environments/environment';
import {TranslocoService} from '@ngneat/transloco';
import {LanguageService} from '../../../services/language.service';
import {version} from '../../../../environments/version';
import {PushNotificationService} from '../../../services/push-notification.service';
import {Subscription} from 'rxjs';
import {DeviceDetectorService} from 'ngx-device-detector';
import {UserLoginService} from './user-login.service';

@Component({
  selector: 'foh-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss', '../../../components/dialog/dialog.component.scss']
})
export class UserLoginComponent implements OnInit, OnDestroy {

  domainSelectTimer;

  bLoading = false;

  apiUrl = ''; // FOH.api;


  LinkLists = [];

  rememberMe = 1;

  chainId = 0; // FOH.Settings.chainId;

  showSelectChain = false;
  showSelectBranch = false;

  forgotPasswordEmail = '';
  forgotPasswordEmailError = '';

  timeZoneId = '';
  version = '';
  currentLanguage = '';
  FOHversion = version; // + '.' + buildnumber;

  modalRef: BsModalRef;
  subs: Subscription[] = [];

  isTestDomain = false;
  testDomain = '';
  testEnv = '';


  currentChainId = null;
  allChains: any[];


  currentComponentClass = '';
  ADDITIONAL_BODY_CLASS = 'height-100';
  setClass = '';

  get hostElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  constructor(
    public userLoginService: UserLoginService,
    private modalService: BsModalService,
    private api: ApiService,
    private router: Router,
    private t: TranslocoService,
    private loader: LoaderService,
    private push: PushNotificationService,
    private ngZone: NgZone,
    private language: LanguageService,
    private state: StateService,
    private cd: ChangeDetectorRef,
    public _elementRef: ElementRef<HTMLElement>,
    private deviceDetector: DeviceDetectorService
  ) {
    window.addEventListener('resize', this.windowResizeListener);
    // this.loadChains();
    this.addSetClass();
    this.setComponentClass('login');
    document.title = 'Better Chains - ' + this.t.translate('Sign In');

    this.timeZoneId = this.state.getTimeZoneId();
    this.subs.push(
      this.state.updateTimeZone.subscribe(() => {
        this.timeZoneId = this.state.getTimeZoneId();
      })
    );
    if (environment.domainMode) {
      this.FOHversion = window['BOHAPIVersion'] + ' / ' + version;
    } else if (environment.isApp) {
      this.FOHversion = this.state.version;
    }

    this.subs.push(
      this.userLoginService.chainBeenSet.subscribe({next: () => cd.detectChanges()})
    );
  }

  addSetClass() {
    if (this.deviceDetector.isMobile()) {
      this.setClass = window.innerHeight > window.innerWidth ? this.ADDITIONAL_BODY_CLASS : '';
    } else {
      this.setClass = this.ADDITIONAL_BODY_CLASS;
    }
    this.setBodyClass(this.setClass);
  }

  windowResizeListener: () => void = () => {
    this.ngZone.run(() => {
      this.addSetClass();
    });
  };

  // checkTestDomain() {
  //   const testApiUrl = localStorage.getItem('test-apiUrl');
  //   const testStaticUrl = localStorage.getItem('test-staticUrl');
  //   const testEnv = localStorage.getItem('test-env');
  //   if (
  //     testApiUrl != null && typeof testApiUrl == 'string' && testApiUrl.trim() != '' &&
  //     testStaticUrl && typeof testStaticUrl == 'string' && testStaticUrl.trim() != '' &&
  //     testEnv && typeof testEnv == 'string' && testEnv.trim() != ''
  //   ) {
  //     this.testDomain = testApiUrl;
  //     this.testEnv = testEnv;
  //     this.isTestDomain = true;
  //   }
  // }

  setBodyClass(cl: string) {
    (document.getElementsByTagName('body')[0] as HTMLBodyElement)
      .className = cl;
  }

  setComponentClass(c: string) {
    if (this.currentComponentClass !== '') {
      this.hostElement.classList.remove(this.currentComponentClass);
    }

    if (c !== '') {
      this.hostElement.classList.add(c);
    }

    this.currentComponentClass = c;
  }


  // forgotPassword(template) {
  //   this.forgotPasswordEmail = '';
  //   this.forgotPasswordEmailError = '';
  //   this.forgotPasswordSubmitPressed = false;
  //   this.showFinalMessageOnly = false;
  //   this.currentChainId = null;
  //   this.messageValidateChain = '';
  //   this.forgotEmailErrors = [];
  //   this.modalRef = this.modalService.show(template);
  //   this.showChainSelector = false;
  // }


  // loadChains(){
  //   this.subs.push(
  //     this.api.getChains(0)
  //       .subscribe(
  //         (res: any) => {
  //           this.allChains = Array.isArray(res.data) ? res.data : [];
  //         },
  //         (err: HttpErrorResponse) => {
  //           this.allChains = [];
  //         }
  //       )
  //   );
  // }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.setBodyClass('');
    window.removeEventListener('resize', this.windowResizeListener);
    this.subs.forEach(subscrption => subscrption.unsubscribe());
  }


  domainSelectStart() {
    if (!environment.isApp) {
      return;
    }
    clearTimeout(this.domainSelectTimer);
    this.domainSelectTimer = setTimeout(() => {
      clearTimeout(this.domainSelectTimer);
      this.router.navigateByUrl('/user/login/select-domain').then();
    }, 5000);
  }

  domainSelectEnd() {
    if (!environment.isApp) {
      return;
    }
    clearTimeout(this.domainSelectTimer);
  }

  // cancelDomainSelect() {
  //   this.showSelectDomain = false;
  //   this.checkTestDomain();
  // }

}
