import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {StateService} from './state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  constructor(
    private api: ApiService,
    private router: Router,
    private state: StateService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.state.getApiToken() === null || this.state.getApiToken() === '') {
      this.router.navigate(['/user/login']).then();
      return false;
    } else {
      return new Promise<boolean>((resolve) => {
        if(!this.state.connected) {
          resolve(true);
        } else {
          this.api.isAuthenticated()
            .subscribe(
              isLoggedIn => {
                this.api.getUserCached().then(user => {
                  if (!isLoggedIn) {
                    this.router.navigate(['/user/login']);
                    resolve(false);
                  } else if (state.url !== '/user/index' && !user.sign_picture) {
                    this.router.navigate(['/user/index']);
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                });
              },
              () => {
                resolve(true);
              }
            );
        }
      });
    }
  }
}
