import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './layout/main/main.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {AuthGuardService} from './services/auth-guard.service';
import {IntroductionComponent} from './modules/user/introduction/introduction.component';
import {LayoutComponent} from './pages/layout/layout.component';
import {WcFohSandboxComponent} from './pages/wc-foh-sandbox/wc-foh-sandbox.component';
import {WcFohSandboxDebuggerComponent} from './pages/wc-foh-sandbox/wc-foh-sandbox-debugger.component';
import {TestCapacitorComponent} from './pages/test-capacitor/test-capacitor.component';
import {LoginByPreparedTokenComponent} from './login-by-prepared-token.component';

const routes: Routes = [
  {
    path: 'user/login',
    loadChildren: () => import('./modules/user/login/user-login.module').then(m => m.UserLoginModule),
  },
  {
    path: 'user/loginByPreparedToken',
    component: LoginByPreparedTokenComponent
  },
  {
    path: 'user/index',
    component: IntroductionComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'test-capacitor',
        component: TestCapacitorComponent
      },
      {
        path: 'layout',
        component: LayoutComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'wc-foh-sandbox',
        component: WcFohSandboxComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'wc-foh-sandbox-debugger',
        component: WcFohSandboxDebuggerComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'layout/:name',
        component: LayoutComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'boss',
        loadChildren: () => import('./modules/boss/boss.module').then(m => m.BossModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'browseDocs',
        loadChildren: () => import('./modules/browse-docs/browse-docs.module').then(m => m.BrowseDocsModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'managerPortal',
        loadChildren: () => import('./modules/manager-portal/manager-portal.module').then(m => m.ManagerPortalModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'learningCenter',
        loadChildren: () => import('./modules/learning-center/learning-center.module').then(m => m.LearningCenterModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'testing',
        loadChildren: () => import('./modules/testing/testing.module').then(m => m.TestingModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'notification',
        loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'schedule',
        loadChildren: () => import('./modules/schedule/schedule.module').then(m => m.ScheduleModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'signDocs',
        loadChildren: () => import('./modules/sign-docs/sign-docs.module').then(m => m.SignDocsModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'user',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'emergency',
        loadChildren: () => import('./modules/emergency/emergency.module').then(m => m.EmergencyModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'formI9',
        loadChildren: () => import('./modules/formI9/formI9.module').then(m => m.FormI9Module),
        canActivate: [AuthGuardService]
      },
      {
        path: 'w4',
        loadChildren: () => import('./modules/w4/w4.module').then(m => m.W4Module),
        canActivate: [AuthGuardService]
      },
      {
        path: 'menu-planner',
        loadChildren: () => import('./modules/menu-planner/menu-planner.module').then(m => m.MenuPlannerModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'applicant',
        loadChildren: () => import('./modules/applicant/applicant.module').then(m => m.ApplicantModule),
        canActivate: [AuthGuardService]
      },
    ]
  },
  {path: '**', component: PageNotFoundComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
