<form class="form-wrapper">
  <div class="wrapper login-wrapper">
    <i class="fa-light fa-envelope login-icon" aria-hidden="true"></i>
    <div>
      <input [dir]="userLoginService.currentLanguage == LANGUAGE_HE_IL && !username ? 'rtl' : 'ltr'"
             [ngStyle]="{'text-align' : userLoginService.currentLanguage == LANGUAGE_HE_IL ? 'right' : 'left'}"
             (keyup)="formKeyUp($event)"
             placeholder="{{'Email' | transloco}}"
             name="username"
             class="text-field"
             type="email" required [(ngModel)]="username"/>
    </div>
    <div class="error" *ngIf="sEmailError">{{ sEmailError }}</div>
  </div>

  <div class="wrapper password-wrapper">
    <i class="fa-light fa-key password-icon" aria-hidden="true"></i>
    <div>
      <input (keyup)="formKeyUp($event)"
             class="text-field"
             placeholder="{{'Password' | transloco}}"
             name="password"
             [type]="showPasswordStatus ? 'text' : 'password'"
             [(ngModel)]="password"
             required/>
    </div>
    <i *ngIf="!showPasswordStatus"
       class="fa-light fa-eye view-password-icon"
       [ngClass]="userLoginService.currentLanguage == LANGUAGE_HE_IL ? 'view-password-icon-rtl' : 'view-password-icon-ltr'"
       (click)="showPassword()"
    ></i>
    <i *ngIf="showPasswordStatus"
       class="fa-light fa-eye-slash view-password-icon"
       [ngClass]="userLoginService.currentLanguage == LANGUAGE_HE_IL ? 'view-password-icon-rtl' : 'view-password-icon-ltr'"
       (click)="showPassword()"
    ></i>
    <div class="error" *ngIf="sPasswordError">{{ sPasswordError }}</div>
    <div class="error" *ngIf="sError">{{ sError }}</div>
  </div>

  <div class="wrapper actions-wrapper">
    <div class="action forgot-password">
      <a routerLink="restore-password" href="#"
         style="font-weight: 700;text-decoration: none">{{ 'Forgot password?' | transloco }}</a>
    </div>
  </div>
  <div class="wrapper">
    <button type="button" class="submit-button" (click)="login()">
      <span></span>
      {{ 'Sign In' | transloco }}
    </button>
  </div>
  <div class="wrapper jobs-wrapper" *ngIf="userLoginService.chainId > 0">
    <i class="fa-light fa-briefcase positions-icon"></i>
    <a href="/vacancy/search">
      <span></span>
      {{ 'Open Positions' | transloco }}
    </a>
  </div>
</form>
