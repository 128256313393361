import {Component, HostBinding, Input, NgZone} from '@angular/core';
import {LayoutService} from '../../layout/main/layout.service';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Capacitor} from '@capacitor/core';
import {Subscription} from 'rxjs';
import {SharedService} from '../../services/shared.service';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';

@Component({
  selector: 'foh-container',
  template: '<ng-content></ng-content>',
  styles: [`
    :host {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  `]
})
export class FohContainerComponent {

  hasMargin: SafeStyle|string = '"calc(env(safe-area-inset-top, \'auto\') + 41px)"';

  notMargin: SafeStyle|string = '0';
  subs: Subscription[] = [];
  hiddenMainMenuByParam = false;

  @HostBinding('style.margin-top') marginTop: SafeStyle|string = "calc(env(safe-area-inset-top, 'auto') + 41px)";

  @Input()
  set pageTitle(pageTitle: string) {
    setTimeout(() => this.ngZone.run(() => this.ls.pagetitle.emit(pageTitle)), 100);
  }

  @Input()
  set dontShowMenuAtAll(show: boolean) {
    setTimeout(() => this.ngZone.run(() => this.ls.dontShowMenuAtAll.emit(show)), 0);
  }

  @Input()
  set showBurger(show: boolean) {
    setTimeout(() => this.ngZone.run(() => this.ls.showBurger.emit(show)), 0);
  }

  @Input()
  set showBack(show: boolean) {
    setTimeout(() => this.ngZone.run(() => this.ls.showBack.emit(show)), 0);
  }

  @Input()
  set backLink(link: string) {
    setTimeout(() => this.ngZone.run(() => this.ls.backLink.emit(link)), 0);
  }

  @Input()
  set showClose(show: boolean) {
    setTimeout(() => this.ngZone.run(() => this.ls.showClose.emit(show)),0);
  }

  @Input()
  set closeLink(link: string) {
    setTimeout(() => this.ngZone.run(() => this.ls.closeLink.emit(link)), 0);
  }

  @Input()
  set showSearch(show: boolean) {
    setTimeout(() => this.ngZone.run(() => this.ls.showSearch.emit(show)), 0);
  }

  @Input()
  set searchPlaceholder(searchPlaceholder: string) {
    setTimeout(() => this.ngZone.run(() => this.ls.searchPlaceholder.emit(searchPlaceholder)), 0);
  }

  @Input()
  set searchFilters(show: boolean) {
    setTimeout(() => this.ngZone.run(() => this.ls.showSearchFilters.emit(show)), 0);
  }

  constructor(
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private ls: LayoutService,
    private shared: SharedService,
    private sanitizer: DomSanitizer
  ) {

    this.subs.push(this.shared.onChangeHeaderVisibility.subscribe((status) => {
      this.hiddenMainMenuByParam = status;
      this.checkMargin();
    }));

    this.checkMargin();
  }

  checkMargin(){
    const hideMainMenu = this.route.snapshot.queryParamMap.get('hideMainMenu') === 'true'
      || this.ls.hideMainMenu === true
      || this.hiddenMainMenuByParam === true;
    if (hideMainMenu) {
      this.marginTop = this.notMargin;
    } else {
      this.hasMargin = environment.isApp && Capacitor.getPlatform() === 'ios' ?
        this.sanitizer.bypassSecurityTrustStyle("calc(env(safe-area-inset-top, 'auto') + 41px)") : '41px';
      this.ls.headerstate.subscribe(show => {
        this.marginTop = (show) ? this.hasMargin : this.notMargin;
      });
    }
  }

}
