<h1>{{'Reset Password' | transloco}}</h1>

<form *ngIf="!showFinalMessageOnly" class="form-wrapper">
  <div class="wrapper chain-wrapper" *ngIf="!userLoginService.chainId && showChainSelector">
    <label class="control-label required">
      {{'Chain' | transloco}} <span class="required">*</span>
    </label>
    <div>
      <select name="currentChainId" class="form-control" [(ngModel)]="userLoginService.currentChainId">
        <option [value]="null">{{'Select...' | transloco}}</option>
        <ng-container *ngFor="let sch of chainSet">
          <ng-container *ngFor="let ch of chainsAvailable">
            <option *ngIf="sch==ch.chain_id" [ngValue]="ch.chain_id">{{ch.name}}</option>
          </ng-container>
        </ng-container>
      </select>
      <div class="errorMessage" *ngIf="!userLoginService.chainId && !userLoginService.currentChainId">
        {{messageValidateChain | transloco}}
      </div>
    </div>
  </div>
  <div class="wrapper login-wrapper">
    <i class="fa-light fa-envelope login-icon" aria-hidden="true"></i>
    <!--    <label class="control-label required" for="fp-email">-->
    <!--      {{'Email' | transloco}} <span class="required">*</span>-->
    <!--    </label>-->
    <div>
      <input class="text-field" [placeholder]="'Email' | transloco"
             name="email"
             [(ngModel)]="forgotPasswordEmail"
             (keyup)="formKeyUp($event)"
             id="fp-email" type="text"/>
      <div class="errorMessage" *ngIf="forgotEmailErrors?.length > 1">
        <ng-container *ngFor="let err of forgotEmailErrors">
          <ng-container *ngIf="err != informationMessage">
            {{err| transloco}}
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</form>

<ng-container *ngIf="showFinalMessageOnly">
  <div id="flash-messages" class="col-md-12">
    <div class="alert alert-success alert-dismissible" role="alert">
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
      {{informationMessage | transloco}}
    </div>
  </div>
</ng-container>
<!--  </div>-->
<!--</div>-->
<!--<div class="modal-footer modal-dialog-footer">-->
<button type="button" class="btn btn-lg btn-danger" *ngIf="!showFinalMessageOnly"
        style="margin-bottom: 10px"
        (click)="sendForgotPassword()">{{'Send' | transloco}}</button>
<button type="button" class="btn btn-lg btn-primary"
        (click)="goBack()"
        data-dismiss="modal">{{'Back' | transloco}}</button>
<!--</div>-->
