<section>
  <article>
    <pre><code [superhtml]="highlight1"></code></pre>
  </article>
  <aside [superhtml]="code1">
  </aside>
</section>

<div style="border-top: dotted 1px black"></div>

<section>
  <article>
    <pre><code [superhtml]="highlight3"></code></pre>
  </article>
  <aside [superhtml]="code3">
  </aside>
</section>

<div style="border-top: dotted 1px black"></div>

<section>
  <article>
    <pre><code [superhtml]="highlight4"></code></pre>
  </article>
  <aside [superhtml]="code4">
  </aside>
</section>
