import {HttpClient} from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule, isString
} from '@ngneat/transloco';
import {Injectable, NgModule} from '@angular/core';
import {environment} from '../environments/environment';

import * as dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/he';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/fr';
import {version} from '../environments/version';
import {buildnumber} from '../environments/buildnumber';

declare var NGFOHChainLocate: string;

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {
  }

  getTranslation(lang: string) {
    const v = version + '.' + buildnumber;
    return this.http.get<Translation>(environment.deployUrl + `/assets/i18n/${lang}.json?v=${v}`);
  }
}

let lslng = localStorage.getItem('user-selected-language');
if (lslng === null || lslng === undefined || lslng === '') {
  lslng = localStorage.getItem('selected-language');
}
let detectedLanguage = 'en';
if (lslng === null || lslng === undefined || lslng === '') {
  if (environment.isLocalhost || environment.isApp) {
    detectedLanguage = window.navigator.language.slice(0, 2);
    const chain = window.localStorage.getItem('chain');
    if (isString(chain)) {
      const chainObject: { default_locale: string } = JSON.parse(chain);
      if (chainObject.default_locale) {
        detectedLanguage = chainObject.default_locale.slice(0, 2);
      }
    }
  } else if (environment.domainMode && window.hasOwnProperty('NGFOHChainLocate')) {
    if (NGFOHChainLocate.trim() !== '') {
      const nl = navigator.language.slice(0, 2);
      switch (nl) {
        case 'en':
        case 'he':
        case 'ro':
        case 'ru':
        case 'fr':
        case 'es':
          detectedLanguage = nl;
          break;
        default:
          detectedLanguage = 'en';
      }
    }
  }
} else {
  detectedLanguage = lslng;
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'he', 'ru', 'ro', 'fr', 'es'],
        defaultLang: detectedLanguage,
        fallbackLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          allowEmpty: false,
          logMissingKey: false
        }
      })
    },
    {provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader}
  ]
})
export class TranslocoRootModule {
  constructor() {
    document.body.parentElement.setAttribute('dir', detectedLanguage === 'he' ? 'rtl' : 'ltr');
    document.body.parentElement.setAttribute('lang', detectedLanguage);
    dayjs.locale(detectedLanguage);
  }
}
