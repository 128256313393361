import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {IApiUser, IBranchShort} from '../models/IApiUser';
import {StateService} from './state.service';
import {Observable} from 'rxjs';
import {IApiChain} from '../models/IApiChain';
import {CookieService} from './cookie.service';
import {IApiUserMenuItem} from '../models/IApiUserMenu';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private state: StateService,
    private cookie: CookieService,
    private http: HttpClient
  ) {
  }

  public newResetPasswordCheck(code: string) {
    return this.http.put(
      `${environment.apiUrl}/api/user/resetPasswordFohV3`,
      {code}
    );
  }

  public newResetPassword(code: string, password: string) {
    return this.http.post(
      `${environment.apiUrl}/api/user/resetPasswordFohV3`,
      {
        code, password
      }
    );
  }

  public verifyLoginCode(code: string) {
    return this.http.post(
      `${environment.apiUrl}/api/user/verifyLoginCode?token=${this.state.getApiToken()}`,
      {
        code
      }
    );
  }

  public requestLoginCode(type: string) {
    return this.http.post(
      `${environment.apiUrl}/api/user/sendLoginCode?token=${this.state.getApiToken()}`,
      {
        type
      }
    );
  }

  public login(username: string, password: string) {
    return this.http.post(
      environment.apiUrl + '/api/user/login',
      {
        username,
        password,
        client_app: 'NG-FOH',
        client_version: this.state.version,
        platform: this.state.platform + (environment.isApp ? ' app' : '')
      }
    );
  }

  public removeFbToken() {
    return new Observable<void>(obs => {
      const fbtoken = localStorage.getItem('ng-foh-app-firebase-token');
      if (fbtoken !== null) {
        // console.log('FB token is not null: ' + fbtoken);
        const data = {
          fb_token: fbtoken,
          platform: 'Fbase/MNG'
        };
        this.http.post(
          `${environment.apiUrl}/api/user/removeFBToken?token=${this.state.getApiToken()}`,
          data
        ).subscribe(
          () => {
            // console.log('Executed removeFBToken API call successfully with data: ' + JSON.stringify(data))
            // console.log('API call result: ' + JSON.stringify(res))
            localStorage.removeItem('ng-foh-app-firebase-token');
            obs.next();
          },
          e => {
            console.log('Failed to execute removeFBToken with error: ' + JSON.stringify(e));
            obs.next();
          }
        );
      } else {
        // console.log('FB token is null - no need to remove it')
        obs.next();
      }
    });
  }

  public logout() {
    return new Observable<void>(obs => {
      this.http.post(
        `${environment.apiUrl}/api/user/logout?token=${this.state.getApiToken()}`,
        {}
      ).subscribe(() => {
        this.state.clear();
        // console.log('local storage cleaned up')
        if (environment.domainMode) {
          // console.log('Logging out in domain mode - redirect to /logout')
          window.location.href = '/logout';
        } else {
          obs.next();
        }
      });
    });
  }

  public setBranch(branchId: number, chainId?: number|null|undefined) {
    const obj: any = {
      branch_id: branchId,
      chain_id: chainId,
      get_login_link: true
    };

    if ( typeof chainId !== undefined && Number(chainId) > 0) {
      obj.chain_id = Number(chainId);
    }
    return new Observable<any>(obs => {
      this.http.post(
        environment.apiUrl + '/api/user/setBranch?token=' + this.state.getApiToken(),
        obj
      ).subscribe((r: any) => {
        // if (environment.domainMode) {
        //   this.http.post(
        //     '/api/user/setBranch',
        //     {
        //       branch_id: branchId,
        //       get_login_link: true
        //     }
        //   ).subscribe(() => {
        //       obs.next(r);
        //     }
        //   );
        // } else {
        obs.next(r);
        // }
      });
    });
  }

  public setChain(chainId: number) {
    return this.http.post(
      environment.apiUrl + '/api/user/setChainAndGetQuickLink?token=' + this.state.getApiToken(),
      {
        chain_id: chainId
      }
    );
  }

  public getChains(chainId: number) {
    return this.http.get(
      environment.apiUrl + '/api/user/getAllChains', {}
    );
  }

  public forgotPassword(email: string, currentChainId: number) {
    const obj = {
      email,
      currentChainId
    };
    return this.http.post(
      environment.apiUrl + '/api/user/forgotPassword', obj
    );
  }

  public deleteAvatar() {
    return this.http.delete(environment.apiUrl + '/api/user/avatar?token=' + this.state.getApiToken());
  }

  public updateAvatar(fd: FormData) {
    return this.http.post(
      environment.apiUrl + '/api/user/avatar?token=' + this.state.getApiToken(),
      fd
    );
  }

  public updatePassword(oldPassword, newPassword, confirmPassword) {
    return this.http.post(
      environment.apiUrl + '/api/user/changePassword?token=' + this.state.getApiToken(),
      {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword
      });
  }

  public async getUserCached(recache = false) {
    let u = this.state.getUserData();
    if (u === null || recache) {
      u = await this.getUser().toPromise();
      this.state.setUserData(u);
    }
    return u;
  }

  public getUser() {
    return this.http.get<IApiUser>(environment.apiUrl + '/api/user?token=' + this.state.getApiToken());
  }

  public async getUserMenuCached(recache = false, callback: (items: IApiUserMenuItem[]) => void = null) {
    let m = this.state.getUserMenu();
    if (m === null || recache) {
      m = await this.getUserMenu().toPromise();
      this.state.setUserMenu(m);
      if (callback) {
        callback(m.data);
      }
    }
    return m;
  }

  public getUserMenu() {
    return this.http.get(`${environment.apiUrl}/api/user/menu?fe=true&counters=false&token=${this.state.getApiToken()}`);
  }

  public setSign(signatureDataUrl: string) {
    return this.http.post(environment.apiUrl + '/api/user/setSign?v=3&token=' + this.state.getApiToken(), {
      SignPadV3: {
        image: signatureDataUrl,
        svg: false
      }
    });
  }

  public userUpdateEmailAndPhone(email: string, phone: string) {
    return this.http.post(environment.apiUrl + '/api/user/updateEmailAndPhone?token=' + this.state.getApiToken(), {
      email,
      phone
    });
  }

  public userUpdateLocale(defaultLocale: string) {
    return this.http.post(environment.apiUrl + '/api/user/updateLocale?token=' + this.state.getApiToken(), {
      default_locale: defaultLocale
    });
  }

  public switchBranch(branch: IBranchShort) {
    const data = {
      branchId: branch.branch_id,
      chainId: branch.chain_id
    };
    return new Observable<void>(obs => {
      this.http.post(environment.apiUrl + '/api/switch?token=' + this.state.getApiToken(), data)
        .subscribe(() => {
          if (!environment.domainMode) {
            obs.next();
          } else {
            this.http.post('/api/switch', data)
              .subscribe(() => {
                obs.next();
              });
          }
        });
    });
  }

  public isAuthenticated() {
    return this.http.get<boolean>(environment.apiUrl + '/api/auth?token=' + this.state.getApiToken());
  }

  public getChainCached(reload = false) {
    return new Observable(observer => {
      const chain: IApiChain = this.state.getChain();
      if (!chain || reload) {
        this.getChain().subscribe((res: any) => {
          if (res.status === true) {
            this.state.setChain(res.data);
            observer.next(res.data as IApiChain);
          }
        });
      } else {
        observer.next(chain);
      }
    });
  }

  private getChain() {
    return this.http.get(`${environment.apiUrl}/api/chain?token=${this.state.getApiToken()}`);
  }

  public setPushId(token: string, platform: string) {
    return this.http.post(
      `${environment.apiUrl}/api/user/setPushId?token=${this.state.getApiToken()}`,
      {push_id: token, platform: platform}
    );
  }

  public getNextToSign() {
    const nextToSignUrl = environment.apiUrl + '/api/signDocs/nextToSign?token=' + this.state.getApiToken();
    return this.http.get(nextToSignUrl);
  }

  public getPendingFormsCount() {
    const getPendingFormsCount = environment.apiUrl + '/api/forms/getPendingCount?token=' + this.state.getApiToken();
    return this.http.get(getPendingFormsCount);
  }
}
