<div class="backdrop" [style]="{display:show?'block':'none'}" (click)="ls.menustate.emit(false)"></div>
<div class="menu" [style]="{display:show?'flex':'none'}">
  <div class="logoblock" routerLink="/dashboard">
    <img *ngIf="(chain && branch) && (branch.logo || chain.logo)"
         [src]="branch.logo ? branch.logo : chain.logo" alt="Home">
  </div>
  <div class="branchpicker" *ngIf="user && ((user.branches && user.branches.length > 1) || user.canSwitchToChainLevel)">
    <select (change)="changeBranch($event)">
      <option value="" *ngIf="user.canSwitchToChainLevel">
        {{"All Branches"|transloco}}
      </option>
      <option
        [value]="branch.branch_id"
        [selected]="user.current_branch_id == branch.branch_id"
        *ngFor="let branch of user.branches">{{branch.name}}</option>
    </select>
  </div>
  <div class="userblock" *ngIf="user" routerLink="/user/profile">
    <img [alt]="'Profile Picture'|transloco" [ngSrc]="avatarUrl" width="50" height="50">
    <span>{{user.first_name}} {{user.last_name}}</span>
  </div>
  <div class="menublock">
    <ul>
      <li>
        <a [routerLink]="'/dashboard'">
          <span>{{'Home'| transloco}}</span>
        </a>
      </li>
      <li *ngFor="let menu of menu">
        <a [routerLink]="menu.route">
          <span>{{menu.label | transloco}}</span>
        </a>
      </li>
      <li *ngIf="showBackendMenu && user && user.backendUrl">
        <hr>
      </li>
      <li *ngIf="showBackendMenu && user && user.backendUrl">
        <span (click)="getLoginAdminUrl(user.backendUrl)"
              style="cursor: pointer"
               class="text"
        >{{'Admin Panel'|transloco}}</span>
      </li>
      <li><hr></li>
      <li>
        <a href="#" (click)="logout()"><span class="text">{{'Log Out'|transloco}}</span></a>
      </li>
    </ul>
  </div>
  <div class="versionblock">
    v{{version}}
    <span *ngIf="isTestDomain" style="color:red"><strong>[ {{testEnv}} ]</strong></span>
  </div>
</div>
