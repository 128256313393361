import {Component} from '@angular/core';
import {StateService} from '../../../services/state.service';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'foh-user-reset-password',
  template: ''
})
export class ResetPasswordComponent {

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private state: StateService
  ) {
    let code = this.route.snapshot.queryParamMap.get('code');
    code = (code == null) ? '' : code;
    const hasToken = (this.state.getApiToken() !== null || this.state.getApiToken() !== '');
    if (hasToken) {
      this.api.isAuthenticated().subscribe({
        next: (isLoggedIn) => {
          if (isLoggedIn) {
            this.router.navigateByUrl('/user/profile', {replaceUrl: true}).then();
          } else {
            this.router.navigateByUrl('/user/login/reset-password?code=' + code, {replaceUrl: true}).then();
          }
        }
      });
    } else {
      if (code.trim() == '') {
        this.router.navigateByUrl('/user/login', {replaceUrl: true}).then();
      } else {
        this.router.navigateByUrl('/user/login/reset-password?code=' + code, {replaceUrl: true}).then();
      }
    }
  }

}
