import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {injectResources} from '../../util/script-loader';
import {RouterLinkWithHref} from '@angular/router';
import {AngularSplitModule} from 'angular-split';
import {StateService} from '../../services/state.service';

declare var CodeMirror: any;

@Component({
  selector: 'wc-foh-sandbox-debugger',
  templateUrl: 'wc-foh-sandbox-debugger.component.html',
  styleUrls: ['wc-foh-sandbox-debugger.component.scss'],
  imports: [
    RouterLinkWithHref,
    AngularSplitModule,
  ],
  providers: [StateService],
  standalone: true
})
export class WcFohSandboxDebuggerComponent implements AfterViewInit {

  original_widget_content = '';
  widget_content = '';

  codeMirror: any;

  iframeLoaded = false;
  previewLoaded = false;

  @ViewChild('editor') editorRef: ElementRef;
  @ViewChild('iframe') iframeRef: ElementRef;

  constructor(
    private state: StateService
  ) {
    const cdnjs = 'https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7';
    injectResources(
      [
        {id: 'code-mirror-style-loader', url: cdnjs + '/codemirror.min.css'},
        {id: 'code-mirror-style-foldgutter', url: cdnjs + '/addon/fold/foldgutter.min.css'}
      ],
      [{id: 'code-mirror-script-loader-main', url: cdnjs + '/codemirror.min.js', module: true}]
    )
      .then(() => {
        injectResources([],
          [

            {id: 'code-mirror-script-loader-foldcode', url: cdnjs + '/addon/fold/foldcode.min.js', module: true},
            {id: 'code-mirror-script-loader-foldgutter', url: cdnjs + '/addon/fold/foldgutter.min.js', module: true},
            {
              id: 'code-mirror-script-loader-xml-fold',
              url: 'https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/addon/fold/xml-fold.min.js', module: true
            },

            {
              id: 'code-mirror-script-loader-xml',
              url: 'https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/mode/xml/xml.min.js',
              module: true
            },
            {
              id: 'code-mirror-script-loader-js',
              url: 'https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/mode/javascript/javascript.min.js', module: true
            },
            {
              id: 'code-mirror-script-loader-css',
              url: 'https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/mode/css/css.min.js',
              module: true
            },
            {
              id: 'code-mirror-script-loader-html',
              url: 'https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/mode/htmlmixed/htmlmixed.min.js', module: true
            },
            {
              id: 'code-mirror-script-loader-closetags',
              url: 'https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/addon/edit/closetag.min.js', module: true
            },
            {
              id: 'code-mirror-script-loader-closebrackets',
              url: 'https://cdnjs.cloudflare.com/ajax/libs/codemirror/6.65.7/addon/edit/closebrackets.min.js', module: true
            },
          ]
        ).then(() => this.init());
      });

    window.addEventListener(
      'message',
      (event) => {
        if (window.origin === event.origin) {
          if (event && event.data && event.data.type && event.data.type == 'wc-foh-sandbox-debugger-preview-loaded') {
            this.previewLoaded = true;
            this.reloadWidget();
          }
        }
      },
      false,
    );

  }

  init() {
    const chain = this.state.getChain();
    this.original_widget_content = chain.widget_content;
    const lskey = 'wc-foh-sandbox-widget-data-chain-' + chain.chain_id;
    const _wc = localStorage.getItem(lskey);
    if (_wc == null) {
      localStorage.setItem(lskey, chain.widget_content);
    } else {
      this.widget_content = _wc;
    }
    this.codeMirror = CodeMirror(
      this.editorRef.nativeElement,
      {
        tabSize: 2,
        value: this.widget_content,
        mode: 'text/html',
        autoCloseTags: true,
        autoCloseBrackets: true,
        lineNumbers: true,
        lineWrapping: true,
        // fixedGutter: false,
        foldGutter: true,
        gutters: [
          'CodeMirror-linenumbers',
          'CodeMirror-foldgutter'
        ]
      }
    );
    this.codeMirror.setSize('100%', '100%');
    this.codeMirror.on('change', (e) => {
      localStorage.setItem(lskey, this.codeMirror.getValue());
      this.reloadWidget();
    });
  }

  reloadWidget() {
    if (!this.iframeLoaded) {
      return false;
    }
    if (!this.previewLoaded) {
      return false;
    }
    const m = {
      type: 'wc-foh-sandbox-debugger-update-widget',
      widget_content: this.codeMirror.getValue()
    };
    const i = this.iframeRef.nativeElement as HTMLIFrameElement;
    if (i && i.contentWindow) {
      i.contentWindow.postMessage(m, {targetOrigin: i.src});
    }
    return false;
  }

  ngAfterViewInit(): void {
    this.iframeRef.nativeElement.onload = () => {
      this.iframeLoaded = true;
    };
  }

  resetToChainDefault() {
    if (confirm('Sure you want to reset?')) {
      this.original_widget_content = '';
      this.widget_content = '';
      const chain = this.state.getChain();
      const lskey = 'wc-foh-sandbox-widget-data-chain-' + chain.chain_id;
      this.codeMirror.setValue(chain.widget_content);
      localStorage.removeItem(lskey);
      this.init();
    }
  }
}
