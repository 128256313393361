import {Component, OnDestroy} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {UserLoginService} from '../user-login.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Capacitor} from '@capacitor/core';
import {environment} from '../../../../../environments/environment';
import {BcGqlService} from '@betterchains/bc-gql';

@Component({
  selector: 'foh-user-login-code-verify',
  templateUrl: 'login-code-verify.component.html',
  styleUrls: ['login-code-verify.component.scss']
})
export class LoginCodeVerifyComponent implements OnDestroy {

  subs: Subscription[] = [];
  hasError = false;
  errorMessage = '';
  code = '';
  emailHint = '';
  phoneHint = '';
  isIos = environment.isApp && Capacitor.getPlatform() === 'ios' ? true : false;

  constructor(
    private api: ApiService,
    public userLoginService: UserLoginService,
    private gql: BcGqlService,
    private router: Router
  ) {
    if (!this.userLoginService.user) {
      this.router.navigateByUrl('/user/login', {replaceUrl: true}).then();
    }
    this.emailHint = this.userLoginService.getEmailHint();
    this.phoneHint = this.userLoginService.getPhoneHint();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  verifyCode() {
    this.subs.push(
      this.api.verifyLoginCode(this.code).subscribe({
        next: (r: { status: boolean, errors: string[] }) => {
          if (r.status == true) {
            this.gql.trackUserAction('2FA code verified successfully')
              .then();
            this.hasError = false;
            this.errorMessage = '';
            if (this.userLoginService.branches.length > 1) {
              this.router.navigateByUrl('/user/login/select-branch').then();
            } else {
              this.userLoginService.setChain(this.userLoginService.chainId, true);
            }
          } else {
            this.gql.trackUserAction('2FA code verification failed', false, 0, r.errors[0])
              .then();
            this.hasError = true;
            this.errorMessage = r.errors[0];
          }
        },
        error: (e: HttpErrorResponse) => {
          this.hasError = true;
          this.errorMessage = e.message;
          this.gql.trackUserAction('2FA code verification failed', false, 0, e.message)
            .then();
        }
      })
    );
  }

  formKeyUp($event) {
    if ($event.keyCode === 13 && this.code.trim() != '') {
      this.verifyCode();
    }
  }

}
