import {EventEmitter, Injectable} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {IApiChain} from '../models/IApiChain';
import {IApiUser, IBranchShort} from '../models/IApiUser';
import * as dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/he';
import 'dayjs/locale/ru';
import 'dayjs/locale/ro';

import * as dayjslocale from 'dayjs/plugin/localizedFormat';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(dayjslocale);
dayjs.extend(customParseFormat);

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private map = {
    en: 'en_us',
    he: 'he_il',
    ru: 'ru_ru',
    ro: 'ro_ro',
    fr: 'fr_fr',
    es: 'es_es',
  };

  languageChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private t: TranslocoService
  ) {
  }

  setupLanguageFromSettings(chain: IApiChain, branch: IBranchShort, user: IApiUser) {
    let lslng = localStorage.getItem('user-selected-language');
    if (lslng === null || lslng === undefined || lslng === '') {
      lslng = localStorage.getItem('selected-language');
    }
    if (!lslng) {
      if (!user || !user.default_locale) {
        if (branch && branch.default_locale && branch.default_locale.length > 0) {
          const lng = branch.default_locale.substr(0, 2);
          this.setLanguage(lng);
        } else if (chain && chain.default_locale && chain.default_locale.length > 0) {
          const lng = chain.default_locale.substr(0, 2);
          this.setLanguage(lng);
        } else {
          this.setLanguage('en');
        }
      } else if (user.default_locale && user.default_locale.length >= 2) {
        const lng = user.default_locale.substr(0, 2);
        this.setLanguage(lng);
      }
    }
  }

  setLanguage(lang: string) {
    window.localStorage.setItem('selected-language', lang);
    this.t.setActiveLang(lang);
    document.body.parentElement.setAttribute('lang', lang);
    document.body.parentElement.setAttribute('dir', (lang.toLowerCase() === 'he') ? 'rtl' : 'ltr');
    dayjs.locale(lang);
    this.languageChanged.emit(lang);
  }

  getLanguage() {
    return this.t.getActiveLang();
  }

  getLocaleFromLanguage(lng: string) {
    return this.map[lng];
  }
}
