import {Component, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {ComponentsModule} from '../../components/components.module';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {environment} from '../../../environments/environment';
import {WcFohSandboxVariablesComponent} from './wc-foh-sandbox-variables.component';
import {WcFohSandboxComponentsComponent} from './wc-foh-sandbox-components.component';
import {RouterLinkWithHref} from '@angular/router';
import {injectResources} from '../../util/script-loader';
import {WcFohSandboxExamplesComponent} from './wc-foh-sandbox-examples.component';
import {NgIf} from '@angular/common';

@Component({
  selector: 'wc-foh-sandbox',
  templateUrl: './wc-foh-sandbox.component.html',
  styleUrls: ['./wc-foh-sandbox.component.scss'],
  imports: [
    ComponentsModule,
    TabsModule,
    WcFohSandboxVariablesComponent,
    WcFohSandboxComponentsComponent,
    RouterLinkWithHref,
    WcFohSandboxExamplesComponent,
    NgIf
  ],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WcFohSandboxComponent {

  public hljsReady = false;

  constructor() {
    injectResources(
      [
        // {id: 'highlight-js-main-css', url: 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/styles/default.min.css'},
      ],
      [
        {id: 'wc-foh-library-loader', url: environment.staticUrl + '/js/wc-foh/dist/wc-foh/wc-foh.esm.js', module: true},
        {id: 'highlight-js-main-js', url: 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/highlight.min.js', module: false},
      ]
    ).then(() => {
      injectResources([
        {id: 'highlight-js-style-github-css', url: 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/styles/github.min.css'}
      ], [{
        id: 'highlight-js-xml',
        url: 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/languages/xml.min.js',
        module: false
      },
      ]).then(() => this.hljsReady = true);
    });
  }

}
