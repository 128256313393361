import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {TranslocoService} from '@ngneat/transloco';
import {StateService} from "./state.service";
import {PushNotifications, Token} from '@capacitor/push-notifications';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(
    private api: ApiService,
    private state: StateService,
    private t: TranslocoService,
    private router: Router
  ) {
  }

  public init() {
    if (environment.isApp) {
      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
          (token: Token) => {
          localStorage.setItem('ng-foh-app-firebase-token', token.value);
          this.api.setPushId(token.value, 'Fbase/MNG')
            .subscribe(() => {
            });
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (/*notification: PushNotification*/) => {
          if (confirm(this.t.translate('You have a new message. Click OK to open'))) {
            this.state.updateNotificationsCounter.emit();
            this.state.updateNotificationsList.emit();
            this.router.navigateByUrl('/notification').then(() => {
              (document.getElementsByTagName('body')[0] as HTMLBodyElement).className = '';
            });
          }
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (/*notification: PushNotificationActionPerformed*/) => {
          if (confirm(this.t.translate('You have a new message. Click OK to open'))) {
            this.state.updateNotificationsCounter.emit();
            this.state.updateNotificationsList.emit();
            this.router.navigateByUrl('/notification').then(() => {
              (document.getElementsByTagName('body')[0] as HTMLBodyElement).className = '';
            });
          }
        }
      );
    }
  }

  public async register() {
    if (environment.isApp) {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await PushNotifications.register();
      // PushNotifications.requestPermission()
      //   .then(result => {
      //     if (result.granted) {
      //       // Register with Apple / Google to receive push via APNS/FCM
      //       PushNotifications.register()
      //         .then(() => {
      //         })
      //         .catch(() => {
      //         });
      //     } else {
      //     }
      //   });
    }
  }
}
