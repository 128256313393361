import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, NgZone, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {environment} from '../../../environments/environment';
import {LayoutService} from '../../layout/main/layout.service';

const loaderId = 'wc-script-loader';
const renderId = 'bc-wc-layout-render';

@Component({
  selector: 'app-layout-preview',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements OnInit {

  public layoutName = '';

  @ViewChild('container', {static: true}) container: ElementRef;

  constructor(
    private cd: ChangeDetectorRef,
    private lsvc: LayoutService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
  ) {
    // const s = document.createElement('script');
    // s.src = environment.staticUrl + '/js/wc/layout-render/render-data-service.js';
    // document.head.append(s);
  }

  inject() {
    if (this.container && this.container.nativeElement) {
      let hasloader = false;
      for (let i = 0; i < document.head.children.length; i++) {
        const c = document.head.children[i];
        if (c.tagName.toLowerCase() === 'script') {
          if (c.id === loaderId) {
            hasloader = true;
          }
        }
      }
      if (!hasloader) {
        const s = document.createElement('script');
        s.id = loaderId;
        s.src = environment.staticUrl + '/js/wc/layout-render/render.js?' + Date.now();
        s.type = 'module';
        s.onload = () => {
          const lr = document.createElement('wc-layout-render');
          lr.id = renderId;
          if (this.layoutName) {
            lr.setAttribute('layout-name', this.layoutName);
          }
          lr.setAttribute('api-url', environment.apiUrl);
          lr.setAttribute('cdn-url', environment.staticUrl);
          lr.addEventListener('pageconfig', (e: any) => {
            if ('data' in e) {
              this.applyPageSettings(e.data);
            }
          });
          this.container.nativeElement.appendChild(lr);
        };
        document.head.appendChild(s);
      } else {
        const lr = document.getElementById(renderId);
        if (this.layoutName) {
          lr.setAttribute('layout-name', this.layoutName);
        }
      }
    }
  }

  applyPageSettings(pageConfig: ILayoutRenderPageConfig) {
    if ('title' in pageConfig) {
      this.lsvc.pagetitle.emit(pageConfig.title);
    }
    if ('showBack' in pageConfig && pageConfig.showBack) {
      this.lsvc.showBack.emit(true);
      if ('backUrl' in pageConfig && pageConfig.backUrl.trim() !== '') {
        this.lsvc.backLink.emit(pageConfig.backUrl);
      }
    }
    if ('showClose' in pageConfig && pageConfig.showClose) {
      this.lsvc.showClose.emit(true);
      if ('closeUrl' in pageConfig && pageConfig.closeUrl.trim() !== '') {
        this.lsvc.closeLink.emit(pageConfig.closeUrl);
      }
    }
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((x: ParamMap) => {
      this.layoutName = x.get('name');
      this.inject();
    });
  }

  @HostListener('window:message', ['$event'])
  onmessage($event: ILayoutRenderMessageEvent) {
    setTimeout(() => {
      this.ngZone.run(() => {
        if ('sentFromLayoutBuilder' in $event.data) {
          const e: ILayoutRenderWebComponent = document.getElementById(renderId) as ILayoutRenderWebComponent;
          if (e && 'applyData' in e && $event && $event.data && $event.data.data) {
            e.setData($event.data.data);
            e.loadLayoutSpec();
            if ('pageConfig' in $event.data.data) {
              this.applyPageSettings($event.data.data.pageConfig);
            }
            this.cd.detectChanges();
          } else {
            console.log('layout render element not found');
          }
        }
      });
    }, 100);
  }

}

interface ILayoutRenderPageConfig {
  title: string;
  showBack: boolean;
  backUrl: string;
  showClose: boolean;
  closeUrl: string;
}

interface ILayoutRenderMessageEvent extends MessageEvent {
  data: {
    data: {
      pageConfig: ILayoutRenderPageConfig
    }
  };
}

interface ILayoutRenderWebComponent extends HTMLElement {
  setData: (data: any) => void;
  applyData: () => void;
  loadLayoutSpec: () => void;
}
