<div class="slim-loading-bar">
  <div class="slim-loading-bar-progress" [style.width]="progress + '%'" [style.backgroundColor]="color" [style.color]="color"
       [style.height]="height" [style.opacity]="show ? '1' : '0'" [style.transition]="isTransition"></div>
</div>
<div *ngIf="false" style="position: fixed;bottom: 24px;left:0;padding: 8px; background-color: black; color: white">
  [
  {{progress}},
  {{show | json}}
  ]
</div>
