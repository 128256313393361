import {Component} from '@angular/core';
import {NgForOf} from '@angular/common';

@Component({
  selector: 'wc-foh-sandbox-variables',
  templateUrl: 'wc-foh-sandbox-variables.component.html',
  styleUrls: ['wc-foh-sandbox-variables.component.scss'],
  imports: [
    NgForOf
  ],
  standalone: true
})
export class WcFohSandboxVariablesComponent {

  data = [
    {
      title: 'MAIN COLORS',
      colors: [
        {n: '--foh-color-main', v: '#ff5132'},
        {n: '--foh-color-additional', v: '#808ba3'}
      ]
    },
    {
      title: 'MAIN COLORS',
      colors: [
        {n: '--foh-color-primary', v: '#4a90e2'},
        {n: '--foh-color-secondary', v: '#808ba3'},
        {n: '--foh-color-success', v: '#19b971'},
        {n: '--foh-color-danger', v: '#ff5132'},
        {n: '--foh-color-warning', v: '#faad13'},
        {n: '--foh-color-info', v: '#17a2b8'},
      ]
    },
    {
      title: 'BODY COLORS',
      colors: [
        {n: '--foh-body-bg-color', v: '#F9F9F9'},
        {n: '--foh-body-color', v: '#333333'},
      ]
    },
    {
      title: 'CARD COLORS',
      colors: [
        {n: '--foh-card-bg-color', v: 'white'},
      ]
    },
    {
      title: 'HEADER COLORS',
      colors: [
        {n: '--foh-header-bg-color', v: 'white'},
        {n: '--foh-header-color', v: '#333'},
      ]
    },
  ];

}
