<header>
  <h1>WC-FOH sandbox</h1>
  <aside>
    <a [routerLink]="'/wc-foh-sandbox-debugger'" class="btn btn-primary">LAUNCH WIDGET DEBUGGER</a>
  </aside>
</header>

<tabset *ngIf="hljsReady">
  <tab heading="Components">
    <wc-foh-sandbox-components></wc-foh-sandbox-components>
  </tab>
  <tab heading="Variables">
    <wc-foh-sandbox-variables></wc-foh-sandbox-variables>
  </tab>
  <tab heading="Examples">
    <wc-foh-sandbox-examples-icon-button></wc-foh-sandbox-examples-icon-button>
  </tab>
</tabset>
