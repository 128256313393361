import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {DirectivesModule} from '../../directives/directives.module';

declare var hljs: any;

@Component({
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'wc-foh-sandbox-examples-icon-button',
  templateUrl: './wc-foh-sandbox-examples.component.html',
  styleUrls: ['./wc-foh-sandbox-examples.component.scss'],
  imports: [DirectivesModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WcFohSandboxExamplesComponent implements OnInit, AfterViewInit {

  highlight1: string = null;
  code1 = `<wc-foh-card>
  <h3 style="margin:16px 0">Differen styles</h3>
  <div style="display:flex;gap:16px">
    <wc-foh-icon-button title="Default"></wc-foh-icon-button>
    <wc-foh-icon-button round="true" title="Round"></wc-foh-icon-button>
    <wc-foh-icon-button border-color="none" title="No border"></wc-foh-icon-button>
    <wc-foh-icon-button border-color="none" round="true" title="No border"></wc-foh-icon-button>
    <wc-foh-icon-button shadow="none" title="No shadow"></wc-foh-icon-button>
    <wc-foh-icon-button shadow="none" round="true" title="No shadow"></wc-foh-icon-button>
  </div>
</wc-foh-card>
<h3>Flex fit width <small>Use flex-grow:1 so items take space evenly</small></h3>
<div style="display: flex;gap:16px">
  <wc-foh-icon-button style="flex-grow: 1"
      title-background="var(--foh-color-main)" title-color="white" title="foh-color-main"></wc-foh-icon-button>
  <wc-foh-icon-button style="flex-grow: 1" icon="fa-light fa-user" title="User icon"></wc-foh-icon-button>
  <wc-foh-icon-button style="flex-grow: 1"
      icon-background="var(--foh-color-main)" icon-color="white" title="Reversed"></wc-foh-icon-button>
  <wc-foh-icon-button style="flex-grow: 1" title="Transparent back" icon="fa-light fa-sun"
      icon-background="transparent" title-background="transparent"
     ></wc-foh-icon-button>
</div>
<h3>
Custom size
<small>Use style's width and height to control widget size</small>
</h3>
<wc-foh-icon-button style="width: 100%; height: 200px" title="Full width"></wc-foh-icon-button>
`;

  highlight3: string = null;
  code3 = `
<div style="display:flex;gap:16px;flex-wrap:wrap;margin-bottom:16px">
    <wc-foh-icon-button show-for-positions="136" title="Server"></wc-foh-icon-button>
    <wc-foh-icon-button hide-for-positions="136" title="Not Server"></wc-foh-icon-button>
    <wc-foh-icon-button show-for-positions="197" title="Chef"></wc-foh-icon-button>
    <wc-foh-icon-button hide-for-positions="197" title="Not Chef"></wc-foh-icon-button>
    <wc-foh-icon-button show-for-positions="136,197" title="Chef & Server"></wc-foh-icon-button>
</div>
  `;

  highlight4: string = null;
  code4 = `
<div style="display:flex;gap:16px;flex-wrap:wrap">
    <wc-foh-icon-button show-for-branches="41" title="Branch 41"></wc-foh-icon-button>
    <wc-foh-icon-button hide-for-branches="41" title="Not Branch 41"></wc-foh-icon-button>
    <wc-foh-icon-button show-for-branches="94" title="Branch 94"></wc-foh-icon-button>
    <wc-foh-icon-button hide-for-branches="94" title="Not Branch 94"></wc-foh-icon-button>
    <wc-foh-icon-button show-for-branches="41,42" title="B 41 & 94"></wc-foh-icon-button>
</div>
  `;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.highlight1 = hljs.highlight(this.code1, {language: 'xml'}).value;
    this.highlight3 = hljs.highlight(this.code3, {language: 'xml'}).value;
    this.highlight4 = hljs.highlight(this.code4, {language: 'xml'}).value;
    this.cd.detectChanges();
  }
}
