import {Component, OnDestroy} from '@angular/core';
import {UserLoginService} from '../user-login.service';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {ApiService} from '../../../../services/api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'foh-user-login-restore-password',
  styleUrls: ['restore-password.component.scss'],
  templateUrl: 'restore-password.component.html'
})
export class RestorePasswordComponent implements OnDestroy {

  subs: Subscription[] = [];

  forgotPasswordSubmitPressed = false;
  forgotPasswordEmail = '';
  forgotPasswordEmailError = '';
  forgotEmailErrors = [];
  informationMessage = null;
  messageValidateChain = '';
  showChainSelector = false;
  showFinalMessageOnly = false;
  chainSet = [];
  chainsAvailable: any[] = [];

  constructor(
    private api: ApiService,
    private router: Router,
    public userLoginService: UserLoginService
  ) {
  }

  sendForgotPassword() {
    this.messageValidateChain = '';
    this.informationMessage = 'Information has been sent if the account exists';
    this.forgotEmailErrors = [];

    if (window.hasOwnProperty('BOHChainId') && Number(window['BOHChainId']) > 0) {
      this.userLoginService.currentChainId = Number(window['BOHChainId']);
    }
    if (this.forgotPasswordEmail.trim() === '') {
      this.forgotEmailErrors.push(this.informationMessage);
      this.forgotEmailErrors.push('Email field cannot be empty.');
      return;
    }

    if (this.showChainSelector && Number(this.userLoginService.currentChainId) === 0) {
      this.messageValidateChain = 'Chain should be set';
      return;
    }

    this.subs.push(
      this.api.forgotPassword(this.forgotPasswordEmail, this.userLoginService.currentChainId)
        .subscribe(
          (res: any) => {
            this.forgotPasswordSubmitPressed = true;
            if (typeof res == 'object' && 'data' in res && 'chains' in res.data) {
              this.chainsAvailable = res.data.chains;
            }
            if (res.status === false) {
              this.forgotEmailErrors = (typeof res.errors.email !== 'undefined'
                && res.errors.email.length > 0
                && Array.isArray(res.errors.email)) ? res.errors.email : [];

              if (Number(this.userLoginService.currentChainId) === 0 && this.forgotEmailErrors.length > 0) {
                this.forgotEmailErrors.forEach(e => {
                  if (Array.isArray(e)) {
                    this.showChainSelector = true;
                    this.chainSet = e;
                  }
                });

                if (this.showChainSelector === true) {
                  this.userLoginService.currentChainId = null;
                  this.userLoginService.chainId = 0;
                  return;
                }
              }

              this.showFinalMessageOnly = this.forgotEmailErrors?.length <= 1;
            } else {
              this.showFinalMessageOnly = true;
            }
          },
          (err: HttpErrorResponse) => {
            this.forgotPasswordEmailError = err.statusText;
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(subscrption => subscrption.unsubscribe());
  }

  goBack() {
    this.router.navigateByUrl('/user/login').then();
  }

  formKeyUp($event) {
    if ($event.keyCode === 13) {
      this.sendForgotPassword();
    }
  }

}


//BM_ELLaBM_ELLa63443@mailinator.com
