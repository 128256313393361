import {Component, ElementRef, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'foh-card',
  template: '<ng-content></ng-content>',
  styles: [`
    :host {
      display: flex;
      padding: 15px;
      width: 100%;
      border-radius: 10px;
      box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.08);
      background-color: var(--foh-card-bg-color);
      margin-bottom: 15px;
      align-content: stretch;
    }
  `]
})
export class FohCardComponent implements OnInit {

  @HostBinding('style.flex-direction') flexDirection = 'column';
  @HostBinding('style.color') foregroundColor = null;
  @HostBinding('style.background-color') backgroundColor = null;
  @HostBinding('style.box-shadow') shadow = '0 7px 12px 0 rgba(0, 0, 0, 0.08)';

  @Input()
  set direction(direction: string) {
    this.flexDirection = direction;
  }

  @Input()
  set setShadow(shadow: string) {
    this.shadow = shadow;
  }


  @Input()
  set background(color: string) {
    this.backgroundColor = color;
  }

  @Input()
  set color(color: string) {
    this.foregroundColor = color;
  }

  constructor(public hostElement: ElementRef) { }

  ngOnInit() {
  }

  public isInViewport() {
    const rect = this.hostElement.nativeElement.getBoundingClientRect();
    const html = document.documentElement;
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || html.clientHeight) &&
      rect.right <= (window.innerWidth || html.clientWidth)
    );
  }

}
