import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {IApiUser} from '../../../models/IApiUser';
import {environment} from '../../../../environments/environment';
import {ApiService} from '../../../services/api.service';
import {LoaderService} from '../../../services/loader.service';
import {IDataResponse} from '../../../services/interfaces';
import {StateService} from '../../../services/state.service';
import {TranslocoService} from '@ngneat/transloco';
import {LanguageService} from '../../../services/language.service';
import {LayoutService} from '../../../layout/main/layout.service';
import {Subscription} from 'rxjs';
import {ComponentService} from '../../../components/component.service';
import {SnackbarService} from '../../../components/snackbar/snackbar.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  public isApp = environment.isApp;
  public signPicture = '';
  public timestamp = (new Date()).getUTCMilliseconds();
  public allowChangeSignature = false;
  public user: IApiUser;

  avatarUrl = '';
  oldPassword = '';
  newPassword = '';
  confirmPassword = '';
  pwdErrors = {
    old_password: [],
    new_password: [],
    confirm_password: []
  };

  userUpdEmlPhone = {
    email: [],
    phone: []
  };

  showSignatureDialog = false;
  currentLanguage = '';
  isRtl = false;

  subs: Subscription[] = [];

  constructor(
    private apiService: ApiService,
    private state: StateService,
    private loader: LoaderService,
    private ls: LanguageService,
    private t: TranslocoService,
    private language: LanguageService,
    private layout: LayoutService,
    private cd: ChangeDetectorRef,
    private snackbar: SnackbarService,
    private componentService: ComponentService
  ) {
    this.currentLanguage = this.ls.getLanguage();
    this.isRtl = document.body.parentElement.getAttribute('dir') === 'rtl';
  }

  ngOnInit() {
    this.reloadUser();
  }

  clickUpdateEmailAndPhone() {
    this.loader.showLoader();
    this.subs.push(
      this.apiService.userUpdateEmailAndPhone(this.user.email, this.user.phone)
        .subscribe((r: IDataResponse) => {
          this.loader.hideLoader();
          if (r.status === false) {
            const e = (r.errors as { email: string[], phone: string[] });
            this.userUpdEmlPhone.email = (e.email) ? e.email.slice(0, 1) : [];
            this.userUpdEmlPhone.phone = e.phone ? e.phone.slice(0, 1) : [];
          } else {
            this.snackbar.clear();
            this.snackbar.add({
              msg: this.t.translate('Your profile was updated!'),
              action: {text: this.t.translate('Ok')},
            });
          }
          this.cd.detectChanges();
        })
    );
    return false;
  }

  chlang($event: Event) {
    this.currentLanguage = ($event.target as HTMLSelectElement).value;
  }

  clickChangeSignature() {
    this.showSignatureDialog = true;
    this.componentService.showSignDialogPopup();
  }

  closeSignatureDialog() {
    this.showSignatureDialog = false;
    this.componentService.hideSignDialogPopup();
    this.timestamp = (new Date()).getUTCMilliseconds();
  }

  signatureSubmitted() {
    this.allowChangeSignature = false;
  }

  reloadUser() {
    this.loader.showLoader();
    this.subs.push(
      this.apiService.getUser()
        .subscribe((res: IApiUser) => {
          this.loader.hideLoader();
          if (res.avatar_url) {
            if (res.avatar_url.startsWith('http')) {
              this.avatarUrl = res.avatar_url;
            } else {
              this.avatarUrl = environment.apiUrl + res.avatar_url;
            }
          } else {
            this.avatarUrl = '';
          }
          this.signPicture = (res.sign_picture) ? environment.apiUrl + res.sign_picture : '';
          this.allowChangeSignature = parseInt(res.allow_change_signature, 10) === 1;
          this.user = res;
          this.cd.detectChanges();
        })
    );
  }


  sendChangePassword() {
    this.loader.showLoader();
    this.subs.push(
      this.apiService.updatePassword(this.oldPassword, this.newPassword.trim(), this.confirmPassword.trim())
        .subscribe((res: IDataResponse) => {
          this.loader.hideLoader();
          if (res.status !== true) {
            this.pwdErrors = res.errors;
          } else {
            this.pwdErrors = {
              old_password: [],
              new_password: [],
              confirm_password: []
            };
            this.snackbar.clear();
            this.snackbar.add({
              msg: this.t.translate('Your password was successfully changed!'),
              action: {text: this.t.translate('Ok')},
            });
            this.oldPassword = '';
            this.newPassword = '';
            this.confirmPassword = '';
          }
        })
    );
    return false;
  }

  setlang() {
    this.ls.setLanguage(this.currentLanguage);
    const locale = this.language.getLocaleFromLanguage(this.currentLanguage);
    this.subs.push(
      this.apiService.userUpdateLocale(locale)
        .subscribe(() => {
          this.snackbar.clear();
          this.snackbar.add({
            msg: this.t.translate('Language has been changed'),
            action: {text: this.t.translate('Ok')}
          });
        })
    );
    localStorage.setItem('user-selected-language', this.currentLanguage);
  }


  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
