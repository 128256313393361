import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

try {
  if(navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for(let registration of registrations) {
        registration.unregister()
          .then(()=>{
            console.log('Worker unregistered');
          });
      } })
  }
} catch (e) {
  console.log("Exception in unregistering service workers:");
  console.log(e);
}
