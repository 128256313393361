import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserRoutingModule} from './user-routing.module';
import {ProfileComponent} from './profile/profile.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ComponentsModule} from '../../components/components.module';
import {FormsModule} from '@angular/forms';
import {IntroductionComponent} from './introduction/introduction.component';
import {TranslocoModule} from '@ngneat/transloco';
import {PipesModule} from '../../pipes/pipes.module';
import {AvatarComponent} from './profile/avatar/avatar.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';

@NgModule({
  declarations: [ProfileComponent, IntroductionComponent, AvatarComponent, ResetPasswordComponent],
  imports: [
    CommonModule,
    UserRoutingModule,
    ComponentsModule,
    ImageCropperModule,
    FormsModule,
    TranslocoModule,
    PipesModule
  ]
})
export class UserModule {
}
