export const environment = {
  isApp: false,
  deployUrl: 'https://d1mcyggarp14he.cloudfront.net/ng-foh-assets-v3.2',
  isLocalhost: false,
  production: true,
  domainMode: true,
  apiUrl: 'https://api.betterchains.com',
  staticUrl: 'https://d1mcyggarp14he.cloudfront.net',
  websocket: 'ws://ws.betterchains.com/ws',
  PDFjsExpressUrl: '/ng-foh-assets-v3.2/assets/pdfjs-express',
  PDFjsExpressKey: 'PUg7I8mmtXZonIvSURWL'
};
