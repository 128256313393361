import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {UserLoginComponent} from './user-login.component';
import {UserLoginEmailPasswordComponent} from './email-password/email-password.component';
import {RestorePasswordComponent} from './restore-password/restore-password.component';
import {SelectBranchComponent} from './select-branch/select-branch.component';
import {SelectChainComponent} from './select-chain/select-chain.component';
import {SelectDomainComponent} from './select-domain/select-domain.component';
import {LoginCodePromptComponent} from './login-code-prompt/login-code-prompt.component';
import {LoginCodeVerifyComponent} from './login-code-verify/login-code-verify.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';


const routes: Routes = [
  {
    path: '',
    component: UserLoginComponent,
    children: [
      {
        path: '',
        component: UserLoginEmailPasswordComponent
      },
      {
        path: 'select-domain',
        component: SelectDomainComponent
      },
      {
        path: 'select-chain',
        component: SelectChainComponent
      },
      {
        path: 'select-branch',
        component: SelectBranchComponent
      },
      {
        path: 'restore-password',
        component: RestorePasswordComponent
      },
      {
        path: 'code-prompt',
        component: LoginCodePromptComponent
      },
      {
        path: 'verify-code',
        component: LoginCodeVerifyComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserLoginRoutingModule {
}
