import { Component, OnInit, EventEmitter } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'foh-plain-dialog',
  templateUrl: './plain-dialog.component.html',
  styleUrls: ['./plain-dialog.component.scss', './../dialog/dialog.component.scss']
})
export class PlainDialogComponent implements OnInit {

  public confirmEvent: EventEmitter<void> = new EventEmitter<void>();
  public confirmCloseEvent: EventEmitter<void> = new EventEmitter<void>();
  public additionalEventRise: EventEmitter<void> = new EventEmitter<void>();

  public title = '';
  public message = '';
  public closeText = '';
  public okText = '';
  public additionalText = '';
  public showClose = true;
  public okBeforeClose = true;
  public additionalButton = false;
  public additionalButtonAddClass: '';
  public additionalStyle = '';
  public showSuccessIcon = false;

  constructor(
    public ref: BsModalRef
  ) {
  }


  ngOnInit(): void {
  }

  closeDialogTop(){
    if ( this.showClose) {
      this.closeDialog();
    } else {
      this.ref.hide();
    }
  }

  closeDialog(){
    this.confirmCloseEvent.emit();
    this.ref.hide();
  }

  okEvent(){
    this.confirmEvent.emit();
  }

  additionalEvent(){
    this.additionalEventRise.emit();
  }

}
