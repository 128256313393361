import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {IApiUser, IBranchShort} from '../../models/IApiUser';
import {IApiUserMenuItem} from '../../models/IApiUserMenu';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {ILayoutFlashMessage} from '../../services/interfaces';
import {StateService} from '../../services/state.service';
import {IApiChain} from '../../models/IApiChain';
import {SharedService} from '../../services/shared.service';
import {LanguageService} from '../../services/language.service';
import {LayoutService} from './layout.service';
import {HttpClient} from '@angular/common/http';
import {TranslocoService} from '@ngneat/transloco';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-main',
  template: `
    <foh-menu [avatarUrl]="avatarUrl" [chain]="chain" [branch]="branch" [menu]="menu" [user]="user"></foh-menu>
    <ng-container *ngIf="!hiddenMainMenuByParam">
      <foh-header></foh-header>
    </ng-container>
    <router-outlet></router-outlet>
  `
})
export class MainComponent implements OnInit, OnDestroy {
  mobileMenuOpened = false;
  public avatarUrl = '';
  public menu: IApiUserMenuItem[] = [];
  public user: IApiUser;
  public flashes: ILayoutFlashMessage[] = [];
  chain: IApiChain;
  branch: IBranchShort;
  isRtl = false;
  containerClass = 'container';
  containerBGColor = 'white-bg-color';
  showBackendMenu = false;
  hiddenMainMenuByParam = false;
  subs: Subscription[] = [];

  menuHidden = false;

  constructor(
    private apiService: ApiService,
    private state: StateService,
    private router: Router,
    private route: ActivatedRoute,
    private language: LanguageService,
    private shared: SharedService,
    private layout: LayoutService,
    private http: HttpClient,
    private ngZone: NgZone,
    private t: TranslocoService
  ) {
    this.showBackendMenu = environment.domainMode;
    this.subs.push(this.state.updateAvatar.subscribe(
      (newAvatarUrl: string) => {
        this.avatarUrl = newAvatarUrl + '?' + (new Date()).getUTCSeconds();
      }
    ));

    this.subs.push(this.state.branchChanged.subscribe(() => {
      // noinspection JSIgnoredPromiseFromCall
      this.load(true, true);
    }));

    this.subs.push(this.state.countersChanged.subscribe(() => {
      // noinspection JSIgnoredPromiseFromCall
      this.load(false, true);
    }));

    this.subs.push(this.state.updateUser.subscribe(() => {
      // noinspection JSIgnoredPromiseFromCall
      this.load(true, false);
    }));

    this.subscription();
    this.subscription2();

    // noinspection JSIgnoredPromiseFromCall
    this.load(true, true);

    this.changeMenuDisplayingByParam();

    setTimeout(() => this.ngZone.run(() => this.state.updateNotificationsCounter.emit()), 10);

    this.subs.push(this.state.updateNotificationsCounter.subscribe(() => {
      const token = this.state.getApiToken();
      if (this.state.connected && token !== null) {
        this.http.get(environment.apiUrl + '/api/communication/notViewedCount?token=' + token)
          .subscribe(
            (res: any) => {
              this.layout.notificationsCount.emit(Number(res.data));
              this.state.notificationsCount = Number(res.data);
            }
          );
      }
    }));
  }

  changeMenuDisplayingByParam() {
    const hideMainMenu = this.route.snapshot.queryParamMap.get('hideMainMenu') === 'true';
    if (hideMainMenu) {
      this.hiddenMainMenuByParam = true;
      this.layout.hideMainMenu = true;
      setTimeout(() => this.ngZone.run(() => this.layout.headerstate.emit(false)), 0);
    }
  }

  subscription() {
    this.subs.push(this.shared.onChangeStatus.subscribe((status) => {
      this.changeClass(status);
    }));
  }

  subscription2() {
    this.subs.push(this.shared.onChangeHeaderVisibility.subscribe((status) => {
      this.hiddenMainMenuByParam = status;
    }));
  }

  changeClass(status) {
    this.containerClass = status ? 'container' : 'container-old';
    this.containerBGColor = status ? 'auto' : 'rgb(255,255,255)';
  }

  async ngOnInit() {
  }

  async load(reloadUser = false, reloadMenu = false) {
    const menuRes = await this.apiService.getUserMenuCached(reloadMenu,
      (items: IApiUserMenuItem[]) => {
        if (!items) {
          return;
        }
        items.forEach((item: any) => {
          switch (item.label) {
            case 'Schedule':
              item.label = this.t.translate('My Schedule');
              break;
            case 'Notifications':
              item.label = this.t.translate('Messages');
              break;
            case 'BOSS':
              item.label = this.t.translate('Daily Tasks');
              break;
            case 'Read & Sign Docs':
              item.label = this.t.translate('Onboarding');
              break;
            case 'Learning Center':
              item.label = this.t.translate('Training & Tutorials');
              break;
            default:
              break;
          }
        });
      });
    if ('code' in menuRes) {
      // this.router.navigateByUrl('/user/login')
      //   .then(() => {
      //   });
    } else {

      this.subs.push(this.apiService.getChainCached(true)
        .subscribe((chain: IApiChain) => {
          this.chain = chain;
          this.apiService.getUserCached(reloadUser)
            .then(userRes => {
              if (!userRes.avatar_url.startsWith('http')) {
                this.avatarUrl = environment.apiUrl + userRes.avatar_url;
              } else {
                this.avatarUrl = userRes.avatar_url;
              }
              this.state.setTimeZone(userRes.timeZone);
              this.state.setTimeZoneId(userRes.timeZoneId);
              this.state.setScheduleSettings(userRes.scheduleSettings);
              this.state.updateTimeZone.emit();
              this.user = userRes;
              this.toAddStaffToMenu(menuRes)
              this.user.branches.forEach((branch) => {
                if (Number(branch.branch_id) === Number(this.user.current_branch_id)) {
                  this.branch = branch;
                  this.language.setupLanguageFromSettings(chain, branch, userRes);
                }
              });
              if (this.user.sign_picture === null) {
                this.router.navigateByUrl('/user/index');
              }
            });
        }));
    }
  }

  toReplaceRoute(menuRes){
    this.menu = menuRes.data.map(i => {
      i.route = '/' + i.route.replace(/\./g, '/');
      return i;
    });
    this.layout.menuLoaded.emit(this.menu);
  }

  async toAddStaffToMenu(menuRes){
/*    if ( this.user.is_cm || this.user.is_bm || this.user.is_dm) {
      let item = {
        counter: 0,
        iconClass: "icon fa fa-pencil",
        isActive: false,
        label: this.t.translate('Staff'),
        route: "managerPortal",
      }

      if ( menuRes && Array.isArray(menuRes?.data)){
        menuRes.data.push(item);
      }
    }*/

    this.toReplaceRoute(menuRes);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
