import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  public onChangeStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onChangeHeaderVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();

}
