import {Injectable} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {ActionSheet, ActionSheetButtonStyle} from '@capacitor/action-sheet';
import {Camera, CameraResultType, CameraSource, GalleryPhotos, Photo} from '@capacitor/camera';
import {Filesystem} from '@capacitor/filesystem';

@Injectable({
  providedIn: 'root'
})
export class CapacitorFilePickerService {

  // public fileSelected: EventEmitter<CapacitorFilePickerResult> = new EventEmitter<CapacitorFilePickerResult>();
  private multiple = true;
  private maxWidth = 1200;
  private maxHeight = 1200;
  private actionSheetOptions = [];

  constructor(
    private t: TranslocoService,
    // private ngZone: NgZone,
  ) {
    this.actionSheetOptions = [
      {
        title: this.t.translate('Camera')
      },
      {
        title: this.t.translate('Picture Gallery')
      },
      {
        title: this.t.translate('Cancel'),
        style: ActionSheetButtonStyle.Destructive
      }
    ];
  }

  public RunAsync(multiple: boolean, maxWidth = 0, maxHeight = 0) {
    return new Promise<CapacitorFilePickerResult[]>((resolve, reject) => {
      this.multiple = multiple;
      if (maxWidth > 0) {
        this.maxWidth = maxWidth;
      }
      if (maxHeight > 0) {
        this.maxHeight = maxHeight;
      }

      ActionSheet.showActions({
        title: this.t.translate('Pick image source'),
        options: this.actionSheetOptions
      }).then((promptRet) => {

        switch (promptRet.index) {
          case 0:
            Camera.getPhoto({
              quality: 100,
              allowEditing: false,
              resultType: CameraResultType.Base64,
              correctOrientation: true,
              width: this.maxWidth,
              height: this.maxHeight,
              source: CameraSource.Camera,
              // promptLabelPicture: this.t.translate('Camera'),
              // promptLabelPhoto: this.t.translate('Picture Gallery'),
              // promptLabelHeader: this.t.translate('Pick image source')
            }).then((photo: Photo) => {
              resolve([{
                data: photo.base64String,
                filename: (new Date()).getTime() + '.jpg'
              }]);
            }).catch(e => reject(e));
            break;
          case 1:
            Camera.pickImages({
              quality: 100,
              limit: this.multiple ? 10 : 1,
              width: this.maxWidth,
              height: this.maxHeight,
            }).then((results: GalleryPhotos) => {
              const res: CapacitorFilePickerResult[] = [];
              let c = 0;
              let t = results.photos.length;
              for (let i = 0; i < results.photos.length; i++) {
                Filesystem.readFile({path: results.photos[i].path})
                  .then(r => {
                    res.push({
                      data: r.data.toString(),
                      filename: results.photos[i].path.substring(results.photos[i].path.lastIndexOf('/') + 1)
                    });
                  })
                  .finally(() => {
                    c++;
                    if (c == t) {
                      resolve(res);
                    }
                  });
              }
            });
            break;
        }
      });
    });
  }


  // public openPanel(multiple: boolean, fieldId: number, maxWidth = 0, maxHeight = 0) {
  //   // this.multiple = multiple;
  //   // if(maxWidth > 0) {
  //   //   this.maxWidth = maxWidth;
  //   // }
  //   // if(maxHeight > 0) {
  //   //   this.maxHeight = maxHeight;
  //   // }
  //   // // const isIos = (environment.isApp && Capacitor.getPlatform() === 'ios');
  //   //
  //   // ActionSheet.showActions({
  //   //   title: this.t.translate('Pick image source'),
  //   //   options: this.actionSheetOptions
  //   // }).then((promptRet) => {
  //   //
  //   //   switch (promptRet.index) {
  //   //     case 0:
  //   //       this.executeCamera(fieldId);
  //   //       break;
  //   //     case 1:
  //   //       this.executeGallery(fieldId);
  //   //       break;
  //   //   }
  //
  //   // const permissions = cordova.plugins.permissions;
  //   // switch (promptRet.index) {
  //   //   case 0:
  //   //     permissions.checkPermission('android.permission.CAMERA', (status) => {
  //   //       if (status.hasPermission) {
  //   //         this.executeCamera();
  //   //       } else {
  //   //         permissions.checkPermission(
  //   //           'android.permission.CAMERA',
  //   //           (status) => {
  //   //             if (status.hasPermission) {
  //   //               this.executeCamera();
  //   //             }
  //   //           },
  //   //           () => {
  //   //           }
  //   //         );
  //   //       }
  //   //     });
  //   //     break;
  //   //   case 1:
  //   //     permissions.checkPermission('android.permission.READ_EXTERNAL_STORAGE', (status) => {
  //   //       if (status.hasPermission) {
  //   //         if (isIos) {
  //   //           this.checkIosPermissions();
  //   //         } else {
  //   //           this.executeGallery();
  //   //         }
  //   //       } else {
  //   //         permissions.checkPermission(
  //   //           'android.permission.READ_EXTERNAL_STORAGE',
  //   //           (status) => {
  //   //             if (status.hasPermission) {
  //   //               isIos ? this.checkIosPermissions() : this.executeGallery();
  //   //             }
  //   //           },
  //   //           () => {
  //   //           }
  //   //         );
  //   //       }
  //   //     });
  //   //     break;
  //   //   default:
  //   //     break;
  //   // }
  //
  //   // });
  //
  //
  // }

  // checkIosPermissions() {
  //   // const ACCESS_TO_PHOTO_GRANTED = 'granted';
  //   // const ACCESS_TO_PHOTO_DENIED = 'denied';
  //   // const ACCESS_TO_PHOTO_PROMPT = 'prompt';
  //   //
  //   // const isIos = (environment.isApp && Capacitor.getPlatform() === 'ios');
  //   //
  //   // if (isIos) {
  //   //   Permissions.query({name: 'photos'} as PermissionsOptions)
  //   //     .then((permissionResult) => {
  //   //     if (typeof permissionResult !== 'undefined'
  //   //       && typeof permissionResult.state !== 'undefined'
  //   //       && (permissionResult.state === ACCESS_TO_PHOTO_GRANTED || permissionResult.state === ACCESS_TO_PHOTO_PROMPT)
  //   //     ) {
  //   //       this.executeGallery();
  //   //     }
  //   //
  //   //     if (typeof permissionResult !== 'undefined'
  //   //       && typeof permissionResult.state !== 'undefined'
  //   //       && permissionResult.state === ACCESS_TO_PHOTO_DENIED
  //   //     ) {
  //   //       alert(this.t.translate('Access to the camera has been prohibited; please enable it in the Settings app to continue'));
  //   //     }
  //   //   }).catch((e) => console.log('Permissions.query error: ' + JSON.stringify(e)));
  //   // }
  // }

  // executeCamera(fieldId: number) {
  //   // navigator.camera.getPicture(
  //   //   (imageData: string) => {
  //   //     this.ngZone.run(() => {
  //   //       this.fileSelected.emit({
  //   //         data: imageData,
  //   //         filename: (new Date()).getTime()+'.jpg'
  //   //       });
  //   //     })
  //   //   },
  //   //   () => {
  //   //   },
  //   //   {
  //   //     correctOrientation: true,
  //   //     targetWidth: this.maxWidth,
  //   //     targetHeight: this.maxHeight,
  //   //     destinationType: 0 //BASE64 encoded
  //   //   }
  //   // );
  // }

  // executeGallery(fieldId: number) {
  //   // window['imagePicker'].getPictures(
  //   //   (results) => {
  //   //     for (let i = 0; i < results.length; i++) {
  //   //       // use capacitor FS plugin to read file
  //   //       Filesystem.readFile({path: results[i]})
  //   //           .then((r: ReadFileResult) => {
  //   //           this.ngZone.run(() => {
  //   //             this.fileSelected.emit({
  //   //               data: r.data,
  //   //               filename: results[i].substring(results[i].lastIndexOf('/')+1)
  //   //             });
  //   //           });
  //   //         }, (error) => {
  //   //           console.log('executeGallery error', error)
  //   //         })
  //   //         .catch((reason) => {
  //   //           console.log('CATCH: ', reason);
  //   //         });
  //   //     }
  //   //   },
  //   //   (error) => {
  //   //     console.log('Error: ' + error);
  //   //   },
  //   //   {
  //   //     maximumImagesCount: this.multiple ? 10 : 1,
  //   //     width: this.maxWidth,
  //   //     height: this.maxHeight
  //   //   }
  //   // ).catch((e) => console.log('Photo Permissions. error: ' + JSON.stringify(e)));
  // }

  public dataURLtoFile(dataurl: string, filename: string) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }


}

export interface CapacitorFilePickerResult {
  data: string,
  filename: string
}
