import {Bezier} from './bezier';
import {Point} from './point';

export class SignPad {

  velocityFilterWeight: number;
  minWidth: number;
  maxWidth: number;
  dotSize: () => number;
  penColor: string;
  backgroundColor: string;
  onEnd: (event) => void;
  onBegin: (event) => void;
  _canvas: HTMLCanvasElement;
  _ctx: CanvasRenderingContext2D;
  _mouseButtonDown = false;
  _isEmpty: boolean;
  _lastVelocity: number;
  _lastWidth: number;

  points: Point[] = [];

  constructor(canvas: HTMLCanvasElement, options) {

    const opts: any = options || {};

    this.velocityFilterWeight = opts.velocityFilterWeight || 0.7;
    this.minWidth = opts.minWidth || 0.5;
    this.maxWidth = opts.maxWidth || 2.5;
    this.dotSize = opts.dotSize || ((this.minWidth + this.maxWidth) / 2);
    this.penColor = opts.penColor || 'black';
    this.backgroundColor = opts.backgroundColor || 'rgba(0,0,0,0)';
    this.onEnd = opts.onEnd;
    this.onBegin = opts.onBegin;

    this._canvas = canvas;
    this._ctx = canvas.getContext('2d');
    this.clear();

    this._handleMouseEvents();
    this._handleTouchEvents();
  }

  _handleMouseDown(event) {
    if (event.which === 1) {
      this._mouseButtonDown = true;
      this._strokeBegin(event);
    }
  }

  _handleMouseMove(event) {
    if (this._mouseButtonDown) {
      this._strokeUpdate(event);
    }
  }

  _handleMouseUp(event) {
    if (event.which === 1 && this._mouseButtonDown) {
      this._mouseButtonDown = false;
      this._strokeEnd(event);
    }
  }

  _handleTouchStart(event) {
    if (event.targetTouches.length === 1) {
      const touch = event.changedTouches[0];
      this._strokeBegin(touch);
    }
  }

  _handleTouchMove(event) {
    // Prevent scrolling.
    event.preventDefault();

    const touch = event.targetTouches[0];
    this._strokeUpdate(touch);
  }

  _handleTouchEnd(event) {
    const wasCanvasTouched = event.target === this._canvas;
    if (wasCanvasTouched) {
      event.preventDefault();
      this._strokeEnd(event);
    }
  }

  clear() {
    const ctx = this._ctx;
    const canvas = this._canvas;

    ctx.fillStyle = this.backgroundColor;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    this._reset();
  }

  _strokeUpdate(event) {
    this._addPoint(this._createPoint(event));
  }

  _strokeBegin(event) {
    this._reset();
    this._strokeUpdate(event);
    if (typeof this.onBegin === 'function') {
      this.onBegin(event);
    }
  }

  _strokeDraw(point) {
    const dotSize = typeof (this.dotSize) === 'function' ? this.dotSize() : this.dotSize;

    this._ctx.beginPath();
    this._drawPoint(point.x, point.y, dotSize);
    this._ctx.closePath();
    this._ctx.fill();
  }

  _strokeEnd(event) {
    const canDrawCurve = this.points.length > 2;
    const point = this.points[0];

    if (!canDrawCurve && point) {
      this._strokeDraw(point);
    }
    if (typeof this.onEnd === 'function') {
      this.onEnd(event);
    }
  }

  _handleMouseEvents() {
    this._mouseButtonDown = false;

    this._canvas.addEventListener('mousedown', (event) => this._handleMouseDown(event));
    this._canvas.addEventListener('mousemove', (event) => this._handleMouseMove(event));
    document.addEventListener('mouseup', (event) => this._handleMouseUp(event));
  }

  _handleTouchEvents() {
    // Pass touch events to canvas element on mobile IE.
    this._canvas.style.touchAction = 'none';

    this._canvas.addEventListener('touchstart', (event) => this._handleTouchStart(event));
    this._canvas.addEventListener('touchmove', (event) => this._handleTouchMove(event));
    document.addEventListener('touchend', (event) => this._handleTouchEnd(event));
  }

  on() {
    this._handleMouseEvents();
    this._handleTouchEvents();
  }

  off() {
    this._canvas.removeEventListener('mousedown', this._handleMouseDown);
    this._canvas.removeEventListener('mousemove', this._handleMouseMove);
    document.removeEventListener('mouseup', this._handleMouseUp);

    this._canvas.removeEventListener('touchstart', this._handleTouchStart);
    this._canvas.removeEventListener('touchmove', this._handleTouchMove);
    document.removeEventListener('touchend', this._handleTouchEnd);
  }

  isEmpty() {
    return this._isEmpty;
  }

  _reset() {
    this.points = [];
    this._lastVelocity = 0;
    this._lastWidth = (this.minWidth + this.maxWidth) / 2;
    this._isEmpty = true;
    this._ctx.fillStyle = this.penColor;
  }

  _createPoint(event) {
    const rect = this._canvas.getBoundingClientRect();
    return new Point(
      event.clientX - rect.left,
      event.clientY - rect.top
    );
  }

  _addPoint(point) {
    let c2;
    let c3;
    let curve;
    let tmp;

    this.points.push(point);

    if (this.points.length > 2) {
      // To reduce the initial lag make it work with 3 points
      // by copying the first point to the beginning.
      if (this.points.length === 3) {
        this.points.unshift(this.points[0]);
      }

      tmp = this._calculateCurveControlPoints(this.points[0], this.points[1], this.points[2]);
      c2 = tmp.c2;
      tmp = this._calculateCurveControlPoints(this.points[1], this.points[2], this.points[3]);
      c3 = tmp.c1;
      curve = new Bezier(this.points[1], c2, c3, this.points[2]);
      this._addCurve(curve);

      // Remove the first element from the list,
      // so that we always have no more than 4 points in points array.
      this.points.shift();
    }
  }

  _calculateCurveControlPoints(s1, s2, s3) {
    const dx1 = s1.x - s2.x;
    const dy1 = s1.y - s2.y;
    const dx2 = s2.x - s3.x;
    const dy2 = s2.y - s3.y;
    const m1 = {x: (s1.x + s2.x) / 2.0, y: (s1.y + s2.y) / 2.0};
    const m2 = {x: (s2.x + s3.x) / 2.0, y: (s2.y + s3.y) / 2.0};
    const l1 = Math.sqrt(dx1 * dx1 + dy1 * dy1);
    const l2 = Math.sqrt(dx2 * dx2 + dy2 * dy2);
    const dxm = (m1.x - m2.x);
    const dym = (m1.y - m2.y);
    const k = l2 / (l1 + l2);
    const cm = {x: m2.x + dxm * k, y: m2.y + dym * k};
    const tx = s2.x - cm.x;
    const ty = s2.y - cm.y;

    return {
      c1: new Point(m1.x + tx, m1.y + ty),
      c2: new Point(m2.x + tx, m2.y + ty)
    };
  }

  _addCurve(curve: Bezier) {
    const startPoint = curve.startPoint;
    const endPoint = curve.endPoint;
    let velocity: number;
    let newWidth;

    velocity = endPoint.velocityFrom(startPoint);
    velocity = this.velocityFilterWeight * velocity
      + (1 - this.velocityFilterWeight) * this._lastVelocity;

    newWidth = this._strokeWidth(velocity);
    this._drawCurve(curve, this._lastWidth, newWidth);

    this._lastVelocity = velocity;
    this._lastWidth = newWidth;
  }

  _drawPoint(x, y, size) {
    this._ctx.moveTo(x, y);
    this._ctx.arc(x, y, size, 0, 2 * Math.PI, false);
    this._isEmpty = false;
  }

  _drawCurve(curve, startWidth, endWidth) {
    const ctx = this._ctx;
    const widthDelta = endWidth - startWidth;
    let drawSteps;
    let width = 0;
    let i;
    let t;
    let tt;
    let ttt;
    let u;
    let uu;
    let uuu;
    let x;
    let y;

    drawSteps = Math.floor(curve.length());
    ctx.beginPath();
    for (i = 0; i < drawSteps; i++) {
      // Calculate the Bezier (x, y) coordinate for this step.
      t = i / drawSteps;
      tt = t * t;
      ttt = tt * t;
      u = 1 - t;
      uu = u * u;
      uuu = uu * u;

      x = uuu * curve.startPoint.x;
      x += 3 * uu * t * curve.control1.x;
      x += 3 * u * tt * curve.control2.x;
      x += ttt * curve.endPoint.x;

      y = uuu * curve.startPoint.y;
      y += 3 * uu * t * curve.control1.y;
      y += 3 * u * tt * curve.control2.y;
      y += ttt * curve.endPoint.y;

      width = startWidth + ttt * widthDelta;
      this._drawPoint(x, y, width);
    }
    ctx.closePath();
    ctx.fill();
  }

  _strokeWidth(velocity) {
    return Math.max(this.maxWidth / (velocity + 1), this.minWidth);
  }
}




