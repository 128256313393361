import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserLoginComponent} from './user-login.component';
import {FormsModule} from '@angular/forms';
import {MainModule} from '../../../layout/main/main.module';
import {RouterModule} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';
import {SelectDomainComponent} from './select-domain/select-domain.component';
import {UserLoginEmailPasswordComponent} from './email-password/email-password.component';
import {RestorePasswordComponent} from './restore-password/restore-password.component';
import {UserLoginRoutingModule} from './user-login-routing.module';
import {SelectBranchComponent} from './select-branch/select-branch.component';
import {SelectChainComponent} from './select-chain/select-chain.component';
import {LoginCodePromptComponent} from './login-code-prompt/login-code-prompt.component';
import {LoginCodeVerifyComponent} from './login-code-verify/login-code-verify.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';

@NgModule({
  declarations: [
    UserLoginComponent,
    UserLoginEmailPasswordComponent,
    RestorePasswordComponent,
    SelectDomainComponent,
    SelectBranchComponent,
    SelectChainComponent,
    LoginCodePromptComponent,
    LoginCodeVerifyComponent,
    ResetPasswordComponent
  ],
  imports: [
    UserLoginRoutingModule,
    CommonModule,
    FormsModule,
    RouterModule,
    MainModule,
    TranslocoModule
  ],
  exports: [UserLoginComponent]
})
export class UserLoginModule {
}


