<div class="login-page">
  <div class="login-page-center">
    <div class="login-page-center-white-box">
      <div class="wrapper login-logo-wrapper">
        <img [src]="userLoginService.logo" alt="Home"
             (mousedown)="domainSelectStart()"
             (mouseup)="domainSelectEnd()"
             (touchstart)="domainSelectStart()"
             (touchend)="domainSelectEnd()">
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
  <footer>
    <div>
      <a href="http://www.betterchains.com" target="_blank">Better Chains®</a>.
      {{ 'All Rights Reserved. Version:' | transloco }} {{FOHversion}}
      <span *ngIf="timeZoneId">({{timeZoneId}})</span>
      <span></span> {{'This page is secured'|transloco}}
    </div>
    <div *ngIf="isTestDomain">
      <span style="background-color: red;color:white;padding: 4px">
        {{testEnv}}:
        {{testDomain}}
      </span>
    </div>
  </footer>
</div>




