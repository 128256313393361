import {SignPad} from './sign-pad';
import {ComponentService} from '../component.service';

export class Canvas {
  signaturePad: SignPad;
  textContext: CanvasRenderingContext2D;
  canvas: HTMLCanvasElement;
  isEmptySignature = true;
  signatureBackGroundColor: string;

  constructor(transparentBackground, canvas: HTMLCanvasElement,  componentService: ComponentService) {

    const TRANSPARENT_BACKGROUND_COLOR = 'transparent';
    this.signatureBackGroundColor = (typeof transparentBackground !== 'undefined' && transparentBackground === TRANSPARENT_BACKGROUND_COLOR) ?
      'rgba(255,255,255,0)' : 'rgba(255,255,255, 0)';

    this.canvas = canvas;

    this.signaturePad = new SignPad(this.canvas, {
      backgroundColor: this.signatureBackGroundColor,
      onBegin: () => {
        // SignPadV3.errorSet.hide();
        this.isEmptySignature = false;

        if ( typeof componentService !== 'undefined' ) {
          componentService.riseSignatureIsNotEmpty();
        }

      },
      onEnd: () => {
        this.isEmptySignature = false;
        if ( typeof componentService !== 'undefined' ) {
          componentService.riseSignatureIsNotEmpty();
        }
      }
    });
    this.signaturePad.minWidth = 1;
    this.signaturePad.maxWidth = 3;

    this.textContext = this.canvas.getContext('2d');
  }


  isEmpty() {
    return this.isEmptySignature;
  }

  text(text: string) {
    if (text.trim() !== '') {
      this.isEmptySignature = false;
      this.textContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.textContext.fillStyle = this.signatureBackGroundColor; // SignPadV3.canvas.signaturePad.backgroundColor;
      this.textContext.fillRect(0, 0, this.canvas.width, this.canvas.height);
      this.textContext.fillStyle = 'black'; // SignPadV3.canvas.signaturePad.penColor;
      this.textContext.textBaseline = 'middle';
      this.textContext.textAlign = 'start';
      this.fitTextOnCanvas(text.trim(), 'Pinyon Script');
    }
  }


  fitTextOnCanvas(text, fontface) {
    let fontsize = 100;
    const htmlTag = document.body.parentElement;
    const isRtlMode = (
      typeof htmlTag.attributes.getNamedItem('lang') !== 'undefined'
      &&
      htmlTag.attributes.getNamedItem('lang').value
      &&
      htmlTag.attributes.getNamedItem('lang').value === 'he') ?
      1 : 0;
    const canvasX = isRtlMode ? this.canvas.width * 0.95 : this.canvas.width - this.canvas.width * 0.95;
    const canvasY = (this.canvas.height) / 2;
    const valToCompare = (this.canvas.width - (this.canvas.width * 0.15));
    do {
      fontsize--;
      this.textContext.font = fontsize + 'pt' + ' ' + fontface;
    } while (this.textContext.measureText(text).width > valToCompare);

    this.textContext.fillText(text, canvasX, canvasY, this.canvas.width);
  }

}
