import {Component} from '@angular/core';
import {ComponentsModule} from '../../components/components.module';
import {QrService} from '../../services/qr.service';

// import {BarcodeScanner} from '@capacitor-community/barcode-scanner';

@Component({
  standalone: true,
  selector: 'test-capacitor',
  imports: [
    ComponentsModule
  ],
  template: `
    <div style="padding: 32px">
      <foh-card>

        <button (click)="scanQR()" class="btn btn-lg btn-primary">SCAN QR</button>

      </foh-card>
    </div>
  `
})
export class TestCapacitorComponent {

  constructor(
    private qrService: QrService
  ) {
  }

  async scanQR() {
    this.qrService.startScan();
  }

}
