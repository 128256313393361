<foh-card>
  <form enctype="multipart/form-data"
        class="form-horizontal" id="user-profile-image-form">
    <div class="form-group">
      <label class="col-sm-3 control-label">
        {{'Profile picture'|transloco}}
      </label>

      <div class="col-sm-9">

        <div class="current-img" *ngIf="!changingAvatar">
          <img class="img-thumbnail" src="{{avatarUrl}}" alt="User Avatar">
        </div>

        <foh-icon-card *ngIf="changingAvatar" iconColor="white" iconBackgroundColor="var(--foh-color-secondary)"
                       icon="fa-light fa-circle-info">
          {{ 'The Update button will be shown after picture uploading.' | transloco}}
          <br>
          {{ 'Please, crop the Uploaded Image and click the Update Button to finish uploading process.' | transloco}}
        </foh-icon-card>

        <image-cropper
          *ngIf="changingAvatar"
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="1"
          [resizeToWidth]="200"
          (imageCropped)="imageCropped($event)"
          [imageBase64]="imageBase64"
          [output]="'base64'"
          format="png"
        ></image-cropper>

        <div class="row" *ngIf="changingAvatar">
          <div class="col-xs-6">
            <button class="btn btn-large btn-primary" (click)="updateAvatar()">{{'Update' | transloco}}</button>
          </div>
          <div class="col-xs-6 text-right">
            <button class="btn btn-large btn-default"
                    (click)="cancelEditAvatar()">{{'Cancel' | transloco}}</button>
          </div>
        </div>

        <div class="form-group" *ngIf="!isApp">
          <div class="col-sm-12">
            <input class="form-control" *ngIf="!changingAvatar"
                   style="height:auto"
                   (change)="fileChangeEvent($event)"
                   accept="image/png, image/jpeg"
                   type="file">
          </div>
        </div>

        <div class="form-group" *ngIf="!changingAvatar">
          <div class="foh-avatar-buttons">
            <button class="btn btn-large btn-primary" *ngIf="isApp" (click)="appFilePicker()">
              {{'Choose File'|transloco}}
            </button>
            <button type="submit" class="btn btn-danger"
                    *ngIf="avatarUrl.indexOf('/img/default-user-avatar.png') < 0"
                    (click)="deleteAvatarConfirm()">
              {{'Delete profile picture' | transloco}}
            </button>
          </div>
        </div>
        <div class="error-message" id="Users_avatar_em_" style="display: none;"></div>
      </div>
    </div>
  </form>
</foh-card>

