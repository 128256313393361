<foh-container>
  <foh-content>
    <foh-card class="page-404">
      <h5>{{'Code 404'| transloco}}</h5>
      <h5>{{'The requested resource is not found in the app'|transloco}}</h5>
      <ng-container *ngIf="pageNotFoundHandler.redirectUrl">
        <a href="#" class="text-center external-resource" (click)="redirect()">
          <h5>{{'Click here to access the external resource'|transloco}}</h5>
        </a>
      </ng-container>
      <div class="text-center">
        <a class="btn btn-large btn-primary" (click)="back()">{{'Go Back'|transloco}}</a>
      </div>
    </foh-card>
  </foh-content>
</foh-container>
