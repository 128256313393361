import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PlainDialogComponent} from './plain-dialog/plain-dialog.component';
import {BsModalService} from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class ComponentService {

  showSignDialog = new EventEmitter<string>();
  showFormDialog = new EventEmitter<string>();
  closedFormDialog = new EventEmitter<string>(); //rises when form popup closes

  hideSignDialog = new EventEmitter<string>();

  signatureIsNotEmpty = new EventEmitter<string>();
  dialogVisibleStatus = false;

  public MOBILE_WIDTH = 720;
  public ALERT_OK = 1;
  public ALERT_CLOSE = 2;
  public ADDITIONAL_EVENT_RISE = 3;

  constructor(private modal: BsModalService) {
  }

  showSignDialogPopup() {
    this.showSignDialog.emit();
  }

  showFormPopup(id) {
    this.showFormDialog.emit(id);
  }

  formDialogClosed(id) {
    this.closedFormDialog.emit(id);
  }

  hideSignDialogPopup() {
    this.hideSignDialog.emit();
  }

  riseSignatureIsNotEmpty() {
    this.signatureIsNotEmpty.emit();
  }

  public confirm(state?: any, config: any = {
    animated: true,
    class:  ''
  }): Observable<void> {
    config.initialState = {
      title: state?.title ?? 'Confirm',
      message: state?.message ?? 'Are you sure?',
      okText: state?.okText ?? 'Ok',
      closeText: state?.closeText ?? 'Cancel',
      okBeforeClose: state?.okBeforeClose ?? true,
      additionalButton: state?.additionalButton ?? false,
      additionalButtonAddClass: state?.additionalButtonAddClass ?? '',
      additionalText: state?.additionalButton && state?.additionalText ? state?.additionalText : '',
      showClose: state?.showClose === true || state?.showClose === false ? state?.showClose : true,
      additionalStyle: state?.additionalStyle ?? '',
      showSuccessIcon: state?.showSuccessIcon ?? '',
    };
    return new Observable<any>(r => {
      const sub = this.modal.show(PlainDialogComponent, config);
      (sub.content as PlainDialogComponent).confirmEvent.subscribe(() => {
        r.next(this.ALERT_OK);
        sub.hide();
      });

      (sub.content as PlainDialogComponent).confirmCloseEvent.subscribe(() => {
        r.next(this.ALERT_CLOSE);
        sub.hide();
      });

      (sub.content as PlainDialogComponent).additionalEventRise.subscribe(() => {
        r.next(this.ADDITIONAL_EVENT_RISE);
        sub.hide();
      });
    });
  }
}
