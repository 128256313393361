import {Component, HostBinding, HostListener, Input, OnInit} from '@angular/core';
import {Params} from '@angular/router';

@Component({
  selector: 'foh-icon-card',
  template: `
    <div class="icon" [ngStyle]="{
    color:iconColor,
    padding: mainPadding,
    backgroundColor:iconBackgroundColor,
    borderRadius:iconBorderRadius,
    marginLeft:iconMarginLeft,
    marginRight:iconMarginRight,
    marginTop:'-'+mainPadding,
    marginBottom:'-'+mainPadding
    }">
      <i *ngIf="!loading" class="{{icon}}"></i>
      <i *ngIf="loading" class="fa-light fa-refresh fa-spin fa-fw"></i>
      <span class="sr-only">Loading...</span>
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
    <div class="arrow" *ngIf="href || routerLink || showArrow">
      <i [ngStyle]="{color: arrowColor}" class="fa-light fa-angle-{{isRTL ? 'left' : 'right'}}" aria-hidden="true"></i>
    </div>
    <div class="counter" *ngIf="counter && counter > 0"><bdo dir="ltr">{{(counter < 100) ? counter : '99+'}}</bdo></div>
  `,
  styles: [`
    :host {
      display: flex;
      flex-shrink: 0;
      flex-direction: row;
      width: 100%;
      border-radius: 10px;
      box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.08);
      background-color: var(--foh-card-bg-color);
      margin-bottom: 15px;
      align-content: stretch;
      cursor: pointer;
      position: relative;
      outline: none;
      /*min-height: 52px;*/
    }

    :host > div.icon {
      margin-top: -15px;
      margin-bottom: -15px;
      /*padding: 15px;*/
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      font-size: 22px;
    }

    :host > div.content {
      font-size: 14px;
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    :host > div.arrow {
      display: flex;
      align-items: center;
      color: #aab7c9;
    }

    :host > div.arrow > i {
      width: 16px;
      font-size: 22px
    }

    :host > div.counter {
      position: absolute;
      top: -9px;
      left: -9px;
      right: auto;
      background-color: black;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      color: white;
      font-size: 10px;
      /*border: solid 1px white;*/
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    html[dir=rtl] :host > div.counter {
      right: -9px;
      left: auto;
    }
  `]
})
export class FohIconCardComponent implements OnInit {
  isRTL = document.body.parentElement.getAttribute('dir') === 'rtl';
  @HostBinding('style.color') foregroundColor = null;
  @HostBinding('style.background-color') backgroundColor = null;
  @HostBinding('style.padding') mainPadding = '15px';

  @Input() loading = false;
  @Input() icon: string = 'fa-light fa-file';
  @Input() icons: { [s: string]: string } = {};
  @Input() iconColor: string = 'black';
  @Input() arrowColor: string = '#aab7c9';
  @Input() iconColors: { [s: string]: string } = {};
  @Input() iconBackgroundColor: string = 'white';
  @Input() iconBackgroundColors: { [s: string]: string } = {};
  @Input() counter = 0;
  @Input() routerLink: string = null;
  @Input() showArrow: boolean = null;
  @Input() queryParams: Params = null;
  @Input() href = null;

  @Input() set padding(v: string) {
    this.mainPadding = v;
  }

  @Input()
  set statuses(statuses: { [s: string]: boolean }) {
    for (let s in statuses) {
      if (statuses[s]) {
        if (this.icons.hasOwnProperty(s)) {
          this.icon = this.icons[s];
        }
        if (this.iconColors.hasOwnProperty(s)) {
          this.iconColor = this.iconColors[s];
        }
        if (this.iconBackgroundColors.hasOwnProperty(s)) {
          this.iconBackgroundColor = this.iconBackgroundColors[s];
        }
      }
    }
  }

  iconBorderRadius = this.isRTL ? '0 10px 10px 0' : '10px 0 0 10px';
  iconMarginRight = this.isRTL ? '-'+this.mainPadding : this.mainPadding;
  iconMarginLeft = this.isRTL ? this.mainPadding : '-'+this.mainPadding;

  restyle() {
    this.isRTL = document.body.parentElement.getAttribute('dir') === 'rtl';
    this.iconBorderRadius = this.isRTL ? '0 10px 10px 0' : '10px 0 0 10px';
    this.iconMarginRight = this.isRTL ? '-'+this.mainPadding : this.mainPadding;
    this.iconMarginLeft = this.isRTL ? this.mainPadding : '-'+this.mainPadding;
  }

  @Input()
  set background(color: string) {
    this.backgroundColor = color;
  }

  @Input()
  set color(color: string) {
    this.foregroundColor = color;
  }

  @HostListener('click', ['$event'])
  onClick() {
    if (this.href && !this.routerLink) {
      window.location.href = this.href;
    }
  }

  constructor() {
    (new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'dir') {
          this.restyle();
        }
      }
    })).observe(document.getElementsByTagName('html')[0], {attributes: true, childList: false, subtree: false});
  }

  ngOnInit() {
    this.restyle();
  }

}
