import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StateService} from './services/state.service';
import {Subscription} from 'rxjs';
import {ApiService} from './services/api.service';

@Component({
  selector: 'foh-login-by-prepared-token',
  standalone: true,
  template: ``
})
export class LoginByPreparedTokenComponent implements OnDestroy {

  sub: Subscription;

  constructor(
    api: ApiService,
    router: Router,
    route: ActivatedRoute,
    state: StateService
  ) {
    const preparedToken = route.snapshot.queryParamMap.get('preparedToken');
    if (preparedToken && preparedToken.trim() != '') {
      state.setApiToken(preparedToken);
      this.sub = api.isAuthenticated().subscribe({
        next: tokenValid => {
          if (tokenValid) {
            const destination = route.snapshot.queryParamMap.get('destination');
            if (destination && destination.trim() != '') {
              router.navigateByUrl(destination).then();
            } else {
              router.navigateByUrl('/dashboard').then();
            }
          } else {
            state.clear();
            router.navigateByUrl('/user/login').then();
          }
        }
      });
    } else {
      router.navigateByUrl('/user/login').then();
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
