<app-line-loader></app-line-loader>
<router-outlet></router-outlet>
<app-full-page-loader></app-full-page-loader>
<ngx-snackbar
  [position]="'bottom-center'"
  [timeout]="5000"
  [max]="1"
></ngx-snackbar>
<div id="foh-offline-banner" *ngIf="!state.connected">
  <div id="foh-offline-message">{{'You are offline'}}</div>
</div>
<app-qr-scan-overlay *ngIf="isApp || isLocalhost"></app-qr-scan-overlay>
