<h1>{{ 'Please select domain' | transloco}}</h1>
<section>
  <article class="foh-login-form-list-link"
           (click)="setDomain('https://api.betterchains.com', 'https://d1mcyggarp14he.cloudfront.net', '', true)">
    Production
  </article>
  <article
    (click)="setDomain('http://api.stage.betterchains.net', 'http://cdn.stage.betterchains.net', 'STAGE', false)">
    Staging
  </article>
  <article class="foh-login-form-list-link"
           (click)="setDomain('http://api.dev.betterchains.net', 'http://cdn.dev.betterchains.net', 'DEV', false)">
    Development
  </article>
</section>
<button class="btn btn-lg" (click)="cancel()">{{"Cancel"|transloco}}</button>
<button class="btn btn-lg btn-danger" (click)="testException()">Test Exception</button>
