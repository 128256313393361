import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {BcGqlService, IGQLIdNameTuple, IGQLResponse, IGQLUser} from '@betterchains/bc-gql';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private gql: BcGqlService
  ) {
  }

  public GetShort(): Promise<IGQLIdNameTuple[]> {
    return new Promise<IGQLIdNameTuple[]>((resolve, reject) => {
      firstValueFrom(this.gql.query(
        //language=gql
        `
          {
            hr {
              employees(limit: 0, sort: "first_name asc") {
                id, first_name, last_name
              }
            }
          }
        `
      )).then((r: HttpResponse<IGQLResponse<{ hr: { employees: IGQLUser[] } }>>) => {
        resolve(r.body.data.hr.employees.map(e => {
          return {id: Number(e.id), name: e.first_name + ' ' + e.last_name};
        }));
      })
        .catch(r => reject(r));
    });
  }

}
