import {Component, OnDestroy} from '@angular/core';
import {UserLoginService} from '../user-login.service';
import {LoaderService} from '../../../../services/loader.service';
import {Subscription} from 'rxjs';
import {ApiService} from '../../../../services/api.service';
import {TranslocoService} from '@ngneat/transloco';
import {LanguageService} from '../../../../services/language.service';
import {Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {StateService} from '../../../../services/state.service';

@Component({
  selector: 'foh-user-login-select-branch',
  styleUrls: ['select-branch.component.scss'],
  templateUrl: 'select-branch.component.html'
})
export class SelectBranchComponent implements OnDestroy {

  subs: Subscription[] = [];

  sError = '';

  constructor(
    public userLoginService: UserLoginService,
    private router: Router,
    private api: ApiService,
    private language: LanguageService,
    private t: TranslocoService,
    private state: StateService,
    private loader: LoaderService
  ) {
    if (this.userLoginService.branches.length == 0) {
      this.router.navigateByUrl('/user/login').then();
      return;
    }
  }

  setBranch(branchId) {
    if (branchId !== undefined) {
      this.loader.showLoader();
      this.subs.push(
        this.api.setBranch(branchId, this.userLoginService.selectedChainId).subscribe(
          (data: any) => {
            if (data.message !== undefined && data.code !== undefined && data.quick_login_url === undefined) {
              this.loader.hideLoader();
              this.sError = this.t.translate(data.message);
            } else {
              this.userLoginService.branches.forEach(b => {
                const chain = this.state.getChain();
                if (Number(branchId) === Number(b.branch_id)) {
                  this.language.setupLanguageFromSettings(chain, b, this.userLoginService.user);
                }
              });
              if (environment.domainMode) {
                window.location.replace(data.quick_login_url + '&rememberMe=1');
              } else {
                this.router.navigateByUrl('/')
                  .then(() => {
                    this.loader.hideLoader();
                    // this.setComponentClass('');
                  });
              }
            }
          },
          () => {
            this.loader.hideLoader();
            this.sError = this.t.translate('Something went wrong, please try again later.');
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
