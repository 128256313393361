<div *ngIf="show">
  <button *ngIf="isLocalhost" (click)="debug()">DEBUG</button>
  <div id="qrscancancel">
    <button (click)="cancel()">&times;</button>
  </div>
  <div id="qrscannerbottom">
    <!--    <button (click)="flash()">-->
    <!--      <svg enable-background="new 0 0 512 512" height="28px" viewBox="0 0 512 512" width="28px" fill="white"-->
    <!--           xmlns="http://www.w3.org/2000/svg">-->
    <!--        <g>-->
    <!--          <path-->
    <!--            d="m144.36 510.45c6.3 3.111 13.953 1.438 18.368-4.099l240-301c3.592-4.505 4.286-10.669 1.786-15.86s-7.752-8.491-13.514-8.491h-95.789l79.221-159.321c2.312-4.65 2.055-10.165-.681-14.579-2.734-4.414-7.559-7.1-12.751-7.1h-180c-6.889 0-12.892 4.692-14.556 11.376l-60 241c-1.115 4.48-.105 9.224 2.737 12.861 2.843 3.638 7.203 5.763 11.819 5.763h70.808l-55.363 222.376c-1.705 6.845 1.59 13.952 7.915 17.074zm81.196-250.826c1.115-4.48.105-9.224-2.737-12.861-2.843-3.638-7.203-5.763-11.819-5.763h-70.808l52.531-211h144.065l-79.221 159.321c-2.312 4.65-2.055 10.165.681 14.579 2.734 4.415 7.559 7.1 12.751 7.1h88.855l-177.67 222.828z"/>-->
    <!--        </g>-->
    <!--      </svg>-->
    <!--    </button>-->
    <!--    <button (click)="swap()">-->
    <!--      <svg height="28px" fill="white" width="28px" viewBox="0 -38 512 511" xmlns="http://www.w3.org/2000/svg">-->
    <!--        <path-->
    <!--          d="m380.933594 77.289062-19.632813-76.789062h-210.601562l-19.632813 76.789062h-60.105468v-38.945312h-30v38.945312h-40.960938v357.617188h512v-357.617188zm101.066406 327.617188h-452v-297.617188h124.363281l19.628907-76.789062h164.015624l19.628907 76.789062h124.363281zm0 0"/>-->
    <!--        <path-->
    <!--          d="m306.613281 301.914062 30.910157-12.066406c-5.078126 10.347656-12.15625 19.699219-20.953126 27.445313-16.136718 14.214843-36.9375 22.042969-58.570312 22.042969-26.300781 0-51.09375-11.566407-68.023438-31.734376l-22.976562 19.285157c22.640625 26.976562 55.8125 42.449219 91 42.449219 28.941406 0 56.785156-10.488282 78.402344-29.53125 10.21875-9 18.714844-19.613282 25.226562-31.316407l8.261719 21.160157 27.945313-10.910157-28.683594-73.449219-73.449219 28.683594zm0 0"/>-->
    <!--        <path-->
    <!--          d="m258 131.820312c-56.949219 0-104.65625 40.292969-116.125 93.867188l-16.007812-19.050781-22.96875 19.300781 50.335937 59.910156 61.105469-50.1875-19.042969-23.183594-24.164063 19.847657c8.4375-40.214844 44.179688-70.507813 86.867188-70.507813 36.296875 0 68.585938 21.726563 82.257812 55.347656l27.789063-11.300781c-18.289063-44.980469-61.488281-74.042969-110.046875-74.042969zm0 0"/>-->
    <!--      </svg>-->
    <!--    </button>-->
  </div>
</div>
