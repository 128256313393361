import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Canvas} from './canvas';
import {ApiService} from '../../services/api.service';
import {TranslocoService} from '@ngneat/transloco';
import {environment} from '../../../environments/environment';
import {DeviceDetectorService} from 'ngx-device-detector';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ComponentService} from '../component.service';
import {Subscription} from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {ChangeDetectorRef} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-sign-pad-v4',
  templateUrl: './sign-pad.component.html',
  styleUrls: ['sign-pad-new-styling.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        display: 'block'
      })),
      state('closed', style({
        opacity: 0,
        display: 'none'
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.005s')
      ]),
    ]),
  ]
})
export class SignPadComponent implements OnInit, AfterViewInit, OnDestroy {

  private _isShowing = false;
  public canvas: Canvas;

  DEFAULT_WIDTH  = 640;
  DEFAULT_HEIGHT = 360;

  drawCanvasWidth  = this.DEFAULT_WIDTH;
  drawCanvasHeight = this.DEFAULT_HEIGHT;

  canvasWidth  = this.DEFAULT_WIDTH;
  canvasHeight = this.DEFAULT_HEIGHT;

  textValue = '';

  signatureDataUrl = '';
  signatureWidth = this.DEFAULT_WIDTH;
  signatureHeight = this.DEFAULT_HEIGHT;

  tapIsVisible = true;

  isEmptySignature = true;

  DRAW_MODE_CANVAS_CLASS = 'draw-mode-canvas';
  TYPE_MODE_CANVAS_CLASS = 'type-mode-canvas';

  deployUrl = environment.deployUrl;
  drawMode = true;
  private subscription: Subscription;
  private subscription1: Subscription;
  private subscription2: Subscription;

  showESignAct = false;
  agreeOnTerms = false;

  @Input() transparency = 'transparent';
  isRtl = document.body.parentElement.getAttribute('dir') === 'rtl';
  @Input() isPhone = false;
  @Input() submitUrl = '/api/user/setSign?v=3';
  @Input() submitRedirect = '/';
  @Input() hiddenTerms = false;

  @Output() dialogClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() signatureSubmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() signatureSubmitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() signatureWithoutTermsSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() signaturePadClosedManually: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  get isShowing() {
    return this._isShowing;
  }

  set isShowing(v: boolean) {
    document.body.style.overflow = v ? 'hidden' : 'auto';
    if (v === true) {
      window.removeEventListener('resize', this.windowResizeListener);
      window.addEventListener('resize', this.windowResizeListener);
    }
    this._isShowing = v;
  }


  @ViewChild('typeInput') typeInputRef: ElementRef;
  // @ViewChild('signatureDialog', {static: false}) dialogRef: ElementRef;
  // @ViewChild('signatureModal', {static: false}) templ: ElementRef;
  @ViewChild('canvasWrapper') canvasWrapper: ElementRef;
  // @ViewChild('imageBackGround', {static: false}) imageBackGround: ElementRef;
  @ViewChild(ModalDirective) modal: ModalDirective;

  changeMode() {
    switch (this.showESignAct) {
      case true:
        this.changeShowTermsInfo();
        break;
      default:
        this.drawMode = !this.drawMode;
        this.prepareMode()
          .then();
        this.checkTapVisible();
        break;
    }
  }

  onClosedModal() {
    document.body.style.overflow = 'auto';
    this.closeModal();
    this.closeDialog();
  }


  focusInput() {
    if ( !this.drawMode && !this.showESignAct ) {
      setTimeout(() => this.ngZone.run(() => {
          document.getElementById('type-sign').focus();
/*          this.typeInputRef.nativeElement.focus();*/
          this.cd.detectChanges();
      }), 0);
    } else {
      setTimeout(() => this.ngZone.run(() => {
        document.getElementById('type-sign').blur();
/*        this.typeInputRef.nativeElement.blur(); */
        this.cd.detectChanges();
      }), 0);
    }
  }

  async prepareMode() {
    this.clearSignature();

    if ( this.drawMode === true) {
      this.tapIsVisible = true;
      this.setSizeDraw();
    } else {
      await this.setSizeType();
      await this.focusInput();
    }
  }

  initiateCanvas() {
    const saveTransparent = 'transparency';
    if ( typeof this.canvas === 'undefined' ) {
      const cnv = document.getElementById('cnvs') as HTMLCanvasElement;
      this.canvas = new Canvas(saveTransparent, cnv, this.componentService);
    }
  }

  windowResizeListener: () => void = () => {
    this.ngZone.run(() => {
      if ( this.drawMode === true ) {
        this.calculateSizeDraw();
       // this.closeModal();
      } else {
        this.setSizeType().then();
      }
    });
  }

  constructor(
    private host: ElementRef,
    private api: ApiService,
    private t: TranslocoService,
    private ngZone: NgZone,
    private deviceService: DeviceDetectorService,
    private modalService: BsModalService,
    private componentService: ComponentService,
    private cd: ChangeDetectorRef
  ) {
    this.isRtl = document.body.parentElement.getAttribute('dir') === 'rtl';

    this.getSubscription();
  }

  getSubscription() {
    this.subscription = this.componentService.showSignDialog.subscribe(() => {
      this.openModal();
    });

    this.subscription1 = this.componentService.hideSignDialog.subscribe(() => {
      this.closeModal();
    });

    this.subscription2 = this.componentService.signatureIsNotEmpty.subscribe(() => {
      this.isEmptySignature = false;
      this.tapIsVisible     = false;
    });
  }



  init() {
    this.initiateCanvas();
    this.checkTerms();
  }

  openModal() {
    this.closeModal();
    this.clearSignature();
    this.modal.show();

  }

  closeModal() {
    if ( this.modal.isShown === true ) {
      this.modal.hide();
    }
  }

  checkTerms() {
    if (this.hiddenTerms === true) {
      this.agreeOnTerms = true;
    }
  }

  changeShowTermsInfo() {
    this.showESignAct = !this.showESignAct;
    this.focusInput();
  }

  closeDialog() {
    this.dialogClose.emit(true);
    this.signaturePadClosedManually.emit();
  }

  setCopyCanvas() {
    const WHITE_BACKGROUND = 'rgba(255, 255, 255, 1)';
    const copy  = document.createElement('canvas');
    copy.width  = this.canvasWidth;
    copy.height = this.canvasHeight;
    const ctx   = copy.getContext('2d');
    ctx.fillStyle = WHITE_BACKGROUND;
    if ( typeof ctx.canvas !== 'undefined' && ctx.canvas ) {
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.drawImage(this.canvas.canvas, 0, 0);
      this.signatureDataUrl = ctx.canvas.toDataURL('image/png');
    } else {
      this.signatureDataUrl = this.canvas.canvas.toDataURL('image/png');
    }
  }

  async submitSign() {

    const TRANSPARENT = 'transparent';

    if ( this.transparency === TRANSPARENT ) {
      this.signatureDataUrl = this.canvas.canvas.toDataURL('image/png');
    } else {
      await this.setCopyCanvas();
    }
    if (this.canvas.isEmptySignature) {
      alert(this.t.translate('Please draw your signature or type it in the field.'));
      this.clearSignature();
    } else {
      if (!this.agreeOnTerms) {
        alert(this.t.translate('You must agree with the terms to submit.'));
      } else {
        if (this.hiddenTerms === true) {
          this.signatureWithoutTermsSubmitted.emit({
            sign: this.signatureDataUrl,
            width: this.signatureWidth,
            height: this.signatureHeight,
          });

          return true;
        }
        this.signatureSubmit.emit();
        this.api.setSign(this.signatureDataUrl)
          .subscribe(() => {
            this.dialogClose.emit(true);
            this.signatureSubmitted.emit();
            this.closeModal();
          });
      }
    }

    return true;
  }

  chAgree(e: Event) {
    const el = e.target as HTMLInputElement;
    this.agreeOnTerms = el.checked;
  }

  textChanged(e: KeyboardEvent) {
    const el = e.target as HTMLInputElement;

    if (el.value.trim() !== '') {
      this.canvas.text(el.value);
    } else {
      this.clearSignature();
    }
    this.textValue = el.value;
    this.isEmptySignature = false;
  }

  resetSignature() {
    this.textValue        = '';
    this.signatureDataUrl = '';

    if ( typeof this.canvas !== 'undefined' ) {
      this.canvas.signaturePad.clear();
      this.canvas.isEmptySignature = true;
      this.isEmptySignature        = true;
      this.tapIsVisible            = true;
    }

    this.isEmptySignature = true;
  }

  clearSignature() {
    this.resetSignature();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => this.ngZone.run(() => {
      this.calculateSizeDraw();
      this.init();
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }

  setSizeDraw() {
    this.canvasWidth   = this.drawCanvasWidth;
    this.canvasHeight  = this.drawCanvasHeight;
  }

  calculateSizeDraw() {
    this.drawCanvasWidth  = this.DEFAULT_WIDTH;
    this.drawCanvasHeight = this.DEFAULT_HEIGHT;

    const DEFAULT_RATIO = 9 / 16;
    const OFFSETS       = 86;

    if ( screen.availWidth <= ( this.drawCanvasWidth - OFFSETS ) ) {
      this.drawCanvasWidth  = screen.availWidth - OFFSETS;
      this.drawCanvasHeight = this.drawCanvasWidth * DEFAULT_RATIO < this.DEFAULT_HEIGHT ?
        this.drawCanvasWidth * DEFAULT_RATIO : this.DEFAULT_HEIGHT;
    }

    this.setSizeDraw();
  }

  onShowCallBack() {
    this.tapIsVisible = true;
  }

  async onShownCallBack() {
  // this.setSizeDraw();
    this.checkTapVisible();
  }


  async setSizeType() {
    await this.clearSignature();
    const DEFAULT_RATIO = 9 / 16;
    const typeWidth  = this.DEFAULT_WIDTH;
    const typeHeight = this.DEFAULT_WIDTH * DEFAULT_RATIO;

    this.canvasWidth  = typeWidth;
    this.canvasHeight = typeHeight ;
  }

  checkTapVisible() {
    setTimeout(() => this.ngZone.run(() => {
      this.tapIsVisible = false;
    } ), 1000);
  }
}
