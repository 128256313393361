import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {ApiService} from '../../../../services/api.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'foh-user-login-reset-password',
  templateUrl: 'reset-password.component.html',
  styleUrls: ['reset-password.component.scss']
})
export class ResetPasswordComponent implements OnDestroy, OnInit {

  subs: Subscription[] = [];

  public newPassword = '';
  public newPasswordConfirm = '';

  public newPasswordError = '';
  public newPasswordConfirmError = '';

  public code = '';

  public hasError = true;
  public hasResponseErrors = false;
  public responseErrors = [];

  public resetPasswordCodeIsExpired = false;

  public allWentGood = false;

  constructor(
    private t: TranslocoService,
    private route: ActivatedRoute,
    private api: ApiService
  ) {
    this.code = route.snapshot.queryParamMap.get('code');
  }

  ngOnInit() {
    this.subs.push(
      this.api.newResetPasswordCheck(this.code).subscribe({
        next: (r: { data: any, errors: string[], status: boolean }) => {
          if (r.status == false) {
            this.resetPasswordCodeIsExpired = true;
            this.hasResponseErrors = true;
            this.responseErrors = r.errors.map(e => this.t.translate(e));
          }
        }
      })
    );
  }

  sendChangePassword() {
    this.checkNewPass();
    this.checkConfirmPass();

    if (!this.hasError) {
      this.hasResponseErrors = false;
      this.responseErrors = [];
      this.subs.push(
        this.api.newResetPassword(this.code, this.newPassword).subscribe({
          next: (r: { data: any, errors: string[], status: boolean }) => {
            if (r.data === true && r.status === true) {
              this.allWentGood = true;
            } else {
              this.hasResponseErrors = true;
              this.responseErrors = r.errors;
            }
          }
        })
      );
    }
  }

  checkNewPass() {
    this.newPasswordError = '';
    if (this.newPassword.trim() == '') {
      this.newPasswordError = this.t.translate('New Password cannot be blank');
      return;
    }
    if (this.newPassword.trim().length < 6) {
      this.newPasswordError = this.t.translate('New Password is too short (minimum is 6 characters)');
      return;
    }
    if (this.newPassword.trim() != this.newPasswordConfirm.trim()) {
      this.newPasswordError = this.t.translate('Passwords should match');
      return;
    }
  }

  checkConfirmPass() {
    this.newPasswordConfirmError = '';
    if (this.newPasswordConfirm.trim() == '') {
      this.newPasswordConfirmError = this.t.translate('Confirm Password cannot be blank');
      return;
    }
    if (this.newPasswordConfirm.trim().length < 6) {
      this.newPasswordConfirmError = this.t.translate('Confirm Password is too short (minimum is 6 characters)');
      return;
    }
  }

  checkPass() {
    this.checkNewPass();
    this.checkConfirmPass();
    this.hasError = this.newPasswordError != '' || this.newPasswordConfirmError != '';
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  formKeyUp($event) {
    if ($event.keyCode === 13) {
      this.sendChangePassword();
    }
  }

}
