import {Component, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {NgForOf, NgIf} from '@angular/common';
import {PipesModule} from '../../pipes/pipes.module';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {DirectivesModule} from '../../directives/directives.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NgSelectModule} from '@ng-select/ng-select';
import {PositionsService} from '../../services/positions.service';
import {BranchesService} from '../../services/branches.service';
import {UsersService} from '../../services/users.service';
import {IGQLBranchShort, IGQLIdNameTuple, IGQLUserPosition} from '@betterchains/bc-gql';

declare var hljs: any;

@Component({
  selector: 'wc-foh-sandbox-components',
  templateUrl: 'wc-foh-sandbox-components.component.html',
  styleUrls: ['wc-foh-sandbox-components.component.scss'],
  standalone: true,
  imports: [
    NgForOf,
    PipesModule,
    NgIf,
    AccordionModule,
    DirectivesModule,
    TooltipModule,
    NgSelectModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WcFohSandboxComponentsComponent {

  public positions: IGQLUserPosition[] = [];
  public branches: IGQLBranchShort[] = [];
  public roles: IGQLIdNameTuple[] = [
    {id: 1, name: 'Simple User'},
    {id: 3, name: 'Chain manager'},
    {id: 4, name: 'Branch manager'},
    {id: 5, name: 'Candidate'},
    {id: 6, name: 'Trainee'},
    {id: 7, name: 'Elevated Branch Manager'},
  ];
  public users: IGQLIdNameTuple[] = [];

  public components = [
    {
      tag: 'wc-foh-icon-button',
      html: '',
      attributes: [
        {a: 'icon', v: 'fa-light fa-image'},
        {a: 'icon-color', v: 'var(--foh-body-color)'},
        {a: 'icon-background', v: 'white'},
        {a: 'title', v: 'Title Here'},
        {a: 'title-color', v: 'white'},
        {a: 'title-background', v: 'var(--foh-color-main)'},
        {a: 'border-color', v: 'var(--foh-color-main)'},
        {a: 'round', v: 'false', o: ['', 'true', 'false']},
        {a: 'shadow', v: '', c: 'Falls back to default if empty. Use \'none\' to disable'},
        {a: 'counter', v: '7'},
        {a: 'navigate-to', v: 'https://google.com'},
        {a: 'navigate-target', v: '_self', o: ['', '_self', '_blank']},
        {a: 'show-for-positions', v: '', c: 'show-for-positions has precedence over Hide', n: 'positions'},
        {a: 'hide-for-positions', v: '', c: 'If show-for-positions is not empty - hide-for-positions is ignored', n: 'positions'},
        {a: 'show-for-branches', v: '', c: 'show-for-branches has precedence over Hide', n: 'branches'},
        {a: 'hide-for-branches', v: '', c: 'If show-for-branches is not empty - hide-for-branches is ignored', n: 'branches'},
        {a: 'show-for-roles', v: '', c: 'show-for-roles has precedence over Hide', n: 'roles'},
        {a: 'hide-for-roles', v: '', c: 'If show-for-roles is not empty - hide-for-roles is ignored', n: 'roles'},
        {a: 'show-for-users', v: '', c: 'show-for-users has precedence over Hide', n: 'users'},
        {a: 'hide-for-users', v: '', c: 'If show-for-users is not empty - hide-for-users is ignored', n: 'users'},
      ]
    },
    {
      tag: 'wc-foh-card',
      html: `
      <p>Sample paragraph in card</p>
      <p>Sample paragraph in card</p>
      <p>Sample paragraph in card</p>
      `,
      attributes: [
        {a: 'background', v: ''},
        {a: 'color', v: ''},
        {a: 'direction', v: 'column', o: ['column', 'row']},
        {a: 'shadow', v: '0 7px 12px 0 rgba(0, 0, 0, 0.08)'}
      ]
    },
    {
      tag: 'wc-foh-icon-card',
      attributes: [
        {a: 'href', v: ''},
        {a: 'counter', v: ''},
        {a: 'loading', v: '', o: ['', 'true', 'false']},
        {a: 'icon', v: 'fa-light fa-file'},
        {a: 'icon-color', v: ''},
        {a: 'icon-background-color', v: ''},
        {a: 'arrow-color', v: ''},
      ]
    }
  ];

  constructor(
    positionsSvc: PositionsService,
    branchesSvc: BranchesService,
    usersSvc: UsersService
  ) {
    branchesSvc.GetShort().then(r => this.branches = r);
    positionsSvc.GetShort().then(r => this.positions = r);
    usersSvc.GetShort().then(r => this.users = r);
  }

  mkHtml(c) {
    let res = `<${c.tag}`;
    c.attributes.forEach(a => {
      if (a.v.trim() != '') {
        res += `\n\t${a.a}="${a.v}"`;
      }
    });
    res += `>`;
    if (c.html && c.html.trim() != '') {
      res += c.html;
    }
    res += `</${c.tag}>`;
    return res;
  }

  mkHighlight(c) {
    return hljs.highlight(this.mkHtml(c), {language: 'xml'}).value;
  }

  attrValueChanged($event: Event, attr: any, n = null) {
    if (Array.isArray($event)) {
      switch (attr.n) {
        case 'branches':
          attr.v = $event.map(a => a.branch_id).join(',');
          break;
        case 'positions':
        case 'roles':
        case 'users':
          attr.v = $event.map(a => a.id).join(',');
          break;
      }
    } else {
      const t = $event.target as HTMLInputElement;
      attr.v = t.value;
    }
  }
}
