import {EventEmitter, Injectable} from '@angular/core';
import {LanguageService} from '../../../services/language.service';
import {environment} from '../../../../environments/environment';
import {ApiService} from '../../../services/api.service';
import {TranslocoService} from '@ngneat/transloco';
import {StateService} from '../../../services/state.service';
import {LoaderService} from '../../../services/loader.service';
import {Router} from '@angular/router';
import {IApiUser} from '../../../models/IApiUser';

@Injectable({providedIn: 'root'})
export class UserLoginService {

  chainBeenSet: EventEmitter<void> = new EventEmitter<void>();
  defaultLogo = 'https://d1mcyggarp14he.cloudfront.net/img/touch/bc-logo-144.png';
  logo = this.defaultLogo;

  chains: any[] = [];
  branches: any[] = [];

  user = null;

  chainId: number;
  currentChainId = null;
  selectedChainId = null;

  branchId: number;
  currentLanguage: string;

  loginCodeRequestedTo = '';

  constructor(
    private t: TranslocoService,
    private state: StateService,
    private router: Router,
    private loader: LoaderService,
    private api: ApiService,
    private language: LanguageService,
    private ls: LanguageService
  ) {
    this.currentChainId = null;
    this.selectedChainId = null;
    this.currentLanguage = this.ls.getLanguage();
    if (window.hasOwnProperty('BOHChainId')) {
      this.chainId = Number(window['BOHChainId']);
      this.currentChainId = Number(this.chainId);
    }
    if (environment.domainMode) {
      if (window.hasOwnProperty('NGFOHChainLogoUrl')) {
        this.logo = window['NGFOHChainLogoUrl'];
      } else {
        this.logo = this.defaultLogo;
      }
    } else {
      const logo = localStorage.getItem('chainLogo');
    }
  }

  getUser(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.api.getUser().subscribe({
        next: (r: IApiUser) => {
          this.user = r;
          resolve();
        },
        error: () => reject()
      });
    });
  }

  getEmailHint() {
    let _e = this.user.email;
    let __ee = _e.split('@');
    return __ee[0][0] + '***@' + __ee[1];
  }

  getPhoneHint() {
    let p = '';
    if (this.user.Applicant) {
      p = this.user.Applicant.phone;
    }
    if (this.user.applicant) {
      p = this.user.applicant.phone;
    }
    return '***' + p.substring(p.length - 4, p.length);
  }

  setLogo(logo) {
    if (logo !== null && logo !== undefined && typeof logo == 'string' && logo.trim() != '' && logo != 'undefined') {
      this.logo = logo;
    } else {
      this.logo = this.defaultLogo;
    }
  }

  setChain(chainId, codeVerified = false) {
    this.selectedChainId = null;
    if (chainId !== undefined) {
      this.loader.showLoader();
      // this.subs.push(
      this.api.setChain(chainId).subscribe((data: any) => {
          if (data.message !== undefined && data.code !== undefined && data.quick_login_url === undefined) {
            // this.sError = this.t.translate(data.message);
          } else {
            this.chainId = chainId;
            this.selectedChainId = chainId;
            this.state.setScheduleAvailabilityTab(data.schedule_availability_tab);
            this.state.setChain(data);

            const logo = localStorage.getItem('chainLogo');
            this.setLogo(logo);

            this.chainBeenSet.emit();

            if (!codeVerified) {
              if (data.hasOwnProperty('enable_2fa') && Number(data.enable_2fa) == 1) {
                this.loader.hideLoader();
                this.router.navigateByUrl('/user/login/code-prompt').then();
                return;
              }
            }

            if (data.branches && data.branches.length > 1) {
              this.loader.hideLoader();
              this.branches = data.branches;
              this.router.navigateByUrl('/user/login/select-branch').then();
            } else {
              const chain = this.state.getChain();
              this.language.setupLanguageFromSettings(chain, this.branches[0], this.user);
              if (environment.domainMode) {
                window.location.replace(data.quick_login_url + '&rememberMe=1');
              } else {
                this.router.navigateByUrl('/')
                  .then(() => {
                    this.loader.hideLoader();
                  });
              }
            }
          }
        },
        () => {
          this.loader.hideLoader();
          // this.sError = this.t.translate('Something went wrong, please try again later.');
        }
      );
      // );
    } else {

    }
  }

}
