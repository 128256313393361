import {Component, Injector, NgZone} from '@angular/core';
import {setTheme} from 'ngx-bootstrap/utils';
import {NavigationCancel, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {StateService} from './services/state.service';
import {LoaderService} from './services/loader.service';
import {LayoutService} from './layout/main/layout.service';
import {createCustomElement} from '@angular/elements';
import {FohIconCardComponent} from './components/foh/foh-icon-card.component';
import {FohCardComponent} from './components/foh/foh-card.component';
import {environment} from '../environments/environment';
import {Location} from '@angular/common';
import {PushNotificationService} from './services/push-notification.service';
import {version} from '../environments/version';
import {buildnumber} from '../environments/buildnumber';
import {TranslocoService} from '@ngneat/transloco';
import {SnackbarService} from './components/snackbar/snackbar.service';
import {injectExternalScriptFile} from './util/script-loader';
import {App, AppUrlOpen} from '@capacitor/app';
import {Network, NetworkStatus} from '@capacitor/network';
import {Keyboard} from '@capacitor/keyboard';
import {Capacitor} from '@capacitor/core';

declare let ga: any;

const testApiUrl = localStorage.getItem('test-apiUrl');
const testStaticUrl = localStorage.getItem('test-staticUrl');
if(testApiUrl && testStaticUrl) {
  environment.apiUrl = testApiUrl;
  environment.staticUrl = testStaticUrl;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'NG-FOH';
  currentUrl = '';
  backClickedOnce = false;
  isApp = environment.isApp;
  isLocalhost = environment.isLocalhost;

  constructor(
    public state: StateService,
    private loader: LoaderService,
    private layout: LayoutService,
    private ngZone: NgZone,
    private router: Router,
    private location: Location,
    private t: TranslocoService,
    private snack: SnackbarService,
    push: PushNotificationService,
    private injector: Injector
  ) {
    setTheme('bs3');
    this.state.applyCss();
    router.events.subscribe(e => {
      this.ngZone.run(() => {
        if (e instanceof NavigationCancel) {
          this.loader.stop();
        }
        if (e instanceof NavigationStart) {
          this.state.updateNotificationsCounter.emit();
          this.layout.menustate.emit(false);
          this.layout.showSearch.emit(false);
          this.loader.start();
        }
        if (e instanceof NavigationEnd) {
          if (
            (e.url.startsWith('/menu-planner') && !e.url.startsWith('/menu-planner/cook-view'))
            ||
            e.url.startsWith('/wc-foh-sandbox')
          ) {
            this.layout.fullWidth.emit(true);
          } else {
            this.layout.fullWidth.emit(false);
          }
          if (environment.production) {
            this.currentUrl = e.urlAfterRedirects;
            ga('set', 'page', this.currentUrl);
            ga('send', 'pageview');
          }
          this.loader.stop();
        }
      });
    });

    // Make sure customElements exist in window object (might not exist in ancient browsers)
    if (window.hasOwnProperty('customElements')) {
      customElements.define('wc-foh-icon-card', createCustomElement(FohIconCardComponent, {injector}));
      customElements.define('wc-foh-card', createCustomElement(FohCardComponent, {injector}));
    }

    push.init();

    Network.addListener('networkStatusChange', (status) => {
      this.ngZone.run(() => {
        if (status.connected && !this.state.connected) {
          this.ngZone.run(() => {
            this.state.connected = true;
            this.state.networkStateChanged.emit(this.state.connected);
          });
        } else if (!status.connected && this.state.connected) {
          this.ngZone.run(() => {
            this.state.connected = false;
            this.state.networkStateChanged.emit(this.state.connected);
          });
        }
      });
    });

    Network.getStatus()
      .then(
        (ns: NetworkStatus) => {
          this.ngZone.run(() => {
            this.state.connected = ns.connected;
            this.state.networkStateChanged.emit(this.state.connected);
          })
        }
      )
      .catch(
        y => console.log(y)
      )

    if (environment.isApp) {

      App.addListener('appUrlOpen', (data: AppUrlOpen) => {
        this.ngZone.run(() => {
          const a = document.createElement('a');
          a.href = data.url;
          this.router.navigateByUrl(a.pathname + a.search + a.hash);
        });
      });

      App.addListener('backButton', () => {
        this.ngZone.run(() => {
          if (this.currentUrl.toLowerCase() === '/dashboard') {
            if (!this.backClickedOnce) {
              this.snack.clear();
              this.snack.add({
                msg: this.t.translate('Click again to exit app'),
                timeout: 2000,
                action: {
                  text: this.t.translate('OK'),
                  onClick: () => {
                    App.exitApp();
                  }
                }
              });
              this.backClickedOnce = true;
              setTimeout(() => {
                this.ngZone.run(() => {
                  this.backClickedOnce = false;
                })
              }, 2000);
            } else {
              App.exitApp();
            }
          } else {
            this.location.back();
          }
        })
      })

      Keyboard.setAccessoryBarVisible({isVisible: true})
        .then(() => {/*console.log('Keyboard.setAccessoryBarVisible.then()')*/
        })
        .catch(() => {/*console.log("Keyboard.setAccessoryBarVisible error: " + JSON.stringify(e))*/
        })

      if (environment.production) {
        ga('set', 'hostname', 'ng-foh-app');
        ga('set', 'appName', 'NG-FOH APP');
        ga('set', 'appVersion', version + '.' + buildnumber);
      }
    } else {
      if (environment.production) {
        ga('set', 'appName', 'NG-FOH WEB');
        ga('set', 'appVersion', version + '.' + buildnumber);
      }
    }

    if (environment.isApp) {
      App.getInfo().then((info) => {
        this.state.platform = Capacitor.getPlatform();
        if (environment.isApp) {
          this.state.version = info.version;
        }
      });
    } else {
      this.state.version = version;
      this.state.platform = 'web';
    }

    injectExternalScriptFile(
      'wc-foh-loader', environment.staticUrl + '/js/wc-foh/dist/wc-foh/wc-foh.esm.js', true, () => {
      console.log('wc-foh loaded');
    });
  }
}
