import {EventEmitter, Injectable} from '@angular/core';
import {IApiUserMenuItem} from '../../models/IApiUserMenu';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public hideMainMenu = false;
  public headerFullWidth = false;

  public menustate: EventEmitter<boolean> = new EventEmitter<boolean>();
  public headerstate: EventEmitter<boolean> = new EventEmitter<boolean>();
  public showSearch: EventEmitter<boolean> = new EventEmitter<boolean>();
  public searchPlaceholder: EventEmitter<string> = new EventEmitter<string>();
  public showSearchFilters: EventEmitter<boolean> = new EventEmitter<boolean>();
  public closeSearch: EventEmitter<void> = new EventEmitter<void>();
  public searchClosed: EventEmitter<void> = new EventEmitter<void>();
  public searchTextChange: EventEmitter<{text:string, key:number}> = new EventEmitter<{text:string, key:number}>();
  public showBurger: EventEmitter<boolean> = new EventEmitter<boolean>();
  public pagetitle: EventEmitter<string> = new EventEmitter<string>();
  public showBack: EventEmitter<boolean> = new EventEmitter<boolean>();
  public backLink: EventEmitter<string> = new EventEmitter<string>();
  public showClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  public closeLink: EventEmitter<string> = new EventEmitter<string>();
  public menuLoaded: EventEmitter<IApiUserMenuItem[]> = new EventEmitter<IApiUserMenuItem[]>();
  public notificationsCount: EventEmitter<number> = new EventEmitter<number>();
  public searchLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  public dontShowMenuAtAll: EventEmitter<boolean> = new EventEmitter<boolean>();
  public fullWidth: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    this.fullWidth.subscribe((fullWidth: boolean) => {
      this.headerFullWidth = fullWidth;
    });
  }
}
