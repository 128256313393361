import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user-login-select-domain',
  templateUrl: './select-domain.component.html',
  styleUrls: ['./select-domain.component.scss']
})
export class SelectDomainComponent implements OnInit {

  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  cancel() {
    this.router.navigateByUrl('/user/login').then();
  }

  setDomain(api: string, cdn: string, env: string, production: boolean) {
    environment.apiUrl = api;
    environment.staticUrl = cdn;
    environment.production = production;
    if(!production) {
      localStorage.setItem('test-apiUrl', api)
      localStorage.setItem('test-staticUrl', cdn)
      localStorage.setItem('test-env', env)
    } else {
      localStorage.removeItem('test-apiUrl');
      localStorage.removeItem('test-staticUrl');
      localStorage.removeItem('test-env');
    }
    this.cancel();
  }

  testException() {
    throw new Error('This is test exception from NG-FOH')
  }
}
