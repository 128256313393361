<foh-container pageTitle="{{'Introduction Page'|transloco}}">
  <foh-content>
    <foh-card>
      <div id="introduction-page-view" *transloco="let t">
        <h2>{{'Welcome to Better Chains!'|transloco}}</h2>

        <br>

        <p class="lead">{{t('Hello {user_name}, welcome to the Better Chains online management system.', {user_name: firstName})}}</p>

        <p class="lead">
        <span
          [innerHTML]="'This simple mobile-friendly platform will allow you to connect to the materials needed to begin right away. You can access your account from any smartphone, tablet or computer. The first step is to create your electronic signature.'|transloco| safe:'html'"></span>&nbsp;
        </p>

        <h4 class="title">{{'Create Your Electronic Signature'|transloco}}</h4>

        <p class="lead">
          {{"In order to start using the system you need to create your digital signature. Please click the “Start” button to generate your signature sample. This sample will be used to digitally sign all documents."|transloco}}
        </p>

        <br>

        <div class="text-center">
          <button class="sign-button" (click)="clickChangeSignature()">{{'Start'|transloco}}</button>
        </div>

        <app-sign-pad-v4
          [isShowing]="showSignatureDialog"
          (signatureSubmitted)="signatureSubmitted()"
          (signatureSubmit)="signatureSubmit()"
          [transparency]="0"
          (dialogClose)="closeSignatureDialog()"></app-sign-pad-v4>
      </div>
    </foh-card>

    <div class="text-center">
      <button (click)="logout()" class="logout-button">{{"Log Out"|transloco}}</button>
    </div>

  </foh-content>
</foh-container>
