<div *ngFor="let s of data">
  <p>{{s.title}}</p>
  <div *ngFor="let co of s.colors">
    <span class="colorbox" [style]="{backgroundColor: co.v}"></span>
    &nbsp;
    <span>{{co.n}}</span>
    &nbsp;
    <span>({{co.v}})</span>
  </div>
</div>
