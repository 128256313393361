<h1>
  <a [routerLink]="'/wc-foh-sandbox'"><i class="fa-light fa-arrow-left"></i></a>
  Dashboard Widget Designer
  <a [routerLink]="'/wc-foh-sandbox'" href>
    <i class="fa-light fa-xmark-large"></i>
  </a>
</h1>
<main>

  <as-split direction="horizontal" gutterSize="8" unit="percent">
    <as-split-area [size]="50" [minSize]="25">
      <section #editor></section>
    </as-split-area>
    <as-split-area [size]="50" [minSize]="25">
      <iframe #iframe src="/dashboard" name="wc-foh-sandbox-preview-frame"></iframe>
    </as-split-area>
  </as-split>

</main>

<footer>
  <button class="btn btn-small btn-primary" (click)="resetToChainDefault()">Reset to chain default</button>
</footer>
