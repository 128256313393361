import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {ApiService} from '../../../../services/api.service';
import {Router} from '@angular/router';
import {TranslocoService} from '@ngneat/transloco';
import {LanguageService} from '../../../../services/language.service';
import {LoaderService} from '../../../../services/loader.service';
import {PushNotificationService} from '../../../../services/push-notification.service';
import {StateService} from '../../../../services/state.service';
import {UserLoginService} from '../user-login.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'foh-user-login-email-password',
  templateUrl: './email-password.component.html',
  styleUrls: ['email-password.component.scss']
})
export class UserLoginEmailPasswordComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];

  username = '';
  password = '';
  sError = '';
  sEmailError = '';
  sPasswordError = '';
  showPasswordStatus = false;
  LANGUAGE_HE_IL = 'he';
  isApp = environment.isApp;

  constructor(
    public userLoginService: UserLoginService,
    private api: ApiService,
    private router: Router,
    private t: TranslocoService,
    private ls: LanguageService,
    private loader: LoaderService,
    private push: PushNotificationService,
    private ngZone: NgZone,
    private language: LanguageService,
    private state: StateService
  ) {
    this.showPasswordStatus = false;
  }

  clearLoginErrors() {
    this.sError = '';
    this.sEmailError = '';
    this.sPasswordError = '';
  }


  login() {
    this.loader.showLoader();

    this.clearLoginErrors();

    if (typeof this.username !== 'undefined'
      &&
      typeof this.password !== 'undefined'
      &&
      this.username.length > 0
      &&
      this.username.trim().length > 0
      &&
      this.password.length > 0
      &&
      this.password.trim().length > 0
    ) {
      this.subs.push(
        this.api.login(this.username.trim(), this.password.trim())
          .subscribe({
              next: (data: any) => this.loginNext(data),
              error: () => {
                this.loader.hideLoader();
                this.sError = this.t.translate('Something went wrong, please try again later.');
              }
            }
          )
      );
    } else {
      this.loader.hideLoader();

      if (typeof this.username === 'undefined' || this.username.length <= 0) {
        this.sEmailError = this.t.translate('Email field cannot be blank.');
      }

      if (typeof this.password === 'undefined' || this.password.length <= 0) {
        this.sPasswordError = this.t.translate('Password cannot be blank.');
      }
    }
  }

  loginNext(data: any) {
    if (data.message !== undefined && data.code !== undefined) {
      this.loader.hideLoader();
      this.sError = this.t.translate(data.message);
    } else {
      if (environment.isApp) {
        this.push.register().then(() => console.log('PUSH Registered'));
      }
      if (data.css) {
        this.state.setCss(data.css);
      }
      if (data.logo) {
      }
      this.userLoginService.chains = data.chains;
      this.userLoginService.branches = data.branches;
      this.userLoginService.user = data.user;
      this.state.clear();
      this.state.setApiToken(data.token);
      this.state.setTimeZone(data.timeZone);
      this.state.setTimeZoneId(data.timeZoneId);
      this.state.setScheduleSettings(data.scheduleSettings);
      if (data.chains && data.chains.length == 0 && data.user.chain_id == 0) {
        this.userLoginService.setChain(0, true);
      } else if (data.chains && data.chains.length > 1) {
        this.loader.hideLoader();
        this.router.navigateByUrl('/user/login/select-chain').then();
        return;
      } else {
        this.userLoginService.chainId = Number(data.user.chain_id);
        this.userLoginService.selectedChainId = Number(data.user.chain_id);
        const ch = data.chains[0];
        this.state.setScheduleAvailabilityTab(ch.schedule_availability_tab);
        this.userLoginService.setChain(ch.chain_id);
      }
    }
  }

  formKeyUp($event) {
    if ($event.keyCode === 13) {
      this.login();
    }
  }

  showPassword() {
    this.showPasswordStatus = !this.showPasswordStatus;
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }


}
