<div class="modal fade inmodal-window-class" bsModal
     tabindex="-1" role="dialog" aria-labelledby="dialog-events-name"
     (onShown)="onShownCallBack()"
     (onShow)="onShowCallBack()"
     (onHidden)="onClosedModal()"
     >
  <div class="modal-dialog">
    <div class="modal-content">

      <div #canvasWrapper class="modal-dialog-wrapper" [dir]="isRtl ? 'rtl' : 'ltr'">
        <div class="modal-header" [ngClass]=" drawMode && !showESignAct ? 'row-reverse' : ''">
          <div class="arrow-holder" *ngIf="!drawMode || showESignAct">
            <img class="back-to-draw" *ngIf="!isRtl" src="{{deployUrl}}/assets/menu.v3/arrow-backward.svg" alt="Back" (click)="changeMode()">
            <img class="back-to-draw" *ngIf="isRtl" src="{{deployUrl}}/assets/menu.v3/arrow-forward.svg" alt="Back" (click)="changeMode()">
          </div>
          <h4 class="modal-title modal-dialog-title"
              [ngStyle]="{'width' : drawMode && !showESignAct ? '100%' : '93%'}"
          >{{'My Signature' | transloco}}</h4>
         <button type="button"
                 [hidden]="!drawMode || showESignAct"
                 class="close modal-dialog-close-button"
                 data-dismiss="modal"
                 aria-label="Close">
            <span aria-hidden="true" (click)="closeModal()">×</span>
          </button>
        </div>
        <div class="modal-body modal-dialog-content">
          <div [hidden]="!drawMode || showESignAct" >
            <div class="draw-content">
              <div class="type-label" *ngIf="isRtl">
                {{"Click"|transloco}}&nbsp;<a href="javascript:void(0)" (click)="changeMode()">{{"to type your name instead"|transloco}}</a>
              </div>
              <div class="type-label" *ngIf="!isRtl">
                {{"Click to"|transloco}}&nbsp;<a href="javascript:void(0)" (click)="changeMode()">{{"type your name instead"|transloco}}</a>
              </div>
            </div>
          </div>
          <div [hidden]="drawMode || showESignAct">
            <div class="type-content">
              <div class="type-label">{{"Full name"|transloco}}</div>
              <input class="type-input"
                     type="text"
                     id="type-sign"
                     #typeInput
                     [value]="textValue"
                     placeholder="{{'Full name Placeholder'|transloco}}"
                     (keyup)="textChanged($event)"
              />
            </div>
          </div>
          <div id="main-dialog-content" [hidden]="!(drawMode && !showESignAct)">
            <div class="clear-signature-icon"
                 [ngClass]="isRtl ? 'clear-signature-icon-rtl' : 'clear-signature-icon-ltr'"
                 *ngIf="!isEmptySignature"
                 (click)="clearSignature()"
            ></div>
            <div class="tap-icon"
                 [@openClose]="tapIsVisible ? 'open' : 'closed'"

            ></div>
            <canvas [width]="canvasWidth" [height]="canvasHeight"
                    id="cnvs"
                    style="background-color: transparent;"
                    [ngClass]="drawMode && !showESignAct ? DRAW_MODE_CANVAS_CLASS : TYPE_MODE_CANVAS_CLASS"></canvas>
          </div>
          <div class="terms-block-container" [hidden]="showESignAct || hiddenTerms !== false">
              <!--type-->
                  <label class="terms-block">
                    <input class="terms-checkbox" type="checkbox" [value]="agreeOnTerms" (change)="chAgree($event)">
                    <span class="checkmark"></span>
                    <span class="terms-text"
                          [ngStyle]="{'margin-left' : !isRtl ? '34px' : 'auto', 'margin-right' : isRtl ? '34px' : 'auto'}">
                      {{"I agree to the term of 1"|transloco}}
                      <a href="javascript:void(0)"
                         (click)="changeShowTermsInfo()">{{"E-Sign Act 1"|transloco}}</a>
                    </span>
                  </label>
              <!--type-->
          </div>

          <div [hidden]="!showESignAct">
            <!--terms text-->
            <div class="term-rules">
                Before signing, please be aware of your rights under the Electronic Signatures in Global and National Commerce
                (E-Sign) Act, specifically that: Your electronic signature will only ever be applied
                to
                the document you have just reviewed.
                <br>
                You have the right to submit this document with your signature in a paper format if you wish.
                <br>
                <br>
                PLEASE NOTE THAT YOUR ELECTRONIC SIGNATURE IS LEGALLY BINDING UNDER FEDERAL LAW IN THE
                PLACE OF ANY LEGALLY REQUIRED PHYSICAL SIGNATURE, AND CANNOT BE DENIED LEGAL EFFECT OR ENFORCEABILITY SOLELY
                BECAUSE
                IT IS IN ELECTRONIC FORMAT.
            </div>
            <!--terms text-->
          </div>
          <!--terms-->
        </div>
        <div class="modal-footer modal-dialog-footer">
          <ng-container>
            <button  *ngIf="!showESignAct"
                     (click)="submitSign()"
                     [disabled]="isEmptySignature === true"
                     class="modal-dialog-button"
            >{{'Submit'|transloco}}</button>
            <button  *ngIf="showESignAct" (click)="showESignAct = !showESignAct" class="modal-dialog-button">{{'Close'|transloco}}</button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
