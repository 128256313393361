import {EventEmitter, Injectable, NgZone} from '@angular/core';
import {environment} from '../../environments/environment';
import {TranslocoService} from '@ngneat/transloco';
import {PlatformLocation} from '@angular/common';
import {FormService} from './form.service';
import {CryptoService} from './crypto.service';
import {BarcodeScanner, ScanResult} from '@capacitor-community/barcode-scanner';

@Injectable({
  providedIn: 'root'
})
export class QrService {

  public started: EventEmitter<void> = new EventEmitter<void>();
  public completed: EventEmitter<any> = new EventEmitter<any>();
  public cancelled: EventEmitter<void> = new EventEmitter<void>();
  public flash: EventEmitter<void> = new EventEmitter<void>();
  public swap: EventEmitter<void> = new EventEmitter<void>();
  private statePushed = false;

  private appMain: HTMLElement;

  constructor(
    private ngZone: NgZone,
    private location: PlatformLocation,
    private form: FormService,
    private crypto: CryptoService,
    private t: TranslocoService
  ) {
    this.location.onPopState(() => {
      this.ngZone.run(() => {
        if (this.statePushed) {
          this.statePushed = false;
          this.cancelled.emit();
        }
      });
    });
    this.completed.subscribe(data => {
      this.ngZone.run(() => {
        if (Array.isArray(data)) {
          const qdata = data.map(d => {
            d.type = 'QR';
            return d;
          });
          this.form.push(qdata);
        } else {
          data.type = 'QR';
          this.form.push(data);
        }
        this.destroy().then(() => console.log('QR Scan Closed'));
      });
    });
    this.cancelled.subscribe(() => {
      this.ngZone.run(() => {
        this.destroy().then(() => console.log('QR Scan Closed'));
      });
    });
  }

  async destroy() {
    await BarcodeScanner.showBackground();
    await BarcodeScanner.stopScan();
    this.hide();
  }

  async startScan() {
    this.appMain = document.getElementsByTagName('app-main')[0] as HTMLElement;
    if (!this.appMain) {
      return;
    }
    BarcodeScanner.checkPermission({force: true})
      .then(r => {
        if (r.granted) {
          BarcodeScanner.hideBackground()
            .then(() => {
              this.started.emit();
              this.ngZone.run(() => {
                this.show();
              });
              BarcodeScanner.startScan()
                .then((result: ScanResult) => {
                  if (result.hasContent) {
                    // console.log(result.content); // log the raw scanned content
                    // alert("SCANNED:\n"+result.content)
                    const data = this.crypto.decrypt(result.content);
                    if (typeof data === 'object') {
                      this.completed.emit(data);
                    } else {
                      this.cancelled.emit();
                    }
                  } else {
                    alert('QR Has no content');
                  }
                })
                .catch(() => alert('QR Scan execution failure'));
            });
        } else {
          alert(this.t.translate('Access to the camera has been prohibited; please enable it in the Settings app to continue'));
        }
      })
      .catch(() => {
        alert(this.t.translate('Access to the camera has been prohibited; please enable it in the Settings app to continue'));
      });
    if (environment.isLocalhost && !environment.isApp) {
      this.started.emit();
      this.show();
    }
  }

  show() {
    this.statePushed = true;
    this.location.pushState('QRSCAN','QRSCAN','QRSCAN');
    this.appMain.style.display = 'none';
    document.body.style.backgroundColor = 'transparent';
    document.documentElement.style.backgroundColor = 'transparent';
  }

  hide() {
    this.appMain.style.display = '';
    document.body.style.backgroundColor = 'var(--foh-body-bg-color)';
    document.documentElement.style.backgroundColor = 'var(--foh-body-bg-color)';
  }
}
