<div class="modal-dialog-wrapper">
  <div class="modal-header">
    <button type="button" class="close modal-dialog-close-button" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true" (click)="closeDialogTop()">×</span>
    </button>
    <br>
    <h4 class="modal-title modal-dialog-title">{{title| transloco}}</h4>
  </div>
  <div class="modal-body modal-dialog-content" *ngIf="message || showSuccessIcon">
    <div class="modal-icon-holder" *ngIf="showSuccessIcon">
      <div class="modal-icon-container"></div>
    </div>
    <span class="message-container">{{message| transloco}}</span>
  </div>
  <div class="modal-footer modal-dialog-footer" [ngStyle]="additionalStyle">
    <button class="modal-dialog-button" *ngIf="okText && okBeforeClose" (click)="okEvent()">{{okText|transloco}}</button>
    <button class="modal-dialog-button"
            *ngIf="additionalButton && additionalText"
            (click)="additionalEvent()"
            [ngClass]="additionalButtonAddClass ? additionalButtonAddClass : ''"
    >{{additionalText|transloco}}</button>
    <button class="modal-dialog-button modal-secondary-button" *ngIf="closeText && showClose" (click)="closeDialog()">{{closeText|transloco}}</button>
  </div>
</div>
