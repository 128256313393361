import {Component, OnDestroy} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {UserLoginService} from '../user-login.service';
import {BcGqlService} from '@betterchains/bc-gql';

@Component({
  selector: 'foh-user-login-code-prompt',
  templateUrl: 'login-code-prompt.component.html',
  styleUrls: ['login-code-prompt.component.scss']
})
export class LoginCodePromptComponent implements OnDestroy {

  subs: Subscription[] = [];

  sendType: string = 'sms';

  hasError = false;
  errorMessage = '';

  emailHint = '';
  phoneHint = '';

  constructor(
    private userLoginService: UserLoginService,
    private api: ApiService,
    private gql: BcGqlService,
    private router: Router
  ) {
    if (!this.userLoginService.user) {
      this.router.navigateByUrl('/user/login', {replaceUrl: true}).then();
    }
    this.userLoginService.getUser().then(() => {
      this.emailHint = this.userLoginService.getEmailHint();
      this.phoneHint = this.userLoginService.getPhoneHint();
    });
  }

  requestCode() {
    this.userLoginService.loginCodeRequestedTo = this.sendType;
    this.subs.push(
      this.api.requestLoginCode(this.sendType).subscribe({
        next: () => {
          this.gql.trackUserAction('2FA code sent successfully', true, 0, this.sendType)
            .then();
          this.router.navigateByUrl('/user/login/verify-code').then();
        },
        error: (e: HttpErrorResponse) => {
          this.gql.trackUserAction('2FA code send failed', false, 0, e.message)
            .then();
          this.hasError = true;
          this.errorMessage = e.message;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
