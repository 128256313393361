import {NgModule} from '@angular/core';
import {SuperHtmlDirective} from './superhtml-directive';
import {SwiperDirective} from "./swiper.directive";

@NgModule({
  declarations: [
    SuperHtmlDirective,
    SwiperDirective
  ],
  exports: [
    SuperHtmlDirective,
    SwiperDirective
  ]
})
export class DirectivesModule {

}
