import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {StateService} from '../services/state.service';
import {CookieService} from '../services/cookie.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PagesApiService {

  constructor(
    private state: StateService,
    private cookie: CookieService,
    private http: HttpClient
  ) {
  }


  public checkAccess(userId: number | string, actionString: string) {
    return this.http.post(
      environment.apiUrl + '/api/acl/checkAccess?user_id=' + userId + '&action=' + actionString
      + '&token=' + this.state.getApiToken(),
      {
        user_id: userId,
        action: actionString
      }
    );
  }

  public getUrl(action: string) {
    return this.http.post(
      environment.apiUrl + '/api/user/getLoginByCodeLink' + '?token=' + this.state.getApiToken(),
      {
        url: action
      }
    );
  }
}
