import {Component, OnDestroy, OnInit} from '@angular/core';
import {QrService} from '../../services/qr.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {StatusBar} from '@capacitor/status-bar';

@Component({
  selector: 'app-qr-scan-overlay',
  templateUrl: './qr-scan-overlay.component.html',
  styleUrls: ['./qr-scan-overlay.component.scss']
})
export class QrScanOverlayComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];
  show = false;
  isLocalhost = environment.isLocalhost;

  constructor(
    private qr: QrService
  ) {
    this.subs.push(this.qr.started.subscribe(() => {
      if (environment.isApp) {
        StatusBar.hide().then(r => r);
      }
      this.show = true;
    }));
    this.subs.push(this.qr.completed.subscribe(() => {
      this.show = false;
    }));
    this.subs.push(this.qr.cancelled.subscribe(() => {
      this.show = false;
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  flash() {
    this.qr.flash.emit();
  }

  cancel() {
    if (environment.isApp) {
      StatusBar.show().then(r => r);
    }
    this.show = false;
    this.qr.cancelled.emit();
  }

  swap() {
    this.qr.swap.emit();
  }

  debug() {
    const data = [
      {
        'fid': 6810,
        'fields': {
          '0': 'TEST TEXT FROM QR',
          '1': 10
        }
      },
      {
        'fid': 32,
        'fields': {
          '0': 111222333,
          '1': 'some@email.com'
        }
      }
    ];
    // const data = {
    //   fid: 32,
    //   fields: {
    //     1: 'some@email.com',
    //     2: 2,
    //     14: 'Branch 3'
    //   }
    // };
    this.qr.completed.emit(data);
  }
}


