import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';

export * from './snackbar.service';

import {SnackbarService} from './snackbar.service';
import {SnackComponent} from './snack.component';
import {SnackbarComponent} from './snackbar.component';
import {PipesModule} from "../../pipes/pipes.module";

@NgModule({
  imports: [CommonModule, PipesModule],
  declarations: [SnackbarComponent, SnackComponent],
  exports: [SnackbarComponent, SnackComponent],
  providers: [SnackbarService]
})
export class SnackbarModule {
  static forRoot(): ModuleWithProviders<SnackbarModule> {
    return {
      ngModule: SnackbarModule,
      providers: [SnackbarService]
    };
  }
}
