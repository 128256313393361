<foh-container backLink="/dashboard" pageTitle="{{'User Profile'|transloco}}">
  <foh-content>

    <div id="user-profile-view">

      <foh-user-profile-avatar [avatarUrl]="avatarUrl"></foh-user-profile-avatar>

      <foh-card>
        <div class="form-horizontal">
          <div class="form-group">
            <label class="col-sm-3 control-label">
              {{'Interface Language' | transloco}}
            </label>
            <div class="col-sm-9" style="display: flex">
              <select style="min-width: 150px" class="form-control" (change)="chlang($event)" [value]="currentLanguage">
                <option value="en">English</option>
<!--                <option value="es">Español</option>-->
                <option value="he">עברית</option>
                <option value="ro">Română</option>
                <option value="ru">Русский</option>
                <option value="fr">Française</option>
                <option value="es">Español</option>
              </select>
              &nbsp;
              <button class="btn btn-primary" (click)="setlang()">{{'Update'|transloco}}</button>
            </div>
          </div>
        </div>
      </foh-card>

      <foh-card>
        <form class="form-horizontal" id="user-change-password-form">
          <div class="form-group">
            <label class="col-sm-3 control-label required" for="Users_old_password">
              {{'Old Password' | transloco}} <span class="required">*</span>
            </label>
            <div class="col-sm-9">
              <input class="form-control"
                     placeholder="{{'Type current password'|transloco}}"
                     autocomplete="new-password"
                     id="Users_old_password"
                     type="password"
                     [value]="oldPassword"
                     (keyup)="oldPassword = $event.target.value">
              <div class="errorMessage" id="Users_old_password_em_" *ngIf="pwdErrors.old_password">
                <div *ngFor="let error of pwdErrors.old_password">{{error}}</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-3 control-label required" for="Users_new_password">
              {{'New Password'|transloco}} <span class="required">*</span></label>
            <div class="col-sm-9">
              <input class="form-control"
                     placeholder="{{'Type new password'|transloco}}"
                     autocomplete="off"
                     id="Users_new_password"
                     (keyup)="newPassword = $event.target.value"
                     [value]="newPassword"
                     type="password">
              <div class="errorMessage" id="Users_new_password_em_" *ngIf="pwdErrors.new_password">
                <div *ngFor="let error of pwdErrors.new_password">{{error}}</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-3 control-label required" for="Users_confirm_password">
              {{'Confirm Password'|transloco}} <span class="required">*</span>
            </label>
            <div class="col-sm-9">
              <input class="form-control"
                     placeholder="{{'Type new password again'|transloco}}"
                     autocomplete="off"
                     id="Users_confirm_password"
                     (keyup)="confirmPassword = $event.target.value"
                     [value]="confirmPassword"
                     type="password">
              <div class="errorMessage"
                   *ngIf="newPassword != '' && confirmPassword != '' && newPassword != confirmPassword">
                {{'Passwords should match'|transloco}}
              </div>
              <div class="errorMessage" id="Users_confirm_password_em_" *ngIf="pwdErrors.confirm_password">
                <div *ngFor="let error of pwdErrors.confirm_password">{{error}}</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-3 control-label"></label>
            <div class="col-sm-9">
              <foh-password-meter [password]="newPassword"></foh-password-meter>
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-offset-2 col-sm-10 text-right">
              <button type="submit" class="btn btn-primary"
                      [disabled]="oldPassword == '' || newPassword == '' || confirmPassword == '' || newPassword !== confirmPassword"
                      (click)="sendChangePassword()">
                {{'Change Password'|transloco}}
              </button>
            </div>
          </div>

        </form>
      </foh-card>

      <foh-card>
        <form class="form-horizontal" id="user-change-signature">
          <div class="form-group" *ngIf="signPicture">
            <label class="col-sm-3 control-label">{{'Signature'|transloco}}</label>
            <div class="col-sm-9">
              <img src="{{ signPicture }}?t={{timestamp}}" alt="Signature" style="max-width: 100%">
            </div>
          </div>
          <div class="form-group" *ngIf="allowChangeSignature">
            <div class="col-sm-offset-2 col-sm-10 text-right">
              <div class="btn btn-primary" id="user-sign"
                   (click)="clickChangeSignature()"> {{'Change Signature'|transloco}}</div>
            </div>
          </div>
        </form>
      </foh-card>

      <app-sign-pad-v4 [isShowing]="showSignatureDialog"
                       (signatureSubmitted)="signatureSubmitted()"
                       [transparency]="0"
                       (dialogClose)="closeSignatureDialog()"></app-sign-pad-v4>

      <foh-card>
        <form class="form-horizontal" id="user-profile-form" *ngIf="user">
          <div class="form-group">
            <label class="col-sm-2 control-label">{{'First Name' | transloco}}</label>
            <div class="col-sm-10">
              <p class="form-control-static">{{user.first_name}}</p>
              <div class="errorMessage" id="Users_first_name_em_" style="display:none"></div>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-2 control-label">{{'Last Name' | transloco}}</label>
            <div class="col-sm-10">
              <p class="form-control-static">{{user.last_name}}</p>
              <div class="errorMessage" id="Users_last_name_em_" style="display:none"></div>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-2 control-label">
              {{'Employee Main Position' | transloco}}
            </label>

            <div class="col-sm-10">
              <h4><span class="label label-success">{{user.user_position_name}}</span></h4>
              <div class="errorMessage" id="Positions_name_em_" style="display:none"></div>
            </div>
          </div>

          <div class="form-group" *ngIf="user.additional_positions.length > 0">
            <label class="col-sm-2 control-label">
              {{'Additional Position' | transloco}}
            </label>

            <div class="col-sm-10" id="user-profile-additional-positions">
        <span *ngFor="let pos of user.additional_positions" class="label label-primary">
          {{pos.position_name}}
        </span>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-2 control-label required" for="Users_email">
              {{'Email' | transloco}} <span class="required">*</span>
            </label>
            <div class="col-sm-10">
                <input  class="form-control" placeholder="Email" name="Users[email]" id="Users_email" type="email"
                        [(ngModel)]="user.email" />
              <div class="errorMessage" id="Users_email_em_" *ngIf="userUpdEmlPhone.email">
                <div *ngFor="let error of userUpdEmlPhone.email">{{error}}</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-2 control-label" for="Users_phone">
              {{'Phone' | transloco}}
              <span class="required">*</span>
            </label>
            <div class="col-sm-10">
              <bdo>
                <input [dir]="'ltr'"
                       class="form-control"
                       [ngStyle]="{'text-align' : isRtl ? 'right' : 'left' }"
                       placeholder="Phone"
                       name="Users[phone]"
                       id="Users_phone"
                       type="text"
                       [(ngModel)]="user.phone">
              </bdo>
              <div class="errorMessage" id="Users_phone_em_" *ngIf="userUpdEmlPhone.phone">
                <div *ngFor="let error of userUpdEmlPhone.phone">{{error}}</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-offset-2 col-sm-10 text-right">
              <button type="submit" class="btn btn-primary" (click)="clickUpdateEmailAndPhone()">
                {{'Update' | transloco}}
              </button>
            </div>
          </div>
        </form>
      </foh-card>
    </div>

  </foh-content>
</foh-container>
