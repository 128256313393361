import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {CookieService} from "./cookie.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private cookie: CookieService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if(this.cookie.check('DB57')) {
    //   request = request.clone({
    //     headers: new HttpHeaders({
    //       'DB57': 'yes'
    //     })
    //   });
    // }
    return next.handle(request);
  }

}
