import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FullPageLoaderComponent} from './full-page-loader/full-page-loader.component';
import {SignPadComponent} from './sign-pad/sign-pad.component';
import {LineLoaderComponent} from './line-loader/line-loader.component';
import {TranslocoModule} from '@ngneat/transloco';
import {IconWithCounterComponent} from './icon-with-counter/icon-with-counter.component';
import {PipesModule} from '../pipes/pipes.module';
import {FohCardComponent} from './foh/foh-card.component';
import {FohContainerComponent} from './foh/foh-container.component';
import {FohContentComponent} from './foh/foh-content.component';
import {FohIconCardComponent} from './foh/foh-icon-card.component';
import {FohBottomDrawerComponent} from './foh/foh-bottom-drawer.component';
import {FohDrawerActionComponent} from './foh/foh-drawer-action.component';
import {FohDashedProgressComponent} from './foh/foh-dashed-progress.component';
import {DirectivesModule} from '../directives/directives.module';
import {ModalModule} from 'ngx-bootstrap/modal';
import {QrScanOverlayComponent} from './qr-scan-overlay/qr-scan-overlay.component';
import {FohPasswordMeterComponent} from './foh-password-meter/foh-password-meter.component';
import {FohActionsBarComponent} from './foh/foh-actions-bar/foh-actions-bar.component';
import {FohActionsBarButtonComponent} from './foh/foh-actions-bar-button/foh-actions-bar-button.component';
import {SearchResultsComponent} from './search-results/search-results.component';
import {PlainDialogComponent} from './plain-dialog/plain-dialog.component';
import {SignaturepadModule} from '../../../projects/forms-render/src/lib/signaturepad/signaturepad.module';

@NgModule({
  declarations: [
    FullPageLoaderComponent,
    SignPadComponent,
    LineLoaderComponent,
    IconWithCounterComponent,
    FohCardComponent,
    FohIconCardComponent,
    FohContainerComponent,
    FohContentComponent,
    FohBottomDrawerComponent,
    FohDrawerActionComponent,
    FohDashedProgressComponent,
    QrScanOverlayComponent,
    FohPasswordMeterComponent,
    FohActionsBarComponent,
    FohActionsBarButtonComponent,
    SearchResultsComponent,
    PlainDialogComponent
  ],
  imports: [
    SignaturepadModule,
    CommonModule,
    TranslocoModule,
    PipesModule,
    DirectivesModule,
    ModalModule.forRoot(),
  ],
  exports: [
    FullPageLoaderComponent,
    LineLoaderComponent,
    SignPadComponent,
    IconWithCounterComponent,
    FohCardComponent,
    FohContainerComponent,
    FohContentComponent,
    FohIconCardComponent,
    FohBottomDrawerComponent,
    FohDrawerActionComponent,
    FohDashedProgressComponent,
    QrScanOverlayComponent,
    FohPasswordMeterComponent,
    FohActionsBarComponent,
    FohActionsBarButtonComponent,
    SearchResultsComponent
  ]
})
export class ComponentsModule {
}

