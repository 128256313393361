import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MainComponent} from './main.component';
import {RouterModule} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TranslocoModule} from '@ngneat/transloco';
import {HeaderComponent} from './header/header.component';
import {MenuComponent} from './menu/menu.component';

@NgModule({
  declarations: [
    MainComponent,
    HeaderComponent,
    MenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BsDropdownModule,
    TranslocoModule,
    NgOptimizedImage,
  ],
  providers: [
    ApiService
  ],
  exports: [MainComponent]
})
export class MainModule {
}
