import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public onDisplayLoaderChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public onLineLoaderChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  public showLoader() {
    this.onDisplayLoaderChange.emit(true);
  }

  public hideLoader() {
    this.onDisplayLoaderChange.emit(false);
  }

  public start() {
    this.onLineLoaderChange.emit(true);
  }

  public stop() {
    this.onLineLoaderChange.emit(false);
  }
}
