import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit
} from '@angular/core';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-line-loader',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './line-loader.component.html',
  styleUrls: ['./line-loader.component.scss']
})
export class LineLoaderComponent implements OnInit, AfterViewInit {

  isTransition = 'none';

  private pProgress = 0;

  @Input() set progress(progress: number) {
    this.isTransition = progress >= this.pProgress ? 'all 0.5s ease-in-out' : 'none';
    this.pProgress = progress;
    this.cdRef.detectChanges();
  }

  get progress() {
    return this.pProgress;
  }

  @Input() color = 'firebrick';
  @Input() height = '3px';
  @Input() show = false;

  private intervalCounterId;
  private interval = 100;

  constructor(
    public service: LoaderService,
    private elmRef: ElementRef,
    private ngZone: NgZone,
    private cdRef: ChangeDetectorRef
  ) {
    this.elmRef.nativeElement.visible = false;
  }

  ngOnInit(): void {
  }

  start() {
    this.elmRef.nativeElement.visible = true;
    // Make it visible for sure
    this.show = true;
    // Run the timer with milliseconds iterval
    this.intervalCounterId = setInterval(() => {
      this.ngZone.run(() => {
        // Increment the progress and update view component
        this.progress += 2;
        this.cdRef.detectChanges();
        // If the progress is 100% - call complete
        if (this.progress === 100) {
          this.stop();
        }
      })
    }, this.interval);
  }

  stop() {
    this.elmRef.nativeElement.visible = false;
    this.progress = 0;
    this.show = false;
    clearInterval(this.intervalCounterId);
    this.intervalCounterId = null;
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.stop();
    this.service.onLineLoaderChange.subscribe((event: boolean) => {
      if (event === false) {
        this.stop();
      } else {
        this.start();
      }
    });
  }
}
