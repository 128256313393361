import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {BcGqlService, IGQLBranchShort, IGQLResponse} from '@betterchains/bc-gql';

@Injectable({
  providedIn: 'root'
})
export class BranchesService {
  constructor(
    private gql: BcGqlService
  ) {
  }

  public GetShort(): Promise<IGQLBranchShort[]> {
    return new Promise<IGQLBranchShort[]>((resolve, reject) => {
      firstValueFrom(this.gql.query(
        //language=gql
        `
          {
            system {
              branches(limit: 0, sort: "name asc") {
                branch_id, name
              }
            }
          }
        `
      )).then((r: HttpResponse<IGQLResponse<{ system: { branches: IGQLBranchShort[] } }>>) => resolve(r.body.data.system.branches))
        .catch(r => reject(r));
    });
  }

}
