<h1>{{'Reset Password'|transloco}}</h1>
<form class="form-wrapper">

  <ng-container *ngIf="allWentGood">

    <div class="alert alert-success">
      <h3>{{'Your password has been successfully changed'|transloco}}</h3>
    </div>

    <div class="wrapper">
      <button type="button" class="submit-button" routerLink="/user/login">
        {{ 'Proceed to login' | transloco }}
      </button>
    </div>

  </ng-container>

  <ng-container *ngIf="!allWentGood">

    <div class="alert alert-danger" *ngIf="hasResponseErrors">
      <ul>
        <li *ngFor="let e of responseErrors">{{e}}</li>
      </ul>
    </div>

    <ng-container *ngIf="!resetPasswordCodeIsExpired">
      <div class="wrapper password-wrapper">
        <i class="fa-light fa-key password-icon" aria-hidden="true"></i>
        <div>
          <input class="text-field"
                 [placeholder]="'New Password' | transloco"
                 name="password"
                 [type]="'password'"
                 [(ngModel)]="newPassword"
                 (ngModelChange)="checkPass()"
                 (keyup)="formKeyUp($event)"
                 required/>
        </div>
        <p class="error" *ngIf="newPasswordError">{{newPasswordError}}</p>
      </div>
      <div class="wrapper password-wrapper">
        <i class="fa-light fa-key password-icon" aria-hidden="true"></i>
        <div>
          <input class="text-field"
                 [placeholder]="'Confirm Password' | transloco"
                 name="password"
                 [type]="'password'"
                 [(ngModel)]="newPasswordConfirm"
                 (ngModelChange)="checkPass()"
                 (keyup)="formKeyUp($event)"
                 required/>
        </div>
        <p class="error" *ngIf="newPasswordConfirmError">{{newPasswordConfirmError}}</p>
      </div>
      <div class="wrapper">
        <button type="button" class="submit-button" (click)="sendChangePassword()" [disabled]="hasError">
          <span></span>
          {{ 'Change Password' | transloco }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="resetPasswordCodeIsExpired">
      <div class="wrapper">
        <button type="button" class="submit-button" routerLink="/user/login/restore-password">
          {{ 'Request Again' | transloco }}
        </button>
      </div>
    </ng-container>

  </ng-container>

</form>
