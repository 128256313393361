import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {BcGqlService, IGQLResponse, IGQLUserPosition} from '@betterchains/bc-gql';

@Injectable({
  providedIn: 'root'
})
export class PositionsService {

  constructor(
    private gql: BcGqlService
  ) {
  }

  public GetShort(): Promise<IGQLUserPosition[]> {
    return new Promise<IGQLUserPosition[]>((resolve, reject) => {
      firstValueFrom(this.gql.query(
        //language=gql
        `
          {
            hr {
              positions(limit: 0, sort: "name asc") {
                id, name
              }
            }
          }
        `
      )).then((r: HttpResponse<IGQLResponse<{ hr: { positions: IGQLUserPosition[] } }>>) => resolve(r.body.data.hr.positions))
        .catch(r => reject(r));
    });
  }

}
