import {Injectable} from '@angular/core';
import CryptoES from "crypto-es";
import {AESCryptKey} from "../../environments/crypt";
import {TranslocoService} from "@ngneat/transloco";

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor(
    private t: TranslocoService
  ) {
  }

  public encrypt(data: any) {
    return CryptoES.AES.encrypt(JSON.stringify(data), AESCryptKey).toString();
  }

  public decrypt(encrypted: string) {
    try {
      const dectyptedPayloadBytes = CryptoES.AES.decrypt(encrypted, AESCryptKey);
      const unencryptedPayload = dectyptedPayloadBytes.toString(CryptoES.enc.Utf8);
      return JSON.parse(unencryptedPayload);
    } catch (e) {
      alert(this.t.translate('Invalid data, make sure you scan the correct tag.'))
    }
  }

}
