<section class="component" *ngFor="let c of components">
  <h3 class="font-monospace">{{c.tag}}</h3>
  <div>
    <aside>
      <div class="attr-row" *ngFor="let attr of c.attributes">
        <label for="{{c.tag}}-{{attr.a}}">{{attr.a}}:</label>
        <input class="form-control" *ngIf="!attr.o && !attr.n" id="{{c.tag}}-{{attr.a}}" type="text" [value]="attr.v"
               [tooltip]="attr.c?attr.c:null"
               (change)="attrValueChanged($event, attr)">
        <select class="form-control" *ngIf="attr.o && !attr.n" (change)="attrValueChanged($event, attr)">
          <option [selected]="o === attr.v" *ngFor="let o of attr.o" [value]="o">{{o}}</option>
        </select>
        <ng-select *ngIf="attr.n && attr.n=='branches'" [items]="branches" bindValue="branch_id" bindLabel="name"
                   (change)="attrValueChanged($event, attr)"
                   hideSelected="true" multiple="true"></ng-select>
        <ng-select *ngIf="attr.n && attr.n=='positions'" [items]="positions" bindValue="id" bindLabel="name"
                   (change)="attrValueChanged($event, attr)"
                   hideSelected="true" multiple="true"></ng-select>
        <ng-select *ngIf="attr.n && attr.n=='roles'" [items]="roles" bindValue="id" bindLabel="name"
                   (change)="attrValueChanged($event, attr)"
                   hideSelected="true" multiple="true"></ng-select>
        <ng-select *ngIf="attr.n && attr.n=='users'" [items]="users" bindValue="id" bindLabel="name"
                   (change)="attrValueChanged($event, attr)"
                   hideSelected="true" multiple="true"></ng-select>
      </div>
    </aside>
    <aside class="center" [superhtml]="mkHtml(c)">
    </aside>
    <aside class="flex-grow-1" style="width: 33%">
      <pre><code [superhtml]="mkHighlight(c)"></code></pre>
    </aside>
  </div>
</section>


