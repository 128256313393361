import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-full-page-loader',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
      <div id="foh-full-page-loader" *ngIf="loading">
          <div><span></span></div>
      </div>`,
  styleUrls: ['./full-page-loader.component.scss']
})
export class FullPageLoaderComponent {

  public loading = false;

  constructor(
    private loaderService: LoaderService,
    cd: ChangeDetectorRef
  ) {
    this.loaderService.onDisplayLoaderChange.subscribe(v => {
      this.loading = v;
      cd.detectChanges();
    });
  }

}
