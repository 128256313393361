import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'foh-password-meter',
  templateUrl: './foh-password-meter.component.html',
  styleUrls: ['./foh-password-meter.component.scss']
})
export class FohPasswordMeterComponent implements OnInit {

  @Input() password: string;

  @Input() minPasswordLength = 8;

  @Input() colors: string[] = [];

  @Output() strengthChange = new EventEmitter<number>();

  passwordStrength: number = null;

  private prevPasswordStrength = null;

  private defaultColours = [
    'darkred',
    'orangered',
    'orange',
    'yellowgreen',
    'green'
  ];

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.password) {
      this.calculatePasswordStrength();
    }
  }

  private calculatePasswordStrength() {
    if (!this.password) {
      this.passwordStrength = null;
    } else {
      this.passwordStrength = this.score(
        this.password
      );
    }

    if (this.prevPasswordStrength !== this.passwordStrength) {
      this.strengthChange.emit(this.passwordStrength);
      this.prevPasswordStrength = this.passwordStrength;
    }
  }

  score(password: string): number {
    let s = -1;
    if(password.length >= 8) {
      s++;
    }
    const ml = password.match(/.*[a-z]/);
    if(ml && ml.length > 0) {
      s++
    }
    const mu = password.match(/.*[A-Z]/);
    if(mu && mu.length > 0) {
      s++
    }
    const mn = password.match(/.*[0-9]/);
    if(mn && mn.length > 0) {
      s++
    }
    const mc = password.match(/.*[!@#$%^&*]/);
    if(mc && mc.length > 0) {
      s++
    }
    return s;
  }

  getMeterFillColor(strength) {
    if (!strength || strength < 0 || strength > 5) {
      return this.colors[0] ? this.colors[0] : this.defaultColours[0];
    }

    return this.colors[strength]
      ? this.colors[strength]
      : this.defaultColours[strength];
  }
}
